/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";

const apiUrl = process.env.REACT_APP_API_URL;

// @mui material components
import { Card, Icon, Grid, Tooltip } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAutocomplete from "components/MDAutocomplete";
import MDInput from "components/MDInput";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function CAInformation({ cApp }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  if (!cApp || Object.keys(cApp).length === 0) {
    return (
      <MDBox pt={3} px={2}>
        <MDTypography variant="subtitle2" fontWeight="medium">
          Loading centre & eqa information...
        </MDTypography>
      </MDBox>
    );
  }

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Centre Information
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MDBox
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            bgColor={darkMode ? "transparent" : "grey-100"}
            borderRadius="lg"
            p={3}
            mb={1}
            mt={2}
          >
            <MDBox width="100%" display="flex" flexDirection="column">
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
                mb={2}
              >
                <MDTypography variant="h6" fontWeight="regular" textTransform="capitalize">
                  {cApp.centreName.toUpperCase()}
                </MDTypography>
                {cApp.centreApplicationApproved && (
                  <Tooltip title="Centre Application Approved">
                    <Icon fontSize="medium" color="success">
                      verified
                    </Icon>
                  </Tooltip>
                )}
              </MDBox>
              <Grid container mb={2} lineHeight={0} display="flex" alignItems="center">
                <Grid item md={3} xs={12}>
                  <MDTypography variant="subtitle2" color="success">
                    Year of Incorporation:
                  </MDTypography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <MDTypography variant="h6" fontWeight="regular" textTransform="capitalize">
                    {cApp.yearOfInc}
                  </MDTypography>
                </Grid>
              </Grid>

              <Grid container mb={2} lineHeight={0} display="flex" alignItems="center">
                <Grid item md={3} xs={12}>
                  <MDTypography variant="subtitle2" color="success" sx={{ minWidth: "200px" }}>
                    CAC Number:
                  </MDTypography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <MDTypography variant="h6" fontWeight="regular" textTransform="capitalize">
                    RC.{cApp.cacNumber}
                  </MDTypography>
                </Grid>
              </Grid>

              <Grid container mb={2} lineHeight={0} display="flex" alignItems="center">
                <Grid item md={3} xs={12}>
                  <MDTypography variant="subtitle2" color="success" sx={{ minWidth: "200px" }}>
                    Centre Type:
                  </MDTypography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <MDTypography variant="h6" fontWeight="regular" textTransform="capitalize">
                    {cApp.centreType}
                  </MDTypography>
                </Grid>
              </Grid>

              <Grid container mb={2} lineHeight={0} display="flex" alignItems="center">
                <Grid item md={3} xs={12}>
                  <MDTypography variant="subtitle2" color="success" sx={{ minWidth: "200px" }}>
                    Centre Address:
                  </MDTypography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <MDTypography variant="h6" fontWeight="regular" textTransform="capitalize">
                    {cApp.permanentCentreAddress}
                  </MDTypography>
                </Grid>
              </Grid>

              <Grid container mb={2} lineHeight={0} display="flex" alignItems="center">
                <Grid item md={3} xs={12}>
                  <MDTypography variant="subtitle2" color="success" sx={{ minWidth: "200px" }}>
                    Email Address:
                  </MDTypography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <MDTypography variant="h6" fontWeight="regular">
                    {cApp.email}
                  </MDTypography>
                </Grid>
              </Grid>

              <Grid container mb={2} lineHeight={0} display="flex" alignItems="center">
                <Grid item md={3} xs={12}>
                  <MDTypography variant="subtitle2" color="success" sx={{ minWidth: "200px" }}>
                    Zone:
                  </MDTypography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <MDTypography variant="h6" fontWeight="regular" textTransform="capitalize">
                    {cApp.zone}
                  </MDTypography>
                </Grid>
              </Grid>

              <Grid container mb={2} lineHeight={0} display="flex" alignItems="center">
                <Grid item md={3} xs={12}>
                  <MDTypography variant="subtitle2" color="success" sx={{ minWidth: "200px" }}>
                    State:
                  </MDTypography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <MDTypography variant="h6" fontWeight="regular" textTransform="capitalize">
                    {cApp.stateOfRez}
                  </MDTypography>
                </Grid>
              </Grid>

              <Grid container mb={2} lineHeight={0} display="flex" alignItems="center">
                <Grid item md={3} xs={12}>
                  <MDTypography variant="subtitle2" color="success" sx={{ minWidth: "200px" }}>
                    Qualifications:
                  </MDTypography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <MDTypography variant="h6" fontWeight="regular">
                    {cApp.qualificationsList
                      ? cApp.qualificationsList.map((q) => q.qualificationType).join(", ")
                      : "No qualifications"}
                  </MDTypography>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Define PropTypes for CAInformation
CAInformation.propTypes = {
  cApp: PropTypes.shape({
    centreName: PropTypes.string,
    yearOfInc: PropTypes.string,
    cacNumber: PropTypes.string,
    centreType: PropTypes.string,
    permanentCentreAddress: PropTypes.string,
    email: PropTypes.string,
    zone: PropTypes.string,
    stateOfRez: PropTypes.string,
    centreApplicationApproved: PropTypes.boolean,
    qualificationsList: PropTypes.arrayOf(
      PropTypes.shape({
        qualificationType: PropTypes.string, // Ensure this key exists
      })
    ),
  }),
};

// Set default props to avoid undefined errors
CAInformation.defaultProps = {
  cApp: {},
};

export default CAInformation;
