/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAutocomplete from "components/MDAutocomplete";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar, { DashboardNavbar2 } from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams, Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../../AuthProvider";
import { STATES } from "../../config/states";
import { ZONES } from "../../config/zones";
import { CENTRETYPES } from "../../config/centretypes";

// Data
import centresTableData from "layouts/centres/data/centresTableData";

const CENTRE_REGEX = /^[a-zA-Z \.,()-]{8,50}$/;
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

// Display All Centres
export default function Centres() {
  const { columns, rows } = centresTableData();

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  All Centres
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  canSearch={true}
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={{ variant: "gradient", color: "success" }}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// Edit Centre Form
export function EditCentreForm() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [centreCode, setCentreCode] = useState("");
  const [centreName, setCentreName] = useState("");
  const [validCentreName, setValidCentreName] = useState(false);
  const [centreManager, setCentreManager] = useState("");
  const [validCentreManager, setValidCentreManager] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [stateOfRez, setStateOfRez] = useState("");
  const [zone, setZone] = useState("");
  const [centreType, setCentreType] = useState("");
  const [qualifications, setQualifications] = useState([]);
  const [verified, setVerified] = useState(false);

  const [qualificationsData, setQualificationsData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole !== "admin") {
    return <Navigate to="/error404" />;
  }

  // Fetch centre data
  useEffect(() => {
    const fetchCentreData = async () => {
      const { data } = await axios.get(`${apiUrl}/api/centres/${id}`);
      setCentreCode(data.centreCode);
      setCentreName(data.centreName);
      setCentreManager(data.centreManager);
      setEmail(data.email);
      setStateOfRez(data.stateOfRez);
      setZone(data.zone);
      setQualifications(data.qualifications.map((q) => q.qualificationType));
      setCentreType(data.centreType);
      setVerified(data.verified);
    };
    fetchCentreData();
  }, [id]);

  // Fetch qualifications data
  useEffect(() => {
    const fetchData = async () => {
      const qualificationsResponse = await axios.get(
        `${apiUrl}/api/qualifications/getQualifications`
      );

      setQualificationsData(qualificationsResponse.data);
    };

    fetchData();
  }, []);

  // Input validation
  useEffect(() => {
    setValidCentreName(CENTRE_REGEX.test(centreName));
    setValidCentreManager(CENTRE_REGEX.test(centreManager));
    setValidEmail(EMAIL_REGEX.test(email));
  }, [centreName, centreManager, email]);

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Form submission handler
  const onSaveCentreClicked = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Map qualifications to their corresponding IDs
    const selectedQualificationIds = qualifications
      .map(
        (qualification) =>
          qualificationsData.find((data) => data.qualificationType === qualification)?.id
      )
      .filter((id) => id);

    const formData = {
      centreCode,
      centreName,
      centreManager,
      email,
      stateOfRez,
      zone,
      qualifications: selectedQualificationIds,
      centreType,
      verified,
    };

    try {
      const response = await axios.put(`${apiUrl}/api/centres/${id}`, formData);

      if (response) {
        setIsSubmitting(false);
        showNotification("success", "Centre record updated successfully!");

        setTimeout(() => {
          navigate("/centres");
        }, 5000);
      }
    } catch (e) {
      setIsSubmitting(false);
      showNotification("error", `${e.response.data}, Try again`);
    }
  };

  // Options for autocomplete components
  const options3 = qualificationsData.map((qualification) => ({
    id: qualification.id,
    qualificationType: qualification.qualificationType,
  }));
  const options1 = Object.entries(STATES).map(([key, value]) => ({
    key,
    state: value,
  }));

  const options4 = Object.entries(ZONES).map(([key, value]) => ({
    key,
    zone: value,
  }));

  const options0 = Object.entries(CENTRETYPES).map(([key, value]) => ({
    key,
    centreType: value,
  }));

  // Form validation state
  const canSave = [
    validCentreName,
    validCentreManager,
    validEmail,
    centreType,
    stateOfRez,
    qualifications.length,
  ].every(Boolean);

  // Ensure values are always valid objects or null
  const mapValuesToOptions = (values, options, labelKey) =>
    values.map(
      (value) => options.find((option) => option[labelKey] === value) || { [labelKey]: value }
    );

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  Update Centre Record
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <form onSubmit={onSaveCentreClicked}>
                  <MDBox display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Centre Code"
                      value={centreCode}
                      disabled
                      sx={{ gridColumn: "span 1" }}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Centre Name"
                      value={centreName}
                      onChange={(e) => setCentreName(e.target.value)}
                      sx={{ gridColumn: "span 2" }}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="email"
                      label="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      name="email"
                      sx={{ gridColumn: "span 1" }}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Centre Manager"
                      value={centreManager}
                      onChange={(e) => setCentreManager(e.target.value)}
                      sx={{ gridColumn: "span 2" }}
                    />

                    <MDAutocomplete
                      id="centretype-selector"
                      sx={{ gridColumn: "span 1" }}
                      options={options0}
                      value={options0.find((option) => option.centreType === centreType) || null} // Set a single value or null if no match
                      onChange={(event, value) => setCentreType(value?.centreType || "")} // Set only one centreType value
                      getOptionLabel={(option) => option.centreType} // Access the centreType property for display
                      isOptionEqualToValue={(option, value) =>
                        option.centreType === value?.centreType
                      }
                      renderInput={(params) => (
                        <MDInput {...params} label="Select Centre Type" variant="standard" />
                      )}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Verified"
                      value={verified}
                      onChange={(e) => setVerified(e.target.value)}
                      sx={{ gridColumn: "span 1" }}
                    />

                    <MDAutocomplete
                      id="state-selector"
                      sx={{ gridColumn: "span 2" }}
                      options={options1}
                      value={options1.find((option) => option.state === stateOfRez) || null} // Set a single value or null if no match
                      onChange={(event, value) => setStateOfRez(value?.state || "")} // Set only one state value
                      getOptionLabel={(option) => option.state} // Access the state property for display
                      isOptionEqualToValue={(option, value) => option.state === value?.state}
                      renderInput={(params) => (
                        <MDInput {...params} label="Select State" variant="standard" />
                      )}
                    />

                    <MDAutocomplete
                      id="zone-selector"
                      sx={{ gridColumn: "span 2" }}
                      options={options4}
                      value={options4.find((option) => option.zone === zone) || null} // Set a single value or null if no match
                      onChange={(event, value) => setZone(value?.zone || "")} // Set only one zone value
                      getOptionLabel={(option) => option.zone} // Access the zone property for display
                      isOptionEqualToValue={(option, value) => option.zone === value?.zone}
                      renderInput={(params) => (
                        <MDInput {...params} label="Select Zone" variant="standard" />
                      )}
                    />

                    <MDAutocomplete
                      id="qualification-selector"
                      options={options3}
                      multiple
                      value={mapValuesToOptions(qualifications, options3, "qualificationType")}
                      onChange={(event, value) =>
                        setQualifications(value.map((item) => item.qualificationType))
                      }
                      getOptionLabel={(option) => option.qualificationType || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.qualificationType === value?.qualificationType
                      }
                      renderInput={(params) => (
                        <MDInput {...params} label="Select Qualifications" variant="standard" />
                      )}
                      sx={{ gridColumn: "span 4" }}
                    />
                  </MDBox>
                  <MDBox mt={3}>
                    <MDButton
                      type="submit"
                      disabled={!canSave || isSubmitting}
                      variant="gradient"
                      color="success"
                    >
                      {isSubmitting ? "Updating..." : "Update Centre Record"}
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
