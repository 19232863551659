/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";

export default function data() {
  const [centres, setCentres] = useState([]);
  //const apiUrl = 'https://api.niobplateau.org.ng'
  const apiUrl = process.env.REACT_APP_API_URL;

  // get all Centres' record
  useEffect(() => {
    const getCentresData = async () => {
      const { data } = await axios.get(`${apiUrl}/api/centres/getCentres`); /*, {
       withCredentials: true, // Include cookies or session data
      });*/
      setCentres(data);
    };
    getCentresData();
  }, []);

  const Centre = ({ centreCode, centreName }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {centreCode}
        </MDTypography>
        <MDTypography variant="caption">{centreName}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Contact = ({ email, phone }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {email}
        </MDTypography>
        <MDTypography variant="caption">{phone}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Status = ({ verified }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={verified ? "verified" : "unverified"}
          color={verified ? "success" : "dark"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    </MDBox>
  );

  return {
    columns: [
      { Header: "sno.", accessor: "number", width: "10%", align: "center" },
      { Header: "centre", accessor: "centre", width: "20%", align: "left" },
      { Header: "contact", accessor: "contact", width: "20%", align: "left" },
      {
        Header: "qualifications",
        accessor: "qualifications",
        width: "20%",
        align: "left",
        Cell: ({ value }) => (
          <div
            style={{
              maxWidth: "250px",
              whiteSpace: "normal",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {value}
          </div>
        ),
      },
      { Header: "state", accessor: "state", width: "20%", align: "left" },
      { Header: "status", accessor: "status", width: "10%", align: "center" },
      { Header: "action", accessor: "action", width: "20%", align: "center" },
    ],

    rows: centres.map((centre, index) => ({
      number: <MDTypography>{index + 1 + "."}</MDTypography>,
      centre: <Centre centreCode={centre.centreCode} centreName={centre.centreName} />,
      contact: <Contact email={centre.email} phone={centre.phone} />,
      qualifications: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {centre.qualifications.map((q) => q.qualificationType).join(", ")}
        </MDTypography>
      ),
      state: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {centre.stateOfRez}
        </MDTypography>
      ),
      status: <Status verified={centre.verified} />,
      action: (
        <MDTypography
          component="a"
          href={`centres/${centre.id}/edit`}
          variant="caption"
          color="success"
          fontWeight="medium"
        >
          Edit
        </MDTypography>
      ),
    })),
  };
}
