/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// routes.js
import Dashboard, { EQAMDashboard } from "layouts/dashboard";
import Tables from "layouts/tables";
import Users, { NewUserForm } from "layouts/users";
import Qualifications, { NewQualificationForm } from "layouts/qualifications";
import Roles, { NewRoleForm } from "layouts/roles";
import Centres from "layouts/centres";
import CentreApplications, { MyCentreApplications } from "layouts/centreapplications";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon>dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    allowedRoles: ["admin"],
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "eqadashboard",
    icon: <Icon>dashboard</Icon>,
    route: "/eqam-dashboard",
    component: <EQAMDashboard />,
    allowedRoles: ["eqam"],
  },
  {
    type: "dropdown",
    name: "Users",
    key: "all users",
    icon: <Icon>people</Icon>,
    allowedRoles: ["admin"],
    children: [
      {
        type: "collapse",
        name: "View Users",
        key: "users",
        icon: <Icon>search</Icon>,
        route: "/users",
        component: <Users />,
      },
      {
        type: "collapse",
        name: "New User",
        key: "new-user",
        icon: <Icon>add</Icon>,
        route: "/users/new",
        component: <NewUserForm />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Centres",
    key: "centres",
    icon: <Icon>house</Icon>,
    route: "/centres",
    component: <Centres />,
    allowedRoles: ["admin"],
  },
  {
    type: "dropdown",
    name: "Qualifications",
    key: "qualifications",
    icon: <Icon>construction</Icon>,
    allowedRoles: ["admin"],
    children: [
      {
        type: "collapse",
        name: "View Qualifications",
        key: "view-qualifications",
        icon: <Icon>search</Icon>,
        route: "/qualifications",
        component: <Qualifications />,
      },
      {
        type: "collapse",
        name: "New Qualification",
        key: "new-qualification",
        icon: <Icon>add</Icon>,
        route: "/qualifications/new",
        component: <NewQualificationForm />,
      },
    ],
  },
  {
    type: "dropdown",
    name: "Roles",
    key: "roles",
    icon: <Icon>folder_shared</Icon>,
    allowedRoles: ["admin"],
    children: [
      {
        type: "collapse",
        name: "View Roles",
        key: "view-roles",
        icon: <Icon>search</Icon>,
        route: "/roles",
        component: <Roles />,
      },
      {
        type: "collapse",
        name: "New Role",
        key: "new-role",
        icon: <Icon>add</Icon>,
        route: "/roles/new",
        component: <NewRoleForm />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Centre Applications",
    key: "centre-applications",
    icon: <Icon>app_registration</Icon>,
    route: "/centre-applications",
    component: <CentreApplications />,
    allowedRoles: ["admin"],
  },
  {
    type: "collapse",
    name: "Assigned Centres",
    key: "my-centre-applications",
    icon: <Icon>app_registration</Icon>,
    route: "/assigned-centres",
    component: <MyCentreApplications />,
    allowedRoles: ["eqam"],
  },
];

export default routes;
