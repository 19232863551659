import { useState, useEffect } from "react";
import { useAuth } from "../../../AuthProvider";
import { useNavigate } from "react-router-dom";
import { useSearch } from "../../../SearchContext";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import SearchIcon from "@mui/icons-material/Search";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

export default function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  //const route = useLocation().pathname.split("/").slice(1);
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleLogout = async (e) => {
    e.preventDefault();

    await logout();

    navigate("/"); // Redirect after logout
  };

  const handleGoToSettings = async (e) => {
    e.preventDefault();

    navigate("/settings");
  };

  const [query, setQuery] = useState("");
  const { handleSearch } = useSearch();

  const onSubmit = (e) => {
    e.preventDefault();
    if (query.trim()) {
      handleSearch(query); // Pass search query to global context
    }
  };

  // Define a mapping for breadcrumbs
  const breadcrumbMap = {
    dashboard: "Dashboard",
    users: "All Users",
    "users/new": "Add New User",
    "users/:id/edit": "Update User",
    "users/:id/view": "View User",
    centres: "All Centres",
    qualifications: "All Qualifications",
    "qualifications/new": "Add New Qualification",
    "qualifications/:id/edit": "Update Qualification",
    roles: "All Roles",
    "roles/new": "Add New Role",
    "roles/:id/edit": "Update Role",
    settings: "Settings",
    profile: "Profile",
    search: "Search",
    centreapplications: "All Centre Applications",
    "centre-applications/:id": "EQA Centre Visit Report",
    "centre-applications/:id/view": "View Centre Application",
    // Add more mappings as needed
  };

  const getBreadcrumbTitle = (pathSegments) => {
    const path = pathSegments.join("/");

    // Direct match (e.g., "users/new")
    if (breadcrumbMap[path]) {
      return breadcrumbMap[path];
    }

    // Handle dynamic routes (e.g., "users/:id/edit")
    if (pathSegments.length === 3 && pathSegments[0] === "users" && pathSegments[2] === "edit") {
      return "Update User";
    } else if (
      pathSegments.length === 3 &&
      pathSegments[0] === "users" &&
      pathSegments[2] === "view"
    ) {
      return "View User";
    } else if (
      pathSegments.length === 3 &&
      pathSegments[0] === "roles" &&
      pathSegments[2] === "edit"
    ) {
      return "Update Role";
    } else if (
      pathSegments.length === 3 &&
      pathSegments[0] === "qualifications" &&
      pathSegments[2] === "edit"
    ) {
      return "Update Qualification";
    } else if (
      pathSegments.length === 3 &&
      pathSegments[0] === "centre-applications" &&
      pathSegments[2] === "view"
    ) {
      return "View Centre Application";
    } else if (
      pathSegments.length === 2 &&
      pathSegments[0] === "centre-applications" &&
      /^[a-f0-9\-]{36}$/i.test(pathSegments[1]) // Check if it's a valid UUID
    ) {
      return "EQA Centre Visit Report";
    }

    // Default to the last part of the path capitalized
    return pathSegments[pathSegments.length - 1].replace(/-/g, " ").toUpperCase();
  };

  const route = useLocation().pathname.split("/").slice(1);
  const breadcrumbTitle = getBreadcrumbTitle(route);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={breadcrumbTitle} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox pr={1}>
              <MDInput
                label="Search EQAs"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSubmit(e);
                  }
                }}
              />
            </MDBox>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleGoToSettings}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                variant="contained"
                onClick={handleLogout}
              >
                <Icon sx={iconsStyle}>logout</Icon>
              </IconButton>
              {/*renderMenu()*/}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

export function DashboardNavbar2({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  //const route = useLocation().pathname.split("/").slice(1);
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleLogout = async (e) => {
    e.preventDefault();

    await logout();

    navigate("/"); // Redirect after logout
  };

  const [query, setQuery] = useState("");
  const { handleSearch } = useSearch();

  const onSubmit = (e) => {
    e.preventDefault();
    if (query.trim()) {
      handleSearch(query); // Pass search query to global context
    }
  };

  // Define a mapping for breadcrumbs
  const breadcrumbMap = {
    dashboard: "Dashboard",
    users: "All Users",
    "users/new": "Add New User",
    "users/:id/edit": "Update User",
    "users/:id/view": "View User",
    centres: "All Centres",
    qualifications: "All Qualifications",
    "qualifications/new": "Add New Qualification",
    "qualifications/:id/edit": "Update Qualification",
    roles: "All Roles",
    "roles/new": "Add New Role",
    "roles/:id/edit": "Update Role",
    settings: "Settings",
    profile: "Profile",
    centreapplications: "All Centre Applications",
    "centreapplications/:id": "EQA Centre Visit Report",
    "centreapplications/:id/view": "View Centre Application",
    // Add more mappings as needed
  };

  const getBreadcrumbTitle = (pathSegments) => {
    const path = pathSegments.join("/");

    // Direct match (e.g., "users/new")
    if (breadcrumbMap[path]) {
      return breadcrumbMap[path];
    }

    // Handle dynamic routes (e.g., "users/:id/edit")
    if (pathSegments.length === 3 && pathSegments[0] === "users" && pathSegments[2] === "edit") {
      return "Update User";
    } else if (
      pathSegments.length === 3 &&
      pathSegments[0] === "users" &&
      pathSegments[2] === "view"
    ) {
      return "View User";
    } else if (
      pathSegments.length === 3 &&
      pathSegments[0] === "roles" &&
      pathSegments[2] === "edit"
    ) {
      return "Update Role";
    } else if (
      pathSegments.length === 3 &&
      pathSegments[0] === "qualifications" &&
      pathSegments[2] === "edit"
    ) {
      return "Update Qualification";
    } else if (
      pathSegments.length === 3 &&
      pathSegments[0] === "centreapplications" &&
      pathSegments[2] === "view"
    ) {
      return "View Centre Application";
    }

    // Default to the last part of the path capitalized
    return pathSegments[pathSegments.length - 1].replace(/-/g, " ").toUpperCase();
  };

  const route = useLocation().pathname.split("/").slice(1);
  const breadcrumbTitle = getBreadcrumbTitle(route);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={breadcrumbTitle} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox pr={1}>
              <MDInput
                label="Search EQAs"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSubmit(e);
                  }
                }}
              />
            </MDBox>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                variant="contained"
                onClick={handleLogout}
              >
                <Icon sx={iconsStyle}>logout</Icon>
              </IconButton>
              {/*renderMenu()*/}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

// Setting default values for the props of DashboardNavbar
DashboardNavbar2.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar2.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};
