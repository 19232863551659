import { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const apiUrl = process.env.REACT_APP_API_URL;

// Create context
const AuthContext = createContext(null);

// AuthProvider Component
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    // Persist login state using localStorage
    const storedUser = localStorage.getItem("auth");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    if (auth) {
      localStorage.setItem("auth", JSON.stringify(auth));
    } else {
      localStorage.removeItem("auth");
    }
  }, [auth]);

  // ✅ Add login function
  const login = async ({ uln, password }) => {
    try {
      const response = await axios.post(`${apiUrl}/api/auth/login`, { uln, password });

      if (response.status !== 200) {
        throw new Error("Login failed");
      }

      const data = response.data;
      if (!data.token) {
        throw new Error("Token missing in response");
      }

      // Decode token to extract role
      const decodedUser = jwtDecode(data.token);
      if (!decodedUser.roles) {
        throw new Error("No role found in token");
      }

      const authData = { token: data.token, roles: decodedUser.roles };

      setAuth(authData); // Update state
      localStorage.setItem("auth", JSON.stringify(authData)); // Persist in storage

      return true; // Login successful
    } catch (error) {
      console.error("Login Error:", error.message);
      return false; // Login failed
    }
  };

  // ✅ Add logout function
  const logout = () => {
    setAuth(null);
    localStorage.removeItem("auth");
  };

  return <AuthContext.Provider value={{ auth, login, logout }}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Custom Hook for consuming AuthContext
export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  // Rehydrate auth from localStorage on first load
  useEffect(() => {
    if (!context.auth) {
      const storedAuth = localStorage.getItem("auth");
      if (storedAuth) {
        context.setAuth(JSON.parse(storedAuth));
      }
    }
  }, []);

  return context;
}
