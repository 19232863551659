import React, { useState, useEffect } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { Link, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { STATECODES } from "config/statecodes";

const apiUrl = process.env.REACT_APP_API_URL;

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAutocomplete from "components/MDAutocomplete";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SendIcon from "@mui/icons-material/Send";
import ApprovalIcon from "@mui/icons-material/Approval";
import AddIcon from "@mui/icons-material/Add";

const AssignEQACard = ({ eqas }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const id = useParams();
  const [selectedEqa, setSelectedEqa] = useState("");

  const onSubmitAssignmentClicked = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = { eqaAssignedTo: selectedEqa };

    try {
      const eqaAssigned = await axios.put(
        `${apiUrl}/api/centreapplications/assigneqa/${id.id}`,
        formData
      );

      if (eqaAssigned) {
        setIsSubmitting(false);
        showNotification("success", eqaAssigned.data.message);

        // Redirect after a delay
        setTimeout(() => navigate("/"), 7000);
      }
    } catch (e) {
      setIsSubmitting(false);
      showNotification("error", `Error in assigning EQA to Centre: ${e}`);
    }
  };

  const options = Array.isArray(eqas)
    ? eqas.map((eqa) => ({
        id: eqa.id,
        fullName: eqa.firstName + " " + eqa.middleName + " " + eqa.lastName,
        zone: eqa.zone,
      }))
    : [];

  if (!eqas || eqas.length === 0) {
    return (
      <MDBox pt={3} px={2}>
        <MDTypography variant="subtitle2" fontWeight="medium">
          Loading centre & eqa information...
        </MDTypography>
      </MDBox>
    );
  }

  return (
    <Paper elevation={1} sx={{ p: 3, borderRadius: 2, mt: 3 }} id="assign-eqa">
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <Typography variant="h6" sx={{ mb: 3 }}>
        Assign EQA to Centre
      </Typography>
      <Box component="form" noValidate autoComplete="off" onSubmit={onSubmitAssignmentClicked}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <MDAutocomplete
              id="eqa-selector"
              sx={{ minWidth: "350px", mr: 3 }}
              options={options}
              value={options.find((option) => option.id === selectedEqa) || null} // Set null if no selection
              onChange={(event, value) => setSelectedEqa(value?.id || "")}
              getOptionLabel={(option) => option.fullName}
              isOptionEqualToValue={(option, value) => option.fullName === value?.fullName}
              renderInput={(params) => (
                <MDInput {...params} label="Select EQA" variant="standard" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MDButton type="submit" variant="gradient" color="success" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Submit Assignment"}
            </MDButton>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

// Define PropTypes for CAInformation
AssignEQACard.propTypes = {
  eqas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      middleName: PropTypes.string,
      lastName: PropTypes.string.isRequired,
      zone: PropTypes.string.isRequired,
    })
  ),
};

// Set default props to avoid undefined errors
AssignEQACard.defaultProps = {
  eqas: [],
};

const DownloadCADocsCard = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { id } = useParams();
  const [ca, setCa] = useState({});
  const [loadingStates, setLoadingStates] = useState({});

  useEffect(() => {
    const fetchCentreApplicationData = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/api/centreapplications/${id}`);
        setCa(data);
      } catch (error) {
        console.error("Error fetching centre application data:", error);
      }
    };
    fetchCentreApplicationData();
  }, [id]);

  // Mapping document keys to meaningful button labels
  const documentLabels = {
    centreProfileDocUrl: "Centre Profile Document",
    centreProofOfRegUrl: "Proof of Legal Registration",
    centreStaffListUrl: "Staff List & Engagement Letters",
    centreStaffCVsUrl: "Centre Staff CVs",
    centreMOUUrl: "MOU for Facility Usage",
    centreEquipmentListUrl: "Equipment List",
    centrePolicyAndProceduresUrl: "Policies & Procedures",
    centreTimetableAndManualUrl: "Timetable & Sample of Manual",
    otherCentreDocsUrl: "Other Supporting Documents",
    centrePhotosUrl: "Centre Photos",
  };

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Unified function to handle document downloads
  const handleDownload = async (docKey, fileName) => {
    if (!ca[docKey]) return; // Skip if document URL is missing

    setLoadingStates((prev) => ({ ...prev, [docKey]: true }));

    try {
      const fileUrl = `${apiUrl}/${ca[docKey].replace(/\\/g, "/")}`;
      const fileResponse = await fetch(fileUrl);
      const blob = await fileResponse.blob();

      const fileExtension = fileUrl.split(".").pop().toLowerCase();
      const suggestedFilename = fileName || `document.${fileExtension}`;

      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = suggestedFilename;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error(`Error downloading ${fileName}:`, error);
      showNotification("error", `Error fetching ${fileName}: ${error}`);
    }

    setLoadingStates((prev) => ({ ...prev, [docKey]: false }));
  };

  return (
    <Paper elevation={1} sx={{ p: 3, borderRadius: 2, mt: 3 }}>
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <Grid item xs={12} lg={12}>
        <MDBox lineHeight={0} sx={{ mb: 3 }}>
          <MDTypography variant="h6">Centre Application Documents</MDTypography>
          <MDTypography variant="caption" color="text" fontWeight="regular">
            Download all available Centre Application supporting documents.
          </MDTypography>
        </MDBox>
        <Grid container spacing={2}>
          {Object.keys(documentLabels).map((docKey) => {
            const fileUrl = ca[docKey];
            if (!fileUrl) return null; // Skip if file URL is missing

            // Extract file extension dynamically
            const fileExtension = fileUrl.split(".").pop().toLowerCase();
            const validExtensions = ["pdf", "zip", "jpg", "jpeg", "png"];

            // Ensure a valid extension; fallback to "pdf" if unknown
            const extension = validExtensions.includes(fileExtension) ? fileExtension : "pdf";

            return (
              <Grid item lg={4} xs={12} key={docKey}>
                <MDButton
                  variant="outlined"
                  color="dark"
                  startIcon={<FileDownloadIcon />}
                  onClick={() => handleDownload(docKey, `${documentLabels[docKey]}.${extension}`)}
                  disabled={loadingStates[docKey]}
                  fullWidth
                >
                  {loadingStates[docKey]
                    ? `Downloading ${documentLabels[docKey]}...`
                    : `Download ${documentLabels[docKey]}`}
                </MDButton>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Paper>
  );
};

const DownloadEQADocsCard = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const apiUrl = process.env.REACT_APP_API_URL;
  const [ca, setCa] = useState([]);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitting2, setIsSubmitting2] = useState(false);
  const [isSubmitting3, setIsSubmitting3] = useState(false);

  const id = useParams();

  // Fetch centreapplication data
  useEffect(() => {
    const fetchCentreApplicationData = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/api/centreapplications/${id.id}`);
        setCa({
          ...data,
          qualificationsList: data.qualificationsList || [], // Ensure it’s always an array
        });
      } catch (error) {
        console.error("Error fetching centre application data:", error);
        showNotification("error", error);
      }
    };
    fetchCentreApplicationData();
  }, [id]);

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const onDownloadEQAReportClicked = async (e) => {
    e.preventDefault();
    setIsSubmitting2(true);

    try {
      // Fetch Centre's Credentials URL
      const response = await axios.get(`${apiUrl}/api/centreapplications/${id.id}`);
      const ca = response.data; // Extract actual data

      if (ca && ca.eqaReportsUrl) {
        // Construct the full file URL
        const fileUrl = `${apiUrl}/${ca.eqaReportsUrl.replace(/\\/g, "/")}`;

        // Determine the file extension
        const fileExtension = fileUrl.split(".").pop().toLowerCase();
        const suggestedFilename =
          fileExtension === "zip" ? "EQA_Centre_Visit_Report.zip" : "EQA_Centre_Visit_Report.pdf";

        // Fetch the file as a Blob
        const fileResponse = await fetch(fileUrl);
        const blob = await fileResponse.blob();

        // Create a Blob URL and trigger download
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", suggestedFilename);
        document.body.appendChild(link);
        link.click();
        setIsSubmitting2(false);

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } else {
        setIsSubmitting2(false);
        console.warn("EQA Report URL not found in response");
        showNotification("error", "EQA Report URL not found in response");
      }
    } catch (error) {
      setIsSubmitting2(false);
      console.error("Error fetching EQA Report:", error);
      showNotification("error", `Error fetching EQA Report: ${error}`);
    }
  };

  /*const onGenerateCAReportClicked = async (e) => {
    e.preventDefault();
    setIsSubmitting3(true);

    // Assuming centreapplication is an object with data that you want to send for PDF creation
    const response = await axios.get(`${apiUrl}/api/centreapplications/${id.id}`);
    const centreapplication = response.data;

    // POST request to generate the PDF
    axios
      .post(`${apiUrl}/create-pdf`, centreapplication)
      .then(() => {
        // GET request to fetch the generated PDF as a blob
        return axios.get(`${apiUrl}/fetch-pdf`, { responseType: "blob" });
      })
      .then((res) => {
        // Create a Blob from the response data (PDF)
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });

        // Use the saveAs function from file-saver to download the PDF
        saveAs(pdfBlob, `${ca.centreName}_Center_Application.pdf`);
        setIsSubmitting3(false);
      })
      .catch((error) => {
        setIsSubmitting3(false);
        console.error("Error generating or downloading PDF:", error);
        showNotification("error", `Error generating or downloading PDF: ${error}`);
      });
  };*/

  const onGenerateCAReportClicked = async (e) => {
    e.preventDefault();
    setIsSubmitting3(true);

    try {
      // Fetch the centre application data
      const response = await axios.get(`${apiUrl}/api/centreapplications/${id.id}`);
      const centreapplication = response.data;

      // POST request to generate the PDF
      const pdfResponse = await axios.post(`${apiUrl}/create-pdf`, centreapplication, {
        responseType: "blob", // Expect a binary PDF response
      });

      // Create a Blob from the response data (PDF)
      const pdfBlob = new Blob([pdfResponse.data], { type: "application/pdf" });

      // Use the saveAs function from file-saver to download the PDF
      saveAs(pdfBlob, `${centreapplication.centreName}_Center_Application.pdf`);
      setIsSubmitting3(false);
    } catch (error) {
      setIsSubmitting3(false);
      console.error("❌ Error generating or downloading PDF:", error);
      showNotification("error", `Error generating or downloading PDF: ${error}`);
    }
  };

  return (
    <Paper elevation={1} sx={{ p: 3, borderRadius: 2, mt: 3 }} id="credentials">
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <Grid item xs={12} lg={12}>
        <MDBox lineHeight={0} sx={{ mb: 3 }}>
          <MDTypography variant="h6">EQA Report/Documents</MDTypography>
          <MDTypography variant="caption" color="text" fontWeight="regular">
            All documents related to the EQA Centre Visit report are available for you to download
          </MDTypography>
        </MDBox>
        <MDBox>
          <Grid container spacing={1}>
            <Grid item lg={4} xs={12}>
              <MDButton
                variant="gradient"
                color="primary"
                disabled={ca.eqaCentreReportComplete || isSubmitting2 ? false : true}
                onClick={onDownloadEQAReportClicked}
                fullWidth
                startIcon={<FileDownloadIcon />}
              >
                {isSubmitting2 ? "Downloading report..." : "Download EQA Centre Visit Report"}
              </MDButton>
            </Grid>
            <Grid item lg={4} xs={12}>
              <MDButton
                variant="outlined"
                color="success"
                disabled={ca.eqaCentreReportComplete || isSubmitting3 ? false : true}
                onClick={onGenerateCAReportClicked}
                fullWidth
                startIcon={<PictureAsPdfIcon />}
              >
                {isSubmitting3 ? "Generating report..." : "Generate Centre Application Report"}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Paper>
  );
};

const generateCentreCode = (count, stateOfRez, centreName) => {
  const stateTrim = STATECODES[stateOfRez];
  const centreNameTrim = centreName.charAt(0).toUpperCase();

  return `NB/${count}${centreNameTrim}${stateTrim}`;
};

const ApproveCACard = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const apiUrl = process.env.REACT_APP_API_URL;
  const [ca, setCa] = useState([]);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitting2, setIsSubmitting2] = useState(false);
  const [isSubmitting3, setIsSubmitting3] = useState(false);

  const id = useParams();

  // Fetch centreapplication data
  useEffect(() => {
    const fetchCentreApplicationData = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/api/centreapplications/${id.id}`);
        setCa({
          ...data,
          qualificationsList: data.qualificationsList || [], // Ensure it’s always an array
        });
      } catch (error) {
        console.error("Error fetching centre application data:", error);
        showNotification("error", error);
      }
    };
    fetchCentreApplicationData();
  }, [id]);

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const onApproveCAClicked = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      if (!ca || !ca.eqaCentreReportComplete) {
        return showNotification("error", "EQA Centre Report is not completed");
        setIsSubmitting(false);
      }

      const response = await axios.put(`${apiUrl}/api/approve/${id.id}`);

      if (response.status === 201 || response.status === 200) {
        setIsSubmitting(false);
        showNotification("success", `Centre ${ca.centreName} has been Approved`);

        // Redirect after a delay
        setTimeout(() => navigate("/centreapplications"), 7000);
      } else {
        setIsSubmitting(false);
        showNotification("error", "Failed to approve Centre. Please try again.");
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error("Approval Error: ", error);
      showNotification("error", error.response?.data || "Error approving Centre");
    }
  };

  const onCreateCentreClicked = async (e) => {
    e.preventDefault();
    setIsSubmitting2(true);

    try {
      if (!ca || !ca.centreApplicationApproved) {
        setIsSubmitting2(false);
        return showNotification("error", "This Centre Application needs to be approved first");
      }

      // Begin process of generating centreCode
      const pattern = /^NB\/(\d+)(\w+)$/; // Regular expression to match the pattern and capture the number
      let maxNumber = -Infinity;

      const centres = await axios.get(`${apiUrl}/api/centres/getCentres`);

      // get the highest number that a centreCode holds
      centres.data.map((centre) => {
        const centreCode = centre.centreCode;
        const match = centreCode.match(pattern);
        if (match) {
          const number = parseInt(match[1], 10);
          if (number > maxNumber) {
            maxNumber = number;
          }
        }
      });

      let result = maxNumber === -Infinity ? null : maxNumber;

      // Generate Center Code
      const centreCode = await generateCentreCode(result + 1, ca.stateOfRez, ca.centreName);

      const qualifications = ca.qualificationsList.map((qual) => {
        return qual.id;
      });

      const formData = {
        centreCode,
        centreName: ca.centreName,
        centreManager: ca.centreManagerName,
        centreType: ca.centreType,
        email: ca.email,
        phone: ca.phone,
        stateOfRez: ca.stateOfRez,
        zone: ca.zone,
        qualifications,
        centreHead: ca.centreHead,
        address: ca.permanentCentreAddress,
      };

      const response = await axios.post(`${apiUrl}/api/centres/addCentre`, formData);

      if (response.status === 201) {
        setIsSubmitting2(false);
        showNotification("success", `Centre ${centreCode} has been created`);

        // Redirect after a delay
        setTimeout(() => navigate("/centres"), 7000);
      } else {
        setIsSubmitting2(false);
        showNotification("error", "Failed to create Centre. Please try again.");
      }
    } catch (error) {
      setIsSubmitting2(false);
      console.error("Centre Creation Error: ", error);
      showNotification("error", error.response?.data?.message || "Error creating Centre");
    }
  };

  const [open, setOpen] = React.useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSendFeedbackClicked = async (e) => {
    e.preventDefault();
    setIsSubmitting3(true);

    try {
      const formData = {
        email: ca.email,
        subject,
        message,
      };

      const response = await axios.post(`${apiUrl}/sendFeedback`, formData);

      if (response.status === 201) {
        setIsSubmitting3(false);
        showNotification("success", `Feedback sent successfully`);
        setTimeout(() => {
          setSubject("");
          setMessage("");
          setOpen(false);
        }, 2000);
      } else {
        setIsSubmitting3(false);
        showNotification("error", "Failed to send feedback. Please try again.");
        setTimeout(() => {
          setSubject("");
          setMessage("");
          setOpen(false);
        }, 2000);
      }
    } catch (error) {
      setIsSubmitting3(false);
      console.error("Feedback Error: ", error);
      showNotification("error", error.response?.data || "Error sending feedback");
      setTimeout(() => setOpen(false), 2000);
    }
  };

  return (
    <Paper elevation={1} sx={{ p: 3, borderRadius: 2, mt: 3 }} id="approval">
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              component: "form",
            },
          }}
        >
          <DialogTitle>Feedback to &quot;{ca.centreName || "this centre"}&quot;</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 3 }}>
              What would you like this centre to know about their application?
            </DialogContentText>
            <TextField
              autoFocus
              required
              id="subject"
              name="subject"
              label="Subject"
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              fullWidth
              variant="standard"
              sx={{ mb: 2 }}
            />
            <TextField
              required
              id="message"
              name="message"
              label="Type Feedback here"
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              rows={6}
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="dark">
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={onSendFeedbackClicked}
              color="dark"
              disabled={isSubmitting2}
            >
              {isSubmitting2 ? "Sending..." : "Send Feedback"}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <Grid item xs={12} lg={12}>
        <MDBox lineHeight={0} sx={{ mb: 3 }}>
          <MDTypography variant="h6">Centre Application Feedback</MDTypography>
          <MDTypography variant="caption" color="text" fontWeight="regular">
            Approve Centre Application or send a feedback to the Centre
          </MDTypography>
        </MDBox>
        <MDBox>
          <Grid container spacing={1}>
            <Grid item lg={4} xs={12}>
              <MDButton
                variant="gradient"
                color="success"
                disabled={
                  (ca.eqaCentreReportComplete && !ca.centreApplicationApproved) || isSubmitting
                    ? false
                    : true
                }
                onClick={onApproveCAClicked}
                fullWidth
                startIcon={<ApprovalIcon />}
              >
                {isSubmitting ? "Approving Centre..." : "Approve Centre Application"}
              </MDButton>
            </Grid>
            <Grid item lg={4} xs={12}>
              <MDButton
                variant="outlined"
                color="dark"
                disabled={!ca.centreApplicationApproved ? false : true}
                onClick={handleClickOpen}
                fullWidth
                startIcon={<SendIcon />}
              >
                Send Feedback to Centre
              </MDButton>
            </Grid>
            <Grid item lg={4} xs={12}>
              <MDButton
                variant="gradient"
                color="primary"
                disabled={
                  (ca.eqaCentreReportComplete && ca.centreApplicationApproved) || isSubmitting3
                    ? false
                    : true
                }
                onClick={onCreateCentreClicked}
                fullWidth
                startIcon={<AddIcon />}
              >
                {isSubmitting3 ? "Creating Centre Dashboard..." : "Create Centre Dashboard"}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Paper>
  );
};

export { AssignEQACard, DownloadCADocsCard, DownloadEQADocsCard, ApproveCACard };
