/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import {
  Grid,
  Card,
  Tab,
  Tabs,
  List,
  ListItem,
  Select,
  Stack,
  IconButton,
  InputAdornment,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  TextField,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAutocomplete from "components/MDAutocomplete";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar, { DashboardNavbar2 } from "examples/Navbars/DashboardNavbar";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Sidebar, { CASidebar } from "examples/Sidenav/Sidebar";
import CAInformation from "layouts/centreapplications/components/CentreInformation";
import {
  AssignEQACard,
  DownloadCADocsCard,
  DownloadEQADocsCard,
  ApproveCACard,
} from "layouts/centreapplications/components/EqaCards";

import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { STATES } from "../../config/states";
import { ZONES } from "../../config/zones";
import { ZONE_STATES } from "../../config/zonestates";
import { CENTRETYPES } from "../../config/centretypes";
import { useAuth } from "../../AuthProvider"; // Corrected Import
import { Navigate, useLocation } from "react-router-dom";

// Data
import centreapplicationsTableData from "layouts/centreapplications/data/centreapplicationsTableData";
import myCentreapplicationsTableData from "layouts/centreapplications/data/myCentreapplicationsTableData";

// Images
import bgImage from "assets/images/workshop.jpg";

const CENTRE_REGEX = /^[a-zA-Z \.,()-]{8,50}$/;
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const steps = [
  "Instructions",
  "CENTRE DETAILS",
  "YOUR ORGANISATION",
  "HUMAN & FINANCIAL RESOURCES",
  "PHYSICAL INFRASTRUCTURE",
  "CODE OF PRACTICE",
  "ORGANISATIONAL STAFFING",
  "PHYSICAL FACILITIES",
  "TRAINING STAFF",
  "SUPPORTING DOCUMENTS",
];

const eqasteps = [
  "Instructions",
  "CENTRE DETAILS",
  "YOUR ORGANISATION",
  "EQA FEEDBACK 1",
  "EQA FEEDBACK 2",
  "EQA FEEDBACK 3",
  "EQA FEEDBACK 4",
  "PHYSICAL FACILITIES",
  "TRAINING STAFF",
  "EQA FINAL FEEDBACK",
  "EQA SUPPORTING DOCUMENTS",
];

// Display All CentreApplications
export default function CentreApplications() {
  const { columns, rows } = centreapplicationsTableData();

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole !== "admin") {
    return <Navigate to="/error404" />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  All Centre Applications
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  canSearch={true}
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={{ variant: "gradient", color: "success" }}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// New CentreApplication Form with Stepper
export function NewCentreApplicationForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [qualificationsData, setQualificationsData] = useState([]);
  const maxYear = new Date().getFullYear().toString();
  const today = new Date().toString();

  const apiUrl = process.env.REACT_APP_API_URL;

  const [activeStep, setActiveStep] = useState(0);

  //CENTRE DETAILS INITIALIZE
  const [centreName, setCentreName] = useState("");
  const [validCentreName, setValidCentreName] = useState(false);
  const [yearOfInc, setYearOfInc] = useState();
  const [cacNumber, setCACNumber] = useState("");
  const [validCACNumber, setValidCACNumber] = useState(false);
  const [permanentCentreAddress, setPermanentCentreAddress] = useState("");
  const [trainingCentreAddress, setTrainingCentreAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [website, setWebsite] = useState("");
  const [validWebsite, setValidWebsite] = useState(false);
  const [centreHead, setCentreHead] = useState("");
  const [validCentreHead, setValidCentreHead] = useState(false);
  const [centreHeadPhone, setCentreHeadPhone] = useState("");
  const [validCentreHeadPhone, setValidCentreHeadPhone] = useState(false);
  const [centreHeadEmail, setCentreHeadEmail] = useState("");
  const [validCentreHeadEmail, setValidCentreHeadEmail] = useState(false);
  const [qualifications, setQualifications] = useState([]);
  const [zone, setZone] = useState("");
  const [stateOfRez, setStateOfRez] = useState("");

  //CENTRE ORGANISATION INITIALIZE
  const [centreType, setCentreType] = useState("");
  const [satelliteCentreName1, setSatelliteCentreName1] = useState("");
  const [validSatelliteCentreName1, setValidSatelliteCentreName1] = useState(false);
  const [satelliteCentreAddress1, setSatelliteCentreAddress1] = useState("");
  const [satelliteCentreName2, setSatelliteCentreName2] = useState("");
  const [validSatelliteCentreName2, setValidSatelliteCentreName2] = useState(false);
  const [satelliteCentreAddress2, setSatelliteCentreAddress2] = useState("");
  const [otherApprovals, setOtherApprovals] = React.useState({
    itp: false,
    naddc: false,
    nabteb: false,
    cityGuild: false,
  });
  const [awardingBody1, setAwardingBody1] = useState("");
  const [awardingBody1Qualification, setAwardingBody1Qualification] = useState("");
  const [awardingBody1Level, setAwardingBody1Level] = useState(1);
  const [awardingBody1DateApproved, setAwardingBody1DateApproved] = useState();
  const [awardingBody1ApprovalStatus, setAwardingBody1ApprovalStatus] = useState("");
  const [awardingBody2, setAwardingBody2] = useState("");
  const [awardingBody2Qualification, setAwardingBody2Qualification] = useState("");
  const [awardingBody2Level, setAwardingBody2Level] = useState(1);
  const [awardingBody2DateApproved, setAwardingBody2DateApproved] = useState();
  const [awardingBody2ApprovalStatus, setAwardingBody2ApprovalStatus] = useState("");

  //HUMAN & FINANCIAL RESOURCES INITIALIZE
  const [nsqCertifiedAssessors, setNsqCertifiedAssessors] = useState("");
  const [assessorsCount, setAssessorsCount] = useState(0);
  const [nsqCertifiedVerifiers, setNsqCertifiedVerifiers] = useState("");
  const [verifiersCount, setVerifiersCount] = useState(0);
  const [nsqCertifiedCentreManager, setNsqCertifiedCentreManager] = useState("");
  const [liaisonOfficerPresent, setLiaisonOfficerPresent] = useState("");
  const [resourceOfficerPresent, setResourceOfficerPresent] = useState("");
  const [siteBankPresent, setSiteBankPresent] = useState("");

  //PHYSICAL INFRASTRUCTURE INITIALIZE
  const [trainingRoomAdequate, setTrainingRoomAdequate] = useState("");
  const [dataRoomAdequate, setDataRoomAdequate] = useState("");
  const [workshopAdequate, setWorkshopAdequate] = useState("");
  const [practicalSpaceAdequate, setPracticalSpaceAdequate] = useState("");
  const [techLabAdequate, setTechLabAdequate] = useState("");
  const [staffRoomAdequate, setStaffRoomAdequate] = useState("");
  const [toiletAdequate, setToiletAdequate] = useState("");
  const [firstAidFireAdequate, setFirstAidFireAdequate] = useState("");
  const [hostelsAdequate, setHostelsAdequate] = useState("");
  const [tradeToolsAdequate, setTradeToolsAdequate] = useState("");
  const [auditoriumAvailable, setAuditoriumAvailable] = useState("");
  const [libraryAdequate, setLibraryAdequate] = useState("");
  const [waterAndPowerAdequate, setWaterAndPowerAdequate] = useState("");
  const [mgtOfficesAdequate, setMgtOfficesAdequate] = useState("");

  //CODE OF PRACTICE INITIALIZE
  const [nosAvailable, setNosAvailable] = useState("");
  const [trainingManualAvailable, setTrainingManualAvailable] = useState("");
  const [nosAndManualAlignment, setNosAndManualAlignment] = useState("");
  const [otherTrainingMaterialsAvailable, setOtherTrainingMaterialsAvailable] = useState("");
  const [nsqGuidelineAvailable, setNsqGuidelineAvailable] = useState("");
  const [staffCompliantWithStandards, setStaffCompliantWithStandards] = useState("");
  const [objectivesClear, setObjectivesClear] = useState("");
  const [classParticipation, setClassParticipation] = useState("");

  //ORGANISATION STAFFING INITIALIZE
  const [centreManagerName, setCentreManagerName] = useState("");
  const [centreManagerQualification, setCentreManagerQualification] = useState("");
  const [assessor1Name, setAssessor1Name] = useState("");
  const [assessor1Qualification, setAssessor1Qualification] = useState("");
  const [assessor2Name, setAssessor2Name] = useState("");
  const [assessor2Qualification, setAssessor2Qualification] = useState("");
  const [internalVerifierName, setInternalVerifierName] = useState("");
  const [internalVerifierQualification, setInternalVerifierQualification] = useState("");
  const [liaisonOfficerName, setLiaisonOfficerName] = useState("");
  const [liaisonOfficerQualification, setLiaisonOfficerQualification] = useState("");
  const [resourceOfficerName, setResourceOfficerName] = useState("");
  const [resourceOfficerQualification, setResourceOfficerQualification] = useState("");
  const [otherStaffName, setOtherStaffName] = useState("");
  const [otherStaffQualification, setOtherStaffQualification] = useState("");
  const [otherStaffDesignation, setOtherStaffDesignation] = useState("");

  //PHYSICAL FACILITIES INITIALIZE
  const [trainingRoomQuantity, setTrainingRoomQuantity] = useState(0);
  const [trainingRoomFloorArea, setTrainingRoomFloorArea] = useState(0);
  const [workshopQuantity, setWorkshopQuantity] = useState(0);
  const [workshopFloorArea, setWorkshopFloorArea] = useState(0);
  const [practicalTrainingSpaceQuantity, setPracticalTrainingSpaceQuantity] = useState(0);
  const [practicalTrainingSpaceFloorArea, setPracticalTrainingSpaceFloorArea] = useState(0);
  const [technicalLabQuantity, setTechnicalLabQuantity] = useState(0);
  const [technicalLabFloorArea, setTechnicalLabFloorArea] = useState(0);
  const [recordsRoomQuantity, setRecordsRoomQuantity] = useState(0);
  const [recordsRoomFloorArea, setRecordsRoomFloorArea] = useState(0);
  const [auditoriumQuantity, setAuditoriumQuantity] = useState(0);
  const [auditoriumFloorArea, setAuditoriumFloorArea] = useState(0);
  const [libraryQuantity, setLibraryQuantity] = useState(0);
  const [libraryFloorArea, setLibraryFloorArea] = useState(0);
  const [utilityQuantity, setUtilityQuantity] = useState(0);
  const [utilityFloorArea, setUtilityFloorArea] = useState(0);
  const [mgtOfficesQuantity, setMgtOfficesQuantity] = useState(0);
  const [mgtOfficesFloorArea, setMgtOfficesFloorArea] = useState(0);
  const [staffRoomQuantity, setStaffRoomQuantity] = useState(0);
  const [staffRoomFloorArea, setStaffRoomFloorArea] = useState(0);
  const [toiletQuantity, setToiletQuantity] = useState(0);
  const [toiletFloorArea, setToiletFloorArea] = useState(0);
  const [firstAidFireFacilityQuantity, setFirstAidFireFacilityQuantity] = useState(0);
  const [firstAidFireFacilityFloorArea, setFirstAidFireFacilityFloorArea] = useState(0);
  const [hostelQuantity, setHostelQuantity] = useState(0);
  const [hostelFloorArea, setHostelFloorArea] = useState(0);
  const [tradeToolsQuantity, setTradeToolsQuantity] = useState(0);
  const [tradeToolsFloorArea, setTradeToolsFloorArea] = useState(0);
  const [otherFacilityName, setOtherFacilityName] = useState("");
  const [otherFacilityQuantity, setOtherFacilityQuantity] = useState(0);
  const [otherFacilityFloorArea, setOtherFacilityFloorArea] = useState(0);

  //TRAINING STAFF INITIALIZE
  const [trainingStaff1Name, setTrainingStaff1Name] = useState("");
  const [trainingStaff1Gender, setTrainingStaff1Gender] = useState("");
  const [trainingStaff1Qualification, setTrainingStaff1Qualification] = useState("");
  const [trainingStaff1Experience, setTrainingStaff1Experience] = useState(0);
  const [trainingStaff1Specialization, setTrainingStaff1Specialization] = useState("");
  const [trainingStaff2Name, setTrainingStaff2Name] = useState("");
  const [trainingStaff2Gender, setTrainingStaff2Gender] = useState("");
  const [trainingStaff2Qualification, setTrainingStaff2Qualification] = useState("");
  const [trainingStaff2Experience, setTrainingStaff2Experience] = useState(0);
  const [trainingStaff2Specialization, setTrainingStaff2Specialization] = useState("");
  const [trainingStaff3Name, setTrainingStaff3Name] = useState("");
  const [trainingStaff3Gender, setTrainingStaff3Gender] = useState("");
  const [trainingStaff3Qualification, setTrainingStaff3Qualification] = useState("");
  const [trainingStaff3Experience, setTrainingStaff3Experience] = useState(0);
  const [trainingStaff3Specialization, setTrainingStaff3Specialization] = useState("");

  //SUPPORTING DOCS & DECLARATION INITIALIZE
  const [applicantsName, setApplicantsName] = useState("");
  const [validApplicantsName, setValidApplicantsName] = useState(false);
  const [applicantsPosition, setApplicantsPosition] = useState("");
  const [validApplicantsPosition, setValidApplicantsPosition] = useState(false);

  //INITIALIZE UPLOAD VARIABLES
  const [centreProfileDoc, setCentreProfileDoc] = useState(null);
  const [centreProfileDocUrl, setCentreProfileDocUrl] = useState(null);
  const [centreProofOfReg, setCentreProofOfReg] = useState(null);
  const [centreProofOfRegUrl, setCentreProofOfRegUrl] = useState(null);
  const [centreStaffList, setCentreStaffList] = useState(null);
  const [centreStaffListUrl, setCentreStaffListUrl] = useState(null);
  const [centreStaffCVs, setCentreStaffCVs] = useState(null);
  const [centreStaffCVsUrl, setCentreStaffCVsUrl] = useState(null);
  const [centreMOU, setCentreMOU] = useState(null);
  const [centreMOUUrl, setCentreMOUUrl] = useState(null);
  const [centreEquipmentList, setCentreEquipmentList] = useState(null);
  const [centreEquipmentListUrl, setCentreEquipmentListUrl] = useState(null);
  const [centrePolicyAndProcedures, setCentrePolicyAndProcedures] = useState(null);
  const [centrePolicyAndProceduresUrl, setCentrePolicyAndProceduresUrl] = useState(null);
  const [centreTimetableAndManual, setCentreTimetableAndManual] = useState(null);
  const [centreTimetableAndManualUrl, setCentreTimetableAndManualUrl] = useState(null);
  const [otherCentreDocs, setOtherCentreDocs] = useState(null);
  const [otherCentreDocsUrl, setOtherCentreDocsUrl] = useState(null);
  const [centrePhotos, setCentrePhotos] = useState(null);
  const [centrePhotosUrl, setCentrePhotosUrl] = useState(null);

  // Fetch qualifications data
  useEffect(() => {
    const fetchData = async () => {
      const qualificationsResponse = await axios.get(
        `${apiUrl}/api/qualifications/getQualifications`
      );

      setQualificationsData(qualificationsResponse.data);
    };

    fetchData();
  }, []);

  // Input validation
  useEffect(() => {
    setValidCentreName(CENTRE_REGEX.test(centreName));
    setValidEmail(EMAIL_REGEX.test(email));
  }, [centreName, email]);

  //CENTRE DETAILS HANDLE CHANGE
  const onCentreNameChanged = (e) => setCentreName(e.target.value);
  const onCACChanged = (e) => setCACNumber(e.target.value);
  const onPermanentCentreAddressChanged = (e) => setPermanentCentreAddress(e.target.value);
  const onTrainingCentreAddressChanged = (e) => setTrainingCentreAddress(e.target.value);
  const onPhoneChanged = (e) => setPhone(e.target.value);
  const onEmailChanged = (e) => setEmail(e.target.value);
  const onWebsiteChanged = (e) => setWebsite(e.target.value);
  const onCentreHeadChanged = (e) => setCentreHead(e.target.value);
  const onCentreHeadPhoneChanged = (e) => setCentreHeadPhone(e.target.value);
  const onCentreHeadEmailChanged = (e) => setCentreHeadEmail(e.target.value);

  //CENTRE ORGANIZATION HANDLE CHANGE
  const onCentreTypeChanged = (e) => setCentreType(e.target.value);
  const onSatelliteCentreName1Changed = (e) => setSatelliteCentreName1(e.target.value);
  const onSatelliteCentreAddress1Changed = (e) => setSatelliteCentreAddress1(e.target.value);
  const onSatelliteCentreName2Changed = (e) => setSatelliteCentreName2(e.target.value);
  const onSatelliteCentreAddress2Changed = (e) => setSatelliteCentreAddress2(e.target.value);
  const onOtherApprovalsChange = (event) => {
    setOtherApprovals({ ...otherApprovals, [event.target.name]: event.target.checked });
  };
  const { itp, naddc, nabteb, cityGuild } = otherApprovals;
  const onAwardingBody1Changed = (e) => setAwardingBody1(e.target.value);
  const onAwardingBody1LevelChanged = (e) => setAwardingBody1Level(e.target.value);
  const onAwardingBody2Changed = (e) => setAwardingBody2(e.target.value);
  const onAwardingBody2LevelChanged = (e) => setAwardingBody2Level(e.target.value);

  //HUMAN & FINANCIAL RESOURCES HANDLE CHANGE
  const onAssessorsCountChanged = (e) => setAssessorsCount(e.target.value);
  const onVerifiersCountChanged = (e) => setVerifiersCount(e.target.value);

  //ORGANIZATIONAL STAFFING HANDLE CHANGE
  const onCentreManagerNameChanged = (e) => setCentreManagerName(e.target.value);
  const onCentreManagerQualificationChanged = (e) => setCentreManagerQualification(e.target.value);
  const onAssessor1NameChanged = (e) => setAssessor1Name(e.target.value);
  const onAssessor1QualificationChanged = (e) => setAssessor1Qualification(e.target.value);
  const onAssessor2NameChanged = (e) => setAssessor2Name(e.target.value);
  const onAssessor2QualificationChanged = (e) => setAssessor2Qualification(e.target.value);
  const onInternalVerifierNameChanged = (e) => setInternalVerifierName(e.target.value);
  const onInternalVerifierQualificationChanged = (e) =>
    setInternalVerifierQualification(e.target.value);
  const onLiaisonOfficerNameChanged = (e) => setLiaisonOfficerName(e.target.value);
  const onLiaisonOfficerQualificationChanged = (e) =>
    setLiaisonOfficerQualification(e.target.value);
  const onResourceOfficerNameChanged = (e) => setResourceOfficerName(e.target.value);
  const onResourceOfficerQualificationChanged = (e) =>
    setResourceOfficerQualification(e.target.value);
  const onOtherStaffNameChanged = (e) => setOtherStaffName(e.target.value);
  const onOtherStaffQualificationChanged = (e) => setOtherStaffQualification(e.target.value);
  const onOtherStaffDesignationChanged = (e) => setOtherStaffDesignation(e.target.value);

  //PHYSICAL FACILITIES HANDLE CHANGE
  const onTrainingRoomQuantityChanged = (e) => setTrainingRoomQuantity(e.target.value);
  const onTrainingRoomFloorAreaChanged = (e) => setTrainingRoomFloorArea(e.target.value);
  const onWorkshopQuantityChanged = (e) => setWorkshopQuantity(e.target.value);
  const onWorkshopFloorAreaChanged = (e) => setWorkshopFloorArea(e.target.value);
  const onPracticalTrainingSpaceQuantityChanged = (e) =>
    setPracticalTrainingSpaceQuantity(e.target.value);
  const onPracticalTrainingSpaceFloorAreaChanged = (e) =>
    setPracticalTrainingSpaceFloorArea(e.target.value);
  const onTechnicalLabQuantityChanged = (e) => setTechnicalLabQuantity(e.target.value);
  const onTechnicalLabFloorAreaChanged = (e) => setTechnicalLabFloorArea(e.target.value);
  const onRecordsRoomQuantityChanged = (e) => setRecordsRoomQuantity(e.target.value);
  const onRecordsRoomFloorAreaChanged = (e) => setRecordsRoomFloorArea(e.target.value);
  const onAuditoriumQuantityChanged = (e) => setAuditoriumQuantity(e.target.value);
  const onAuditoriumFloorAreaChanged = (e) => setAuditoriumFloorArea(e.target.value);
  const onLibraryQuantityChanged = (e) => setLibraryQuantity(e.target.value);
  const onLibraryFloorAreaChanged = (e) => setLibraryFloorArea(e.target.value);
  const onUtilityQuantityChanged = (e) => setUtilityQuantity(e.target.value);
  const onUtilityFloorAreaChanged = (e) => setUtilityFloorArea(e.target.value);
  const onMgtOfficesQuantityChanged = (e) => setMgtOfficesQuantity(e.target.value);
  const onMgtOfficesFloorAreaChanged = (e) => setMgtOfficesFloorArea(e.target.value);
  const onStaffRoomQuantityChanged = (e) => setStaffRoomQuantity(e.target.value);
  const onStaffRoomFloorAreaChanged = (e) => setStaffRoomFloorArea(e.target.value);
  const onToiletQuantityChanged = (e) => setToiletQuantity(e.target.value);
  const onToiletFloorAreaChanged = (e) => setToiletFloorArea(e.target.value);
  const onFirstAidFireFacilityQuantityChanged = (e) =>
    setFirstAidFireFacilityQuantity(e.target.value);
  const onFirstAidFireFacilityFloorAreaChanged = (e) =>
    setFirstAidFireFacilityFloorArea(e.target.value);
  const onHostelQuantityChanged = (e) => setHostelQuantity(e.target.value);
  const onHostelFloorAreaChanged = (e) => setHostelFloorArea(e.target.value);
  const onTradeToolsQuantityChanged = (e) => setTradeToolsQuantity(e.target.value);
  const onTradeToolsFloorAreaChanged = (e) => setTradeToolsFloorArea(e.target.value);
  const onOtherFacilityNameChanged = (e) => setOtherFacilityName(e.target.value);
  const onOtherFacilityQuantityChanged = (e) => setOtherFacilityQuantity(e.target.value);
  const onOtherFacilityFloorAreaChanged = (e) => setOtherFacilityFloorArea(e.target.value);

  //TRAINING STAFF HANDLE CHANGE
  const onTrainingStaff1NameChanged = (e) => setTrainingStaff1Name(e.target.value);
  const onTrainingStaff1QualificationChanged = (e) =>
    setTrainingStaff1Qualification(e.target.value);
  const onTrainingStaff1ExperienceChanged = (e) => setTrainingStaff1Experience(e.target.value);
  const onTrainingStaff2NameChanged = (e) => setTrainingStaff2Name(e.target.value);
  const onTrainingStaff2QualificationChanged = (e) =>
    setTrainingStaff2Qualification(e.target.value);
  const onTrainingStaff2ExperienceChanged = (e) => setTrainingStaff2Experience(e.target.value);
  const onTrainingStaff3NameChanged = (e) => setTrainingStaff3Name(e.target.value);
  const onTrainingStaff3QualificationChanged = (e) =>
    setTrainingStaff3Qualification(e.target.value);
  const onTrainingStaff3ExperienceChanged = (e) => setTrainingStaff3Experience(e.target.value);

  //SUPPORTING DOCS & DECLARATION HANDLE CHANGE
  const onApplicantsNameChanged = (e) => setApplicantsName(e.target.value);
  const onApplicantsPositionChanged = (e) => setApplicantsPosition(e.target.value);

  const onSelectFile = (setter) => (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setter(e.target.files[0]);
    }
  };

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const validateStepNine = () => {
    return applicantsName.trim() !== "" && applicantsPosition.trim() !== "";
  };

  // Form submission handler
  const onSaveCentreApplicationClicked = async (e) => {
    e.preventDefault();

    if (activeStep === 9 && !validateStepNine()) {
      showNotification(
        "error",
        `Please accurately fill all required fields (*) before submitting.`
      );
      return;
    }

    try {
      // Step 1: Store file URLs in a temporary object
      let fileUrls = {};

      const files = [
        { key: "centreProfileDoc", file: centreProfileDoc },
        { key: "centreProofOfReg", file: centreProofOfReg },
        { key: "centreStaffList", file: centreStaffList },
        { key: "centreStaffCVs", file: centreStaffCVs },
        { key: "centreMOU", file: centreMOU },
        { key: "centreEquipmentList", file: centreEquipmentList },
        { key: "centrePolicyAndProcedures", file: centrePolicyAndProcedures },
        { key: "centreTimetableAndManual", file: centreTimetableAndManual },
        { key: "otherCentreDocs", file: otherCentreDocs },
        { key: "centrePhotos", file: centrePhotos },
      ].filter(({ file }) => file !== null); // Filter out null files

      const uploadRequests = files.map(({ key, file }) => {
        const fileFormData = new FormData();
        fileFormData.append(key, file);

        return axios.post(`${apiUrl}/api/centreapplications/upload-${key}`, fileFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      });

      // Step 2: Await all uploads and extract file paths
      const responses = await Promise.all(uploadRequests);
      responses.forEach((response, index) => {
        const key = files[index].key;
        fileUrls[key] = response.data.filePath; // Store file paths in the object
      });

      // Ensure at least one file URL exists before submission
      const uploadedUrls = Object.values(fileUrls).filter((url) => url);
      if (uploadedUrls.length === 0) {
        showNotification("error", "At least one document must be uploaded before submitting.");
        return;
      } else {
        // Step 3: Prepare form data using uploaded URLs
        const formData = {
          centreName,
          cacNumber,
          yearOfInc: yearOfInc.$y || "",
          permanentCentreAddress,
          trainingCentreAddress,
          phone,
          email,
          website,
          centreHead,
          centreHeadEmail,
          centreHeadPhone,
          qualifications: qualifications
            .map(
              (qualification) =>
                qualificationsData.find((data) => data.qualificationType === qualification)?.id
            )
            .filter((id) => id),
          zone,
          stateOfRez,
          centreType,
          centreProfileDocUrl: fileUrls.centreProfileDoc,
          centreProofOfRegUrl: fileUrls.centreProofOfReg,
          centreStaffListUrl: fileUrls.centreStaffList,
          centreStaffCVsUrl: fileUrls.centreStaffCVs,
          centreMOUUrl: fileUrls.centreMOU,
          centreEquipmentListUrl: fileUrls.centreEquipmentList,
          centrePolicyAndProceduresUrl: fileUrls.centrePolicyAndProcedures,
          centreTimetableAndManualUrl: fileUrls.centreTimetableAndManual,
          otherCentreDocsUrl: fileUrls.otherCentreDocs,
          centrePhotosUrl: fileUrls.centrePhotos,
          satelliteCentreName1,
          satelliteCentreAddress1,
          satelliteCentreName2,
          satelliteCentreAddress2,
          otherApprovals,
          awardingBody1,
          awardingBody1Qualification,
          awardingBody1Level,
          awardingBody1DateApproved,
          awardingBody1ApprovalStatus,
          awardingBody2,
          awardingBody2Qualification,
          awardingBody2Level,
          awardingBody2DateApproved,
          awardingBody2ApprovalStatus,
          nsqCertifiedAssessors,
          assessorsCount,
          nsqCertifiedVerifiers,
          verifiersCount,
          nsqCertifiedCentreManager,
          liaisonOfficerPresent,
          resourceOfficerPresent,
          siteBankPresent,
          trainingRoomAdequate,
          dataRoomAdequate,
          workshopAdequate,
          practicalSpaceAdequate,
          techLabAdequate,
          staffRoomAdequate,
          toiletAdequate,
          firstAidFireAdequate,
          hostelsAdequate,
          tradeToolsAdequate,
          auditoriumAvailable,
          libraryAdequate,
          waterAndPowerAdequate,
          mgtOfficesAdequate,
          nosAvailable,
          trainingManualAvailable,
          nosAndManualAlignment,
          otherTrainingMaterialsAvailable,
          nsqGuidelineAvailable,
          staffCompliantWithStandards,
          objectivesClear,
          classParticipation,
          centreManagerName,
          centreManagerQualification,
          assessor1Name,
          assessor1Qualification,
          assessor2Name,
          assessor2Qualification,
          internalVerifierName,
          internalVerifierQualification,
          liaisonOfficerName,
          liaisonOfficerQualification,
          resourceOfficerName,
          resourceOfficerQualification,
          otherStaffName,
          otherStaffQualification,
          otherStaffDesignation,
          trainingRoomQuantity,
          trainingRoomFloorArea,
          workshopQuantity,
          workshopFloorArea,
          practicalTrainingSpaceQuantity,
          practicalTrainingSpaceFloorArea,
          technicalLabQuantity,
          technicalLabFloorArea,
          recordsRoomQuantity,
          recordsRoomFloorArea,
          auditoriumQuantity,
          auditoriumFloorArea,
          libraryQuantity,
          libraryFloorArea,
          utilityQuantity,
          utilityFloorArea,
          mgtOfficesQuantity,
          mgtOfficesFloorArea,
          staffRoomQuantity,
          staffRoomFloorArea,
          toiletQuantity,
          toiletFloorArea,
          firstAidFireFacilityQuantity,
          firstAidFireFacilityFloorArea,
          hostelQuantity,
          hostelFloorArea,
          tradeToolsQuantity,
          tradeToolsFloorArea,
          otherFacilityName,
          otherFacilityQuantity,
          otherFacilityFloorArea,
          trainingStaff1Name,
          trainingStaff1Gender,
          trainingStaff1Qualification,
          trainingStaff1Experience,
          trainingStaff1Specialization,
          trainingStaff2Name,
          trainingStaff2Gender,
          trainingStaff2Qualification,
          trainingStaff2Experience,
          trainingStaff2Specialization,
          trainingStaff3Name,
          trainingStaff3Gender,
          trainingStaff3Qualification,
          trainingStaff3Experience,
          trainingStaff3Specialization,
          applicantsName,
          applicantsPosition,
        };

        // Step 4: Submit the form data
        const response = await axios.post(
          `${apiUrl}/api/centreapplications/addCentreApplication`,
          formData
        );

        if (response) {
          showNotification("success", "Centre Application submitted successfully!");

          // Redirect after a delay
          setTimeout(() => navigate("/"), 7000);
        }
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      showNotification("error", error.response?.data?.message || "Submission failed");
    }
  };

  // Options for autocomplete components
  const options3 = qualificationsData.map((qualification) => ({
    id: qualification.id,
    qualificationType: qualification.qualificationType,
  }));

  const [selectedZone, setSelectedZone] = useState(""); // Stores selected zone
  const [filteredStates, setFilteredStates] = useState([]); // Stores filtered states

  // Handle zone selection
  const handleZoneChange = (zone) => {
    setSelectedZone(zone);
    setFilteredStates(
      Object.entries(STATES)
        .filter(([key]) => ZONE_STATES[zone]?.includes(key))
        .map(([key, value]) => ({ key, state: value }))
    );
  };

  const options4 = Object.entries(ZONES).map(([key, value]) => ({
    key,
    zone: value,
  }));

  const options1 = filteredStates;

  const options0 = Object.entries(CENTRETYPES).map(([key, value]) => ({
    key,
    centreType: value,
  }));

  // Ensure values are always valid objects or null
  const mapValuesToOptions = (values, options, key) => {
    if (!Array.isArray(values) || !Array.isArray(options)) return [];
    return values
      .map((value) => options.find((option) => option[key] === value))
      .filter((option) => option); // Ensure no undefined values
  };

  const validateStepOne = () => {
    return (
      centreName.trim() !== "" &&
      validCentreName &&
      yearOfInc &&
      cacNumber.trim() !== "" &&
      permanentCentreAddress.trim() !== "" &&
      phone.trim() !== "" &&
      email.trim() !== "" &&
      validEmail &&
      centreHead.trim() !== "" &&
      centreHeadPhone.trim() !== "" &&
      qualifications.length > 0 &&
      zone.trim() !== "" &&
      stateOfRez.trim() !== "" &&
      centreProfileDoc !== null &&
      centreProofOfReg !== null
    );
  };

  const validateStepTwo = () => {
    return centreType.trim() !== "";
  };

  const validateStepThree = () => {
    return (
      nsqCertifiedAssessors.trim() !== "" &&
      assessorsCount >= 0 &&
      nsqCertifiedVerifiers.trim() !== "" &&
      verifiersCount >= 0 &&
      nsqCertifiedCentreManager.trim() !== "" &&
      liaisonOfficerPresent.trim() !== "" &&
      resourceOfficerPresent.trim() !== "" &&
      siteBankPresent.trim() !== ""
    );
  };

  const validateStepFour = () => {
    return (
      trainingRoomAdequate.trim() !== "" &&
      dataRoomAdequate.trim() !== "" &&
      workshopAdequate.trim() !== "" &&
      practicalSpaceAdequate.trim() !== "" &&
      techLabAdequate.trim() !== "" &&
      staffRoomAdequate.trim() !== "" &&
      toiletAdequate.trim() !== "" &&
      firstAidFireAdequate.trim() !== "" &&
      hostelsAdequate.trim() !== "" &&
      tradeToolsAdequate.trim() !== "" &&
      auditoriumAvailable.trim() !== "" &&
      libraryAdequate.trim() !== "" &&
      waterAndPowerAdequate.trim() !== "" &&
      mgtOfficesAdequate.trim() !== ""
    );
  };

  const validateStepFive = () => {
    return (
      nosAvailable.trim() !== "" &&
      trainingManualAvailable.trim() !== "" &&
      nosAndManualAlignment.trim() !== "" &&
      otherTrainingMaterialsAvailable.trim() !== "" &&
      nsqGuidelineAvailable.trim() !== "" &&
      staffCompliantWithStandards.trim() !== "" &&
      objectivesClear.trim() !== "" &&
      classParticipation.trim() !== "" &&
      centrePolicyAndProcedures !== null &&
      centreTimetableAndManual !== null
    );
  };

  const validateStepSix = () => {
    return (
      centreManagerName.trim() !== "" &&
      centreManagerQualification.trim() !== "" &&
      assessor1Name.trim() !== "" &&
      assessor1Qualification.trim() !== "" &&
      internalVerifierName.trim() !== "" &&
      internalVerifierQualification.trim() !== ""
    );
  };

  const validateStepEight = () => {
    return centreStaffCVs !== null && centreStaffList !== null;
  };

  const handleNext = () => {
    const asterix = "*";
    if (activeStep === 1 && !validateStepOne()) {
      showNotification(
        "error",
        `Please accurately fill all required fields (${asterix}) before proceeding.`
      );
      return;
    } else if (activeStep === 2 && !validateStepTwo()) {
      showNotification(
        "error",
        `Please accurately fill all required fields (${asterix}) before proceeding.`
      );
      return;
    } else if (activeStep === 3 && !validateStepThree()) {
      showNotification(
        "error",
        `Please accurately fill all required fields (${asterix}) before proceeding.`
      );
      return;
    } else if (activeStep === 4 && !validateStepFour()) {
      showNotification(
        "error",
        `Please accurately fill all required fields (${asterix}) before proceeding.`
      );
      return;
    } else if (activeStep === 5 && !validateStepFive()) {
      showNotification(
        "error",
        `Please accurately fill all required fields (${asterix}) before proceeding.`
      );
      return;
    } else if (activeStep === 6 && !validateStepSix()) {
      showNotification(
        "error",
        `Please accurately fill all required fields (${asterix}) before proceeding.`
      );
      return;
    } else if (activeStep === 8 && !validateStepEight()) {
      showNotification(
        "error",
        `Please accurately fill all required fields (${asterix}) before proceeding.`
      );
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <MDBox sx={{ pl: 5, pr: 5 }}>
            <MDTypography sx={{ color: "#295C21", fontSize: 17, mb: 3 }}>
              APPLICATION / APPROVAL FORM INSTRUCTIONS
            </MDTypography>

            <MDBox sx={{ maxWidth: { xs: "100%", md: "80%" } }}>
              <MDTypography sx={{ color: "#0b1c08", fontSize: 16, wordWrap: "break-word", mb: 3 }}>
                Please use this form to request approval as Training/Assessment Centre for NSQs
                Complete all sections in BLOCK LETTERS and give your details as you want them to
                appear in NIOB records.
              </MDTypography>
              <MDTypography sx={{ color: "#49a139", fontSize: 16, wordWrap: "break-word" }}>
                Read the following conditions which apply to this approval request, before you
                complete this form:
              </MDTypography>
              <List sx={{ fontSize: 14, wordWrap: "break-word" }}>
                <ListItem>
                  1. You will be required to pay an inspection fee which will be invoiced to your
                  Account.
                </ListItem>
                <ListItem>
                  2. Payment for an inspection visit fee is required per venue/satellite Centre.
                </ListItem>
                <ListItem>
                  3. Your Centre and Qualification(s) approval will lapse if there is no activity
                  within one year of approval being granted.
                </ListItem>
                <ListItem>
                  4. Failure to provide accurate information may result in withdrawal of any
                  Approval granted earlier.
                </ListItem>
                <ListItem sx={{ pb: 0 }}>
                  5. You&rsquo;ll need the following documents to complete your application:
                </ListItem>
                <List sx={{ ml: "20px" }}>
                  <ListItem sx={{ pt: 0, pb: 0 }}> - Evidence of Legal Registration</ListItem>
                  <ListItem sx={{ pt: 0, pb: 0 }}> - Centre Profile</ListItem>
                  <ListItem sx={{ pt: 0, pb: 0 }}> - Proof of Payment (NGN 150,000.00)</ListItem>
                  <ListItem sx={{ pt: 0, pb: 0 }}>
                    - Completed Centre Manager Declaration Form
                    <a
                      href="/2024-CENTRE APPLICATION Declaration FORM.pdf" // Adjusted path
                      style={{ color: "#49a139", textDecoration: "underline" }}
                      target="_blank"
                      download="2024-CENTRE_APPLICATION_Declaration_FORM.pdf" // Added download attribute
                    >
                      <u>(download the form here)</u>
                    </a>
                  </ListItem>
                  <ListItem sx={{ pt: 0, pb: 0 }}> - MOU (Site Banks / Facility Usage)</ListItem>
                  <ListItem sx={{ pt: 0, pb: 0 }}> - Training Staff CVs </ListItem>
                  <ListItem sx={{ pt: 0, pb: 0 }}> - List of Equipment </ListItem>
                </List>
              </List>
            </MDBox>
          </MDBox>
        );

      case 1:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              CENTRE DETAILS
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Centre Name <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreNameChanged}
              value={centreName}
              name="centreName"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="standard"
                label={
                  <span>
                    Year of Incorporation <span style={{ color: "red" }}> *</span>
                  </span>
                }
                openTo="year"
                onChange={(newValue) => setYearOfInc(newValue)}
                value={yearOfInc ? yearOfInc : dayjs("")}
                views={["year"]}
                maxDate={dayjs(maxYear)}
                minDate={dayjs("1960")}
                name="yearOfInc"
                sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
              />
            </LocalizationProvider>

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  CAC Number <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCACChanged}
              value={cacNumber}
              name="cacNumber"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
              InputProps={{
                startAdornment: <InputAdornment position="start">RC</InputAdornment>,
              }}
            />

            <MDInput
              variant="standard"
              multiline
              rows={3}
              type="text"
              label={
                <span>
                  Permanent Centre Address <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onPermanentCentreAddressChanged}
              value={permanentCentreAddress}
              name="permanentCentreAddress"
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" } }}
            />

            <MDInput
              variant="standard"
              multiline
              rows={3}
              type="text"
              label="Training Centre Address"
              onChange={onTrainingCentreAddressChanged}
              value={trainingCentreAddress}
              name="trainingCentreAddress"
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Phone Number <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onPhoneChanged}
              value={phone}
              name="phone"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+234</InputAdornment>,
              }}
            />

            <MDInput
              variant="standard"
              type="email"
              label={
                <span>
                  Email <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onEmailChanged}
              value={email}
              name="email"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Website"
              onChange={onWebsiteChanged}
              value={website}
              name="website"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Centre Head Fullname <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreHeadChanged}
              value={centreHead}
              name="centreHead"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Centre Head Phone Number <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreHeadPhoneChanged}
              value={centreHeadPhone}
              name="centreHeadPhone"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+234</InputAdornment>,
              }}
            />

            <MDInput
              variant="standard"
              type="email"
              label="Centre Head Email"
              onChange={onCentreHeadEmailChanged}
              value={centreHeadEmail}
              name="centreHeadEmail"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
            />

            <MDAutocomplete
              id="zone-selector"
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" } }}
              options={options4}
              value={options4.find((option) => option.zone === zone) || null} // Set a single value or null if no match
              onChange={(event, value) => {
                setZone(value?.zone || "");
                handleZoneChange(value?.key || ""); // Pass zone key to filter states
              }} // Set only one zone value
              getOptionLabel={(option) => option.zone} // Access the zone property for display
              isOptionEqualToValue={(option, value) => option.zone === value?.zone}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label={
                    <span>
                      Select Zone <span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  variant="standard"
                />
              )}
            />

            <MDAutocomplete
              id="state-selector"
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" } }}
              options={filteredStates} // Updated: Use dynamically filtered states
              value={filteredStates.find((option) => option.state === stateOfRez) || null} // Set null if no selection
              onChange={(event, value) => setStateOfRez(value?.state || "")} // Update stateOfRez based on selection
              getOptionLabel={(option) => option.state} // Display the state property for options
              isOptionEqualToValue={(option, value) => option.state === value?.state} // Match state
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label={
                    <span>
                      Select State <span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  variant="standard"
                />
              )}
            />

            <MDAutocomplete
              id="qualification-selector"
              sx={{ gridColumn: "span 6", mb: "30px" }}
              options={options3}
              multiple
              value={mapValuesToOptions(qualifications || [], options3 || [], "qualificationType")}
              onChange={(event, value) =>
                setQualifications(value.map((item) => item.qualificationType))
              }
              getOptionLabel={(option) => option.qualificationType || ""}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label={
                    <span>
                      Select Qualifications <span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  variant="standard"
                />
              )}
            />

            <MDBox sx={{ gridColumn: "span 6", mt: 2 }}>
              <Grid container spacing={{ xs: 2, md: 6 }} xs={12} sx={{ mb: { xs: 2, md: -7 } }}>
                <Grid item xs={12} sm={6}>
                  {centreProfileDoc && (
                    <MDTypography variant="body2">
                      <strong>Centre Profile:</strong>
                      &nbsp;<span style={{ color: "#4CAF50" }}>{centreProfileDoc.name}</span>
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  {centreProofOfReg && (
                    <MDTypography variant="body2">
                      <strong>Centre Proof of Reg.:</strong>
                      &nbsp;<span style={{ color: "#4CAF50" }}>{centreProofOfReg.name}</span>
                    </MDTypography>
                  )}
                </Grid>
              </Grid>
            </MDBox>

            <MDButton
              component="label"
              role={undefined}
              variant="gradient"
              color="dark"
              tabIndex={-1}
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" }, mb: { xs: 0, md: 5 } }}
              startIcon={<CloudUploadIcon />}
            >
              Upload Centre Profile &nbsp;<span style={{ color: "red" }}> *</span>
              <VisuallyHiddenInput type="file" onChange={onSelectFile(setCentreProfileDoc)} />
            </MDButton>

            <MDButton
              component="label"
              role={undefined}
              variant="gradient"
              color="dark"
              tabIndex={-1}
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" }, mb: 5 }}
              startIcon={<CloudUploadIcon />}
            >
              Upload Centre CAC Document &nbsp;<span style={{ color: "red" }}> *</span>
              <VisuallyHiddenInput type="file" onChange={onSelectFile(setCentreProofOfReg)} />
            </MDButton>
          </MDBox>
        );

      case 2:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              YOUR ORGANISATION
            </MDTypography>
            <MDAutocomplete
              id="centretype-selector"
              sx={{ gridColumn: "span 6" }}
              options={options0}
              value={options0.find((option) => option.centreType === centreType) || null} // Set a single value or null if no match
              onChange={(event, value) => setCentreType(value?.centreType || "")} // Set only one centreType value
              getOptionLabel={(option) => option.centreType} // Access the centreType property for display
              isOptionEqualToValue={(option, value) => option.centreType === value?.centreType}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label={
                    <span>
                      Select Centre Type <span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  variant="standard"
                />
              )}
            />

            <MDTypography sx={{ gridColumn: "span 6", fontSize: "15px", mb: "-10px" }}>
              - Satellite Centres where Training is provided other than your Main Campus/Centre
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              label="Satellite Centre Name 1"
              onChange={onSatelliteCentreName1Changed}
              value={satelliteCentreName1}
              name="satelliteCentreName1"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Satellite Centre Address 1"
              onChange={onSatelliteCentreAddress1Changed}
              value={satelliteCentreAddress1}
              name="satelliteCentreAddress1"
              sx={{ gridColumn: { xs: "span 6", sm: "span 4" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Satellite Centre Name 2"
              onChange={onSatelliteCentreName2Changed}
              value={satelliteCentreName2}
              name="satelliteCentreName2"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Satellite Centre Address 2"
              onChange={onSatelliteCentreAddress2Changed}
              value={satelliteCentreAddress2}
              name="satelliteCentreAddress2"
              sx={{ gridColumn: { xs: "span 6", sm: "span 4" }, mb: "30px" }}
            />

            <FormControl fullWidth sx={{ gridColumn: "span 6" }}>
              <FormLabel component="legend" sx={{ color: "#49a139", fontSize: "15px" }}>
                Indicate if you are currently an approved Centre of another Awarding Body
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={itp}
                      onChange={onOtherApprovalsChange}
                      name="itp"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="ITP"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={naddc}
                      onChange={onOtherApprovalsChange}
                      name="naddc"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="NADDC"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nabteb}
                      onChange={onOtherApprovalsChange}
                      name="nabteb"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="NABTEB"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cityGuild}
                      onChange={onOtherApprovalsChange}
                      name="cityGuild"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="City & Guilds"
                />
              </FormGroup>
            </FormControl>

            <MDTypography sx={{ gridColumn: "span 6", fontSize: "15px" }}>
              If your Centre is currently approved as a Centre for NSQ by another Awarding Body,
              please give details below:
            </MDTypography>

            <MDInput
              variant="standard"
              type="text"
              label="Awarding Body 1"
              onChange={onAwardingBody1Changed}
              value={awardingBody1}
              name="awardingBody1"
              sx={{ gridColumn: { xs: "span 6", sm: "span 1" } }}
            />

            <MDAutocomplete
              id="awardingBody1-qualification-selector"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === awardingBody1Qualification
                ) || null
              } // Set null if no selection
              onChange={(event, value) =>
                setAwardingBody1Qualification(value?.qualificationType || "")
              } // Update the stateOfRez based on selection
              getOptionLabel={(option) => option.qualificationType} // Display the state property for options
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              } // Match state
              renderInput={(params) => (
                <MDInput {...params} label="Qualification 1" variant="standard" />
              )}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Level"
              onChange={onAwardingBody1LevelChanged}
              value={awardingBody1Level}
              name="awardingBody1Level"
              sx={{ gridColumn: { xs: "span 6", sm: "span 1" } }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="standard"
                label={<span>Date Approved</span>}
                value={
                  awardingBody1DateApproved ? dayjs(awardingBody1DateApproved, "DD-MM-YYYY") : null
                }
                onChange={(newValue) =>
                  setAwardingBody1DateApproved(newValue ? newValue.format("DD-MM-YYYY") : "")
                }
                views={["year", "month", "day"]}
                maxDate={dayjs()} // Today's date
                minDate={dayjs("1960-01-01")} // Min date set to 1960
                name="awardingBody1DateApproved"
                sx={{ gridColumn: { xs: "span 6", sm: "span 1" } }}
                renderInput={(params) => (
                  <MDInput {...params} value={awardingBody1DateApproved || ""} /> // Format displayed value
                )}
              />
            </LocalizationProvider>

            <MDAutocomplete
              id="awardingBody1ApprovalStatus-selector"
              sx={{ gridColumn: { xs: "span 6", sm: "span 1" } }}
              options={[
                { label: "Done", value: "Done" },
                { label: "Pending", value: "Pending" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option?.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Approval Status"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setAwardingBody1ApprovalStatus(value?.value || ""); // Update state based on selection
              }}
              isOptionEqualToValue={
                (option, value) => option?.value === value?.value // Safely compare option and value
              }
            />

            <MDInput
              variant="standard"
              type="text"
              label="Awarding Body 2"
              onChange={onAwardingBody2Changed}
              value={awardingBody2}
              name="awardingBody2"
              sx={{ gridColumn: { xs: "span 6", sm: "span 1" } }}
            />

            <MDAutocomplete
              id="awardingBody2-qualification-selector"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === awardingBody2Qualification
                ) || null
              } // Set null if no selection
              onChange={(event, value) =>
                setAwardingBody2Qualification(value?.qualificationType || "")
              } // Update the stateOfRez based on selection
              getOptionLabel={(option) => option.qualificationType} // Display the state property for options
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              } // Match state
              renderInput={(params) => (
                <MDInput {...params} label="Qualification 2" variant="standard" />
              )}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Level"
              onChange={onAwardingBody2LevelChanged}
              value={awardingBody2Level}
              name="awardingBody2Level"
              sx={{ gridColumn: { xs: "span 6", sm: "span 1" } }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="standard"
                label={<span>Date Approved</span>}
                value={
                  awardingBody2DateApproved ? dayjs(awardingBody2DateApproved, "DD-MM-YYYY") : null
                }
                onChange={(newValue) =>
                  setAwardingBody2DateApproved(newValue ? newValue.format("DD-MM-YYYY") : "")
                }
                views={["year", "month", "day"]}
                maxDate={dayjs()} // Today's date
                minDate={dayjs("1960-01-01")} // Min date set to 1960
                name="awardingBody2DateApproved"
                sx={{ gridColumn: { xs: "span 6", sm: "span 1" } }}
                renderInput={(params) => (
                  <MDInput {...params} value={awardingBody2DateApproved || ""} /> // Format displayed value
                )}
              />
            </LocalizationProvider>

            <MDAutocomplete
              id="awardingBody2ApprovalStatus-selector"
              sx={{ gridColumn: { xs: "span 6", sm: "span 1" }, mb: "30px" }}
              options={[
                { label: "Done", value: "Done" },
                { label: "Pending", value: "Pending" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Approval Status"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setAwardingBody2ApprovalStatus(value?.value || "");
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </MDBox>
        );

      case 3:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              HUMAN & FINANCIAL RESOURCES
            </MDTypography>
            {[
              {
                id: "nsqCertifiedAssessor-selector",
                question: "Are there NSQ certified Assessors at your Centre?",
                value: nsqCertifiedAssessors,
                setValue: setNsqCertifiedAssessors,
              },
              {
                id: "assessorsCount",
                question: "How many Assessors are available at Centre?",
                value: assessorsCount,
                setValue: onAssessorsCountChanged,
                type: "number",
              },
              {
                id: "nsqCertifiedVerifier-selector",
                question: "Are there NSQ certified Verifiers at your Centre?",
                value: nsqCertifiedVerifiers,
                setValue: setNsqCertifiedVerifiers,
              },
              {
                id: "verifiersCount",
                question: "How many Verifiers are available at Centre?",
                value: verifiersCount,
                setValue: onVerifiersCountChanged,
                type: "number",
              },
              {
                id: "nsqCertifiedCentreManager-selector",
                question: "Is the Centre Manager NSQ certified?",
                value: nsqCertifiedCentreManager,
                setValue: setNsqCertifiedCentreManager,
              },
              {
                id: "liaisonOfficerPresent-selector",
                question: "Is there a Liaison Officer with the Industry at your Centre?",
                value: liaisonOfficerPresent,
                setValue: setLiaisonOfficerPresent,
              },
              {
                id: "resourceOfficerPresent-selector",
                question: "Is there a Resource/Data Officer at your Centre?",
                value: resourceOfficerPresent,
                setValue: setResourceOfficerPresent,
              },
              {
                id: "siteBankPresent-selector",
                question: `Are there evidences/records of site-banks for apprenticeship training
                at your Centre?`,
                value: siteBankPresent,
                setValue: setSiteBankPresent,
              },
            ].map(({ id, question, value, setValue, type }, index) => (
              <MDBox
                key={id}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { sm: "center" },
                  gap: 2,
                  mb: 3, // Adds spacing between each question-answer set
                  gridColumn: "span 6",
                }}
              >
                <MDTypography sx={{ width: { sm: "60%" }, fontSize: "15px" }}>
                  {index + 1}.{"\u00A0"} {question}
                  <span style={{ color: "red" }}> *</span>
                </MDTypography>

                {type === "number" ? (
                  <MDInput
                    variant="standard"
                    type="number"
                    label="Enter number"
                    onChange={setValue}
                    value={value}
                    InputProps={{ inputProps: { min: 0 } }}
                    name={id}
                    sx={{ width: { xs: "100%", sm: "20%" } }}
                  />
                ) : (
                  <MDAutocomplete
                    id={id}
                    sx={{ width: { xs: "100%", sm: "20%" } }}
                    options={[
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ]}
                    value={value ? { label: value, value } : null}
                    getOptionLabel={(option) => option?.label || ""}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        fullWidth
                        label="Select if in place"
                        variant="standard"
                        aria-autocomplete="list"
                        spellCheck={false}
                        autoComplete="off"
                        autoCapitalize="none"
                      />
                    )}
                    onChange={(event, newValue) => setValue(newValue?.value || "")}
                    isOptionEqualToValue={(option, newValue) => option?.value === newValue?.value}
                  />
                )}
              </MDBox>
            ))}
          </MDBox>
        );

      case 4:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              PHYSICAL INFRASTRUCTURE
            </MDTypography>
            {[
              {
                id: "trainingRoomAdequate-selector",
                question: `Are the Classrooms/Training Rooms adequate in terms of space,
                lighting, ventilation & traffic?`,
                value: trainingRoomAdequate,
                setValue: setTrainingRoomAdequate,
              },
              {
                id: "dataRoomAdequate-selector",
                question: `Is there a Data/Records room available and adequate?`,
                value: dataRoomAdequate,
                setValue: setDataRoomAdequate,
              },
              {
                id: "workshopAdequate-selector",
                question: `Are Workshop spaces adequate and fit for purpose?`,
                value: workshopAdequate,
                setValue: setWorkshopAdequate,
              },
              {
                id: "practicalSpaceAdequate-selector",
                question: `Are Practical training spaces available and suitable for each trade?`,
                value: practicalSpaceAdequate,
                setValue: setPracticalSpaceAdequate,
              },
              {
                id: "techLabAdequate-selector",
                type: "other",
                question: `Are Technical laboratories AVAILABLE or HIRED?`,
                value: techLabAdequate,
                setValue: setTechLabAdequate,
              },
              {
                id: "staffRoomAdequate-selector",
                question: `Are Staff rooms adequate and fit for purpose?`,
                value: staffRoomAdequate,
                setValue: setStaffRoomAdequate,
              },
              {
                id: "toiletAdequate-selector",
                question: `Are Toilets available and functional?`,
                value: toiletAdequate,
                setValue: setToiletAdequate,
              },
              {
                id: "firstAidFireAdequate-selector",
                question: `Are First Aid/Firefighting facilities available and functional?`,
                value: firstAidFireAdequate,
                setValue: setFirstAidFireAdequate,
              },
              {
                id: "hostelsAdequate-selector",
                question: `Are Hostels available and fit for purpose?`,
                value: hostelsAdequate,
                setValue: setHostelsAdequate,
              },
              {
                id: "tradeToolsAdequate-selector",
                question: `Are there Tools and Equipment for relevant trades at your Centre?`,
                value: tradeToolsAdequate,
                setValue: setTradeToolsAdequate,
              },
              {
                id: "auditoriumAvailable-selector",
                type: "other",
                question: `Is Auditorium AVAILABLE or HIRED?`,
                value: auditoriumAvailable,
                setValue: setAuditoriumAvailable,
              },
              {
                id: "libraryAdequate-selector",
                question: `Are Library and Study rooms adequate?`,
                value: libraryAdequate,
                setValue: setLibraryAdequate,
              },
              {
                id: "waterAndPowerAdequate-selector",
                question: `Is Water and Power supply adequate?`,
                value: waterAndPowerAdequate,
                setValue: setWaterAndPowerAdequate,
              },
              {
                id: "mgtOfficesAdequate-selector",
                question: `Are Management Offices adequate and fit for purpose?`,
                value: mgtOfficesAdequate,
                setValue: setMgtOfficesAdequate,
              },
            ].map(({ id, question, value, setValue, type }, index) => (
              <MDBox
                key={id}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { sm: "center" },
                  gap: 2,
                  mb: 3, // Adds spacing between each question-answer set
                  gridColumn: "span 6",
                }}
              >
                <MDTypography sx={{ width: { sm: "60%" }, fontSize: "15px" }}>
                  {index + 1}.{"\u00A0"} {question}
                  <span style={{ color: "red" }}> *</span>
                </MDTypography>

                {type === "other" ? (
                  <MDAutocomplete
                    id={id}
                    sx={{ width: { xs: "100%", sm: "20%" } }}
                    options={[
                      { label: "AVAILABLE", value: "AVAILABLE" },
                      { label: "HIRED", value: "HIRED" },
                    ]}
                    value={value ? { label: value, value } : null}
                    getOptionLabel={(option) => option?.label || ""}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        fullWidth
                        label="Select if in place"
                        variant="standard"
                        aria-autocomplete="list"
                        spellCheck={false}
                        autoComplete="off"
                        autoCapitalize="none"
                      />
                    )}
                    onChange={(event, newValue) => setValue(newValue?.value || "")}
                    isOptionEqualToValue={(option, newValue) => option?.value === newValue?.value}
                  />
                ) : (
                  <MDAutocomplete
                    id={id}
                    sx={{ width: { xs: "100%", sm: "20%" } }}
                    options={[
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ]}
                    value={value ? { label: value, value } : null}
                    getOptionLabel={(option) => option?.label || ""}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        fullWidth
                        label="Select if in place"
                        variant="standard"
                        aria-autocomplete="list"
                        spellCheck={false}
                        autoComplete="off"
                        autoCapitalize="none"
                      />
                    )}
                    onChange={(event, newValue) => setValue(newValue?.value || "")}
                    isOptionEqualToValue={(option, newValue) => option?.value === newValue?.value}
                  />
                )}
              </MDBox>
            ))}

            <MDBox sx={{ gridColumn: "span 6", mt: 2 }}>
              <Grid container spacing={{ xs: 2, md: 6 }} xs={12} sx={{ mb: { xs: 2, md: -7 } }}>
                <Grid item xs={12} sm={6}>
                  {centreMOU && (
                    <MDTypography variant="body2">
                      <strong>Centre MOU:</strong>
                      &nbsp;<span style={{ color: "#4CAF50" }}>{centreMOU.name}</span>
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  {centreEquipmentList && (
                    <MDTypography variant="body2">
                      <strong>Centre List of Equipment:</strong>
                      &nbsp;<span style={{ color: "#4CAF50" }}>{centreEquipmentList.name}</span>
                    </MDTypography>
                  )}
                </Grid>
              </Grid>
            </MDBox>

            <MDButton
              component="label"
              role={undefined}
              variant="gradient"
              color="dark"
              tabIndex={-1}
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" }, mb: { xs: 0, md: 5 } }}
              startIcon={<CloudUploadIcon />}
            >
              Upload Centre MOU for Facility Usage
              <VisuallyHiddenInput type="file" onChange={onSelectFile(setCentreMOU)} />
            </MDButton>

            <MDButton
              component="label"
              role={undefined}
              variant="gradient"
              color="dark"
              tabIndex={-1}
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" }, mb: 5 }}
              startIcon={<CloudUploadIcon />}
            >
              Upload Centre List of Equipment
              <VisuallyHiddenInput type="file" onChange={onSelectFile(setCentreEquipmentList)} />
            </MDButton>
          </MDBox>
        );

      case 5:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              CODE OF PRACTICE
            </MDTypography>
            {[
              {
                id: "nosAvailable-selector",
                question: `Is NOS available at your Centre?`,
                value: nosAvailable,
                setValue: setNosAvailable,
              },
              {
                id: "trainingManualAvailable-selector",
                question: `Are there Training Manuals at your Centre?`,
                value: trainingManualAvailable,
                setValue: setTrainingManualAvailable,
              },
              {
                id: "nosAndManualAlignment-selector",
                question: `Are the Manuals aligned to the NOS?`,
                value: nosAndManualAlignment,
                setValue: setNosAndManualAlignment,
              },
              {
                id: "otherTrainingMaterialsAvailable-selector",
                question: `Are there other Training materials that are relevant to the training
                needs?`,
                value: otherTrainingMaterialsAvailable,
                setValue: setOtherTrainingMaterialsAvailable,
              },
              {
                id: "nsqGuidelineAvailable-selector",
                question: `Is there an NSQ Code of Practice and Implementation Guideline at
                your Centre?`,
                value: nsqGuidelineAvailable,
                setValue: setNsqGuidelineAvailable,
              },
              {
                id: "staffCompliantWithStandards-selector",
                question: `Are the Trainers and Staff conversant/comply with these documents?`,
                value: staffCompliantWithStandards,
                setValue: setStaffCompliantWithStandards,
              },
              {
                id: "objectivesClear-selector",
                question: `Are the Objectives of the training clearly defined?`,
                value: objectivesClear,
                setValue: setObjectivesClear,
              },
              {
                id: "classParticipation-selector",
                question: `Is Class participation through questioning encouraged?`,
                value: classParticipation,
                setValue: setClassParticipation,
              },
            ].map(({ id, question, value, setValue, type }, index) => (
              <MDBox
                key={id}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { sm: "center" },
                  gap: 2,
                  mb: 3, // Adds spacing between each question-answer set
                  gridColumn: "span 6",
                }}
              >
                <MDTypography sx={{ width: { sm: "60%" }, fontSize: "15px" }}>
                  {index + 1}.{"\u00A0"} {question}
                  <span style={{ color: "red" }}> *</span>
                </MDTypography>

                {type === "other" ? (
                  <MDAutocomplete
                    id={id}
                    sx={{ width: { xs: "100%", sm: "20%" } }}
                    options={[
                      { label: "AVAILABLE", value: "AVAILABLE" },
                      { label: "HIRED", value: "HIRED" },
                    ]}
                    value={value ? { label: value, value } : null}
                    getOptionLabel={(option) => option?.label || ""}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        fullWidth
                        label="Select if in place"
                        variant="standard"
                        aria-autocomplete="list"
                        spellCheck={false}
                        autoComplete="off"
                        autoCapitalize="none"
                      />
                    )}
                    onChange={(event, newValue) => setValue(newValue?.value || "")}
                    isOptionEqualToValue={(option, newValue) => option?.value === newValue?.value}
                  />
                ) : (
                  <MDAutocomplete
                    id={id}
                    sx={{ width: { xs: "100%", sm: "20%" } }}
                    options={[
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ]}
                    value={value ? { label: value, value } : null}
                    getOptionLabel={(option) => option?.label || ""}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        fullWidth
                        label="Select if in place"
                        variant="standard"
                        aria-autocomplete="list"
                        spellCheck={false}
                        autoComplete="off"
                        autoCapitalize="none"
                      />
                    )}
                    onChange={(event, newValue) => setValue(newValue?.value || "")}
                    isOptionEqualToValue={(option, newValue) => option?.value === newValue?.value}
                  />
                )}
              </MDBox>
            ))}

            <MDBox sx={{ gridColumn: "span 6", mt: 2 }}>
              <Grid container spacing={{ xs: 2, md: 6 }} xs={12} sx={{ mb: { xs: 2, md: -7 } }}>
                <Grid item xs={12} sm={6}>
                  {centrePolicyAndProcedures && (
                    <MDTypography variant="body2">
                      <strong>Centre Policy & Procedures:</strong>
                      &nbsp;
                      <span style={{ color: "#4CAF50" }}>{centrePolicyAndProcedures.name}</span>
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  {centreTimetableAndManual && (
                    <MDTypography variant="body2">
                      <strong>Centre Timetable & Manual:</strong>
                      &nbsp;
                      <span style={{ color: "#4CAF50" }}>{centreTimetableAndManual.name}</span>
                    </MDTypography>
                  )}
                </Grid>
              </Grid>
            </MDBox>

            <MDButton
              component="label"
              role={undefined}
              variant="gradient"
              color="dark"
              tabIndex={-1}
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" }, mb: { xs: 0, md: 5 } }}
              startIcon={<CloudUploadIcon />}
            >
              Upload Centre Policy & Procedures &nbsp;<span style={{ color: "red" }}> *</span>
              <VisuallyHiddenInput
                type="file"
                onChange={onSelectFile(setCentrePolicyAndProcedures)}
              />
            </MDButton>

            <MDButton
              component="label"
              role={undefined}
              variant="gradient"
              color="dark"
              tabIndex={-1}
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" }, mb: 5 }}
              startIcon={<CloudUploadIcon />}
            >
              Upload Centre Timetable & Sample of Manual &nbsp;
              <span style={{ color: "red" }}> *</span>
              <VisuallyHiddenInput
                type="file"
                onChange={onSelectFile(setCentreTimetableAndManual)}
              />
            </MDButton>
          </MDBox>
        );

      case 6:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              ORGANISATIONAL STAFFING
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Centre Manager&apos;s FullName <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreManagerNameChanged}
              value={centreManagerName}
              name="centreManagerName"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Centre Manager&apos;s Qualification <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreManagerQualificationChanged}
              value={centreManagerQualification}
              name="centreManagerQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Assessor 1&apos;s FullName <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onAssessor1NameChanged}
              value={assessor1Name}
              name="assessor1Name"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Assessor 1&apos;s Qualification <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onAssessor1QualificationChanged}
              value={assessor1Qualification}
              name="assessor1Qualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Assessor 2&apos;s FullName</span>}
              onChange={onAssessor2NameChanged}
              value={assessor2Name}
              name="assessor2Name"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Assessor 2&apos;s Qualification</span>}
              onChange={onAssessor2QualificationChanged}
              value={assessor2Qualification}
              name="assessor2Qualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Internal Verifier&apos;s FullName <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onInternalVerifierNameChanged}
              value={internalVerifierName}
              name="internalVerifierName"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Internal Verifier&apos;s Qualification <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onInternalVerifierQualificationChanged}
              value={internalVerifierQualification}
              name="internalVerifierQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Industry/Workplace Liaison Officer&apos;s FullName</span>}
              onChange={onLiaisonOfficerNameChanged}
              value={liaisonOfficerName}
              name="liaisonOfficerName"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Industry/Workplace Liaison Officer&apos;s Qualification</span>}
              onChange={onLiaisonOfficerQualificationChanged}
              value={liaisonOfficerQualification}
              name="liaisonOfficerQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Resource/Data Officer&apos;s FullName</span>}
              onChange={onResourceOfficerNameChanged}
              value={resourceOfficerName}
              name="resourceOfficerName"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Resource/Data Officer&apos;s Qualification</span>}
              onChange={onResourceOfficerQualificationChanged}
              value={resourceOfficerQualification}
              name="resourceOfficerQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Other Staff&apos;s FullName</span>}
              onChange={onOtherStaffNameChanged}
              value={otherStaffName}
              name="otherStaffName"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Other Staff&apos;s Qualification</span>}
              onChange={onOtherStaffQualificationChanged}
              value={otherStaffQualification}
              name="otherStaffQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Other Staff&apos;s Designation</span>}
              onChange={onOtherStaffDesignationChanged}
              value={otherStaffDesignation}
              name="otherStaffDesignation"
              helperText="e.g: Program Officer, HOD Admin, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />
          </MDBox>
        );

      case 7:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              PHYSICAL FACILITIES
            </MDTypography>
            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Classrooms/Training Rooms - Quantity"
              onChange={onTrainingRoomQuantityChanged}
              value={trainingRoomQuantity}
              name="trainingRoomQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Classrooms/Training Rooms - Total Floor Area"
              onChange={onTrainingRoomFloorAreaChanged}
              value={trainingRoomFloorArea}
              name="trainingRoomFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Workshops - Quantity"
              onChange={onWorkshopQuantityChanged}
              value={workshopQuantity}
              name="workshopQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Workshops - Total Floor Area"
              onChange={onWorkshopFloorAreaChanged}
              value={workshopFloorArea}
              name="workshopFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Practical Training Space for each Trade - Quantity"
              onChange={onPracticalTrainingSpaceQuantityChanged}
              value={practicalTrainingSpaceQuantity}
              name="practicalTrainingSpaceQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Practical Training Space for each Trade - Total Floor Area"
              onChange={onPracticalTrainingSpaceFloorAreaChanged}
              value={practicalTrainingSpaceFloorArea}
              name="practicalTrainingSpaceFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Technical Laboratories - Quantity"
              onChange={onTechnicalLabQuantityChanged}
              value={technicalLabQuantity}
              name="technicalLabQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Technical Laboratories - Total Floor Area"
              onChange={onTechnicalLabFloorAreaChanged}
              value={technicalLabFloorArea}
              name="technicalLabFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Data/Records Room - Quantity"
              onChange={onRecordsRoomQuantityChanged}
              value={recordsRoomQuantity}
              name="recordsRoomQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Data/Records Room - Total Floor Area"
              onChange={onRecordsRoomFloorAreaChanged}
              value={recordsRoomFloorArea}
              name="recordsRoomFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Auditorium - Quantity"
              onChange={onAuditoriumQuantityChanged}
              value={auditoriumQuantity}
              name="auditoriumQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Auditorium - Total Floor Area"
              onChange={onAuditoriumFloorAreaChanged}
              value={auditoriumFloorArea}
              name="auditoriumFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Library & Study Rooms - Quantity"
              onChange={onLibraryQuantityChanged}
              value={libraryQuantity}
              name="libraryQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Library & Study Rooms - Total Floor Area"
              onChange={onLibraryFloorAreaChanged}
              value={libraryFloorArea}
              name="libraryFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Water & Power Supply (Public/Private) - Quantity"
              onChange={onUtilityQuantityChanged}
              value={utilityQuantity}
              name="utilityQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Water & Power Supply (Public/Private) - Total Floor Area"
              onChange={onUtilityFloorAreaChanged}
              value={utilityFloorArea}
              name="utilityFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Management Offices - Quantity"
              onChange={onMgtOfficesQuantityChanged}
              value={mgtOfficesQuantity}
              name="mgtOfficesQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Management Offices - Total Floor Area"
              onChange={onMgtOfficesFloorAreaChanged}
              value={mgtOfficesFloorArea}
              name="mgtOfficesFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Staff Rooms - Quantity"
              onChange={onStaffRoomQuantityChanged}
              value={staffRoomQuantity}
              name="staffRoomQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Staff Rooms - Total Floor Area"
              onChange={onStaffRoomFloorAreaChanged}
              value={staffRoomFloorArea}
              name="staffRoomFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Toilets - Quantity"
              onChange={onToiletQuantityChanged}
              value={toiletQuantity}
              name="toiletQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Toilets - Total Floor Area"
              onChange={onToiletFloorAreaChanged}
              value={toiletFloorArea}
              name="toiletFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="First Aid/Firefighting Facility - Quantity"
              onChange={onFirstAidFireFacilityQuantityChanged}
              value={firstAidFireFacilityQuantity}
              name="firstAidFireFacilityQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="First Aid/Firefighting Facility - Total Floor Area"
              onChange={onFirstAidFireFacilityFloorAreaChanged}
              value={firstAidFireFacilityFloorArea}
              name="firstAidFireFacilityFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Hostel - Quantity"
              onChange={onHostelQuantityChanged}
              value={hostelQuantity}
              name="hostelQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Hostel - Total Floor Area"
              onChange={onHostelFloorAreaChanged}
              value={hostelFloorArea}
              name="hostelFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Tools & Equipment for relevant Trades - Quantity"
              onChange={onTradeToolsQuantityChanged}
              value={tradeToolsQuantity}
              name="tradeToolsQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Tools & Equipment for relevant Trades - Total Floor Area"
              onChange={onTradeToolsFloorAreaChanged}
              value={tradeToolsFloorArea}
              name="tradeToolsFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Other Facility Name"
              onChange={onOtherFacilityNameChanged}
              value={otherFacilityName}
              name="otherFacilityName"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Others - Quantity"
              onChange={onOtherFacilityQuantityChanged}
              value={otherFacilityQuantity}
              name="otherFacilityQuantity"
              InputProps={{ inputProps: { min: 0 } }}
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Others - Total Floor Area"
              onChange={onOtherFacilityFloorAreaChanged}
              value={otherFacilityFloorArea}
              name="otherFacilityFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />
          </MDBox>
        );

      case 8:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              TRAINING STAFF
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              label="Full Name of Staff 1"
              onChange={onTrainingStaff1NameChanged}
              value={trainingStaff1Name}
              name="trainingStaff1Name"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Staff's Qualification"
              onChange={onTrainingStaff1QualificationChanged}
              value={trainingStaff1Qualification}
              name="trainingStaff1Qualification"
              helperText="e.g: BSc. Building, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDAutocomplete
              id="trainingStaff1Gender-selector"
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Gender"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setTrainingStaff1Gender(value?.value || ""); // Call setGender with the value (e.g., "male" or "female")
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Practical Experience"
              onChange={onTrainingStaff1ExperienceChanged}
              value={trainingStaff1Experience}
              name="trainingStaff1Experience"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDAutocomplete
              id="trainingStaff1Specialization-selector"
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === trainingStaff1Specialization
                ) || null
              }
              onChange={(event, value) =>
                setTrainingStaff1Specialization(value?.qualificationType || "")
              }
              getOptionLabel={(option) => option.qualificationType || ""}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput {...params} label="Trade Specialization" variant="standard" />
              )}
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Full Name of Staff 2"
              onChange={onTrainingStaff2NameChanged}
              value={trainingStaff2Name}
              name="trainingStaff2Name"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Staff's Qualification"
              onChange={onTrainingStaff2QualificationChanged}
              value={trainingStaff2Qualification}
              name="trainingStaff2Qualification"
              helperText="e.g: BSc. Building, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDAutocomplete
              id="trainingStaff2Gender-selector"
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Gender"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setTrainingStaff2Gender(value?.value || ""); // Call setGender with the value (e.g., "male" or "female")
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Practical Experience"
              onChange={onTrainingStaff2ExperienceChanged}
              value={trainingStaff2Experience}
              name="trainingStaff2Experience"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDAutocomplete
              id="trainingStaff2Specialization-selector"
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === trainingStaff2Specialization
                ) || null
              }
              onChange={(event, value) =>
                setTrainingStaff2Specialization(value?.qualificationType || "")
              }
              getOptionLabel={(option) => option.qualificationType || ""}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput {...params} label="Trade Specialization" variant="standard" />
              )}
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Full Name of Staff 3"
              onChange={onTrainingStaff3NameChanged}
              value={trainingStaff3Name}
              name="trainingStaff3Name"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Staff's Qualification"
              onChange={onTrainingStaff3QualificationChanged}
              value={trainingStaff3Qualification}
              name="trainingStaff3Qualification"
              helperText="e.g: BSc. Building, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDAutocomplete
              id="trainingStaff3Gender-selector"
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Gender"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setTrainingStaff3Gender(value?.value || ""); // Call setGender with the value (e.g., "male" or "female")
              }}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Practical Experience"
              onChange={onTrainingStaff3ExperienceChanged}
              value={trainingStaff3Experience}
              name="trainingStaff3Experience"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDAutocomplete
              id="trainingStaff3Specialization-selector"
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === trainingStaff3Specialization
                ) || null
              }
              onChange={(event, value) =>
                setTrainingStaff3Specialization(value?.qualificationType || "")
              }
              getOptionLabel={(option) => option.qualificationType || ""}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput {...params} label="Trade Specialization" variant="standard" />
              )}
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDBox sx={{ gridColumn: "span 6", mt: 2 }}>
              <Grid container spacing={{ xs: 2, md: 6 }} xs={12} sx={{ mb: { xs: 2, md: -7 } }}>
                <Grid item xs={12} sm={6}>
                  {centreStaffList && (
                    <MDTypography variant="body2">
                      <strong>Centre Staff List:</strong>
                      &nbsp;<span style={{ color: "#4CAF50" }}>{centreStaffList.name}</span>
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  {centreStaffCVs && (
                    <MDTypography variant="body2">
                      <strong>Centre Staff CVs:</strong>
                      &nbsp;<span style={{ color: "#4CAF50" }}>{centreStaffCVs.name}</span>
                    </MDTypography>
                  )}
                </Grid>
              </Grid>
            </MDBox>

            <MDButton
              component="label"
              role={undefined}
              variant="gradient"
              color="dark"
              tabIndex={-1}
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" }, mb: { xs: 0, md: 5 } }}
              startIcon={<CloudUploadIcon />}
            >
              Upload Centre Staff List & Engagement Letters &nbsp;
              <span style={{ color: "red" }}> *</span>
              <VisuallyHiddenInput type="file" onChange={onSelectFile(setCentreStaffList)} />
            </MDButton>

            <MDButton
              component="label"
              role={undefined}
              variant="gradient"
              color="dark"
              tabIndex={-1}
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" }, mb: 5 }}
              startIcon={<CloudUploadIcon />}
            >
              Upload Centre Staff CVs &nbsp;<span style={{ color: "red" }}> *</span>
              <VisuallyHiddenInput type="file" onChange={onSelectFile(setCentreStaffCVs)} />
            </MDButton>
          </MDBox>
        );

      case 9:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              SUPPORTING DOCUMENTS
            </MDTypography>

            <MDBox sx={{ gridColumn: "span 6", mt: 2 }}>
              <Grid container spacing={{ xs: 2, md: 6 }} xs={12} sx={{ mb: { xs: 2, md: -7 } }}>
                <Grid item xs={12} sm={6}>
                  {otherCentreDocs && (
                    <MDTypography variant="body2">
                      <strong>Supporting Centre Documents:</strong>
                      &nbsp;<span style={{ color: "#4CAF50" }}>{otherCentreDocs.name}</span>
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  {centrePhotos && (
                    <MDTypography variant="body2">
                      <strong>Centre Photos:</strong>
                      &nbsp;<span style={{ color: "#4CAF50" }}>{centrePhotos.name}</span>
                    </MDTypography>
                  )}
                </Grid>
              </Grid>
            </MDBox>

            <MDButton
              component="label"
              role={undefined}
              variant="gradient"
              color="dark"
              tabIndex={-1}
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" }, mb: { xs: 0, md: 5 } }}
              startIcon={<CloudUploadIcon />}
            >
              Upload Other Supporting Centre Documents
              <VisuallyHiddenInput type="file" onChange={onSelectFile(setOtherCentreDocs)} />
            </MDButton>

            <MDButton
              component="label"
              role={undefined}
              variant="gradient"
              color="dark"
              tabIndex={-1}
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" }, mb: 5 }}
              startIcon={<CloudUploadIcon />}
            >
              Upload Centre Photos
              <VisuallyHiddenInput type="file" onChange={onSelectFile(setCentrePhotos)} />
            </MDButton>

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Full Name of Applicant <span style={{ color: "red" }}>*</span>
                </span>
              }
              onChange={onApplicantsNameChanged}
              value={applicantsName}
              name="applicantsName"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Position/Designation of Applicant <span style={{ color: "red" }}>*</span>
                </span>
              }
              onChange={onApplicantsPositionChanged}
              value={applicantsPosition}
              name="applicantsPosition"
              sx={{ gridColumn: "span 3" }}
            />
          </MDBox>
        );
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <MDBox sx={{ mb: 5 }}>
        <Card>
          <form>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{
                p: 3,
                ml: 5,
                mr: 5,
                mt: -3,
                background: "#4CAF50 !important",
                boxShadow: "#4CAF50 !important",
                display: "flex",
                justifyContent: "space-between", // Ensures even spacing
                overflowX: "auto",
                whiteSpace: "nowrap",
                scrollSnapType: "x mandatory",
                WebkitOverflowScrolling: "touch",
                scrollbarWidth: "none", // Firefox
                "&::-webkit-scrollbar": {
                  display: "none", // Chrome, Safari, Edge
                },
                "& .MuiStep-root": {
                  flex: 1, // Ensures equal width for each step
                },
                "& .MuiStepLabel-label": {
                  color: "#ffffff !important",
                  minWidth: { md: "180px", xs: "100px" },
                },
                "& .MuiStepIcon-root": {
                  color: "#ffffff !important",
                  stroke: "#ffffff !important",
                  fill: "#ffffff !important",
                },
                "& .MuiStepConnector-root": {
                  flexGrow: 1, // Ensures connectors stretch evenly
                  color: "#ffffff !important",
                  minWidth: { md: "180px", xs: "100px" },
                },
              }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <MDBox sx={{ mt: 3 }}>{renderStepContent(activeStep)}</MDBox>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
                mb: 2,
                mr: 3,
                ml: 3,
              }}
            >
              <MDButton disabled={activeStep === 0} onClick={handleBack}>
                Back
              </MDButton>
              {/* Next/Finish Button */}
              {activeStep === steps.length - 1 ? (
                <MDButton onClick={onSaveCentreApplicationClicked}>Submit</MDButton>
              ) : (
                <MDButton onClick={handleNext}>Next</MDButton>
              )}
            </MDBox>
          </form>
        </Card>
      </MDBox>
      <Footer />
    </CoverLayout>
  );
}

// View Centre Application
export function ViewCentreApplication() {
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [cApp, setCApp] = useState({});
  const [eqas, setEQAs] = useState({});
  const [filteredEQAs, setFilteredEQAs] = useState([]);

  // Fetch centreapplication data
  useEffect(() => {
    const fetchCentreApplicationData = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/api/centreapplications/${id}`);
        setCApp({
          ...data,
          qualificationsList: data.qualificationsList || [], // Ensure it’s always an array
        });
      } catch (error) {
        console.error("Error fetching centre application data:", error);
      }
    };
    fetchCentreApplicationData();
  }, [id]);

  // Fetch eqa data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/api/users/getUsers`);

        // Filter users where roleType is "EQAM"
        const eqamUsers = data.filter((user) =>
          user.roles.some((role) => role.roleType === "EQAM")
        );

        setEQAs(eqamUsers);
      } catch (error) {
        console.error("Error fetching eqa data:", error);
      }
    };
    fetchUserData();
  }, []);

  // Filter EQAs when either cApp or eqas data updates
  useEffect(() => {
    if (!cApp.zone || !cApp.qualificationsList.length || !eqas.length) return;

    const filtered = eqas.filter(
      (eqa) =>
        eqa.zone === cApp.zone &&
        eqa.qualifications.some((q) =>
          cApp.qualificationsList.some((qual) => qual.qualificationType === q.qualificationType)
        )
    );

    setFilteredEQAs(filtered);
  }, [cApp, eqas]);

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDBox mt={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} display={{ xs: "none", md: "block" }}>
              <CASidebar />
            </Grid>
            <Grid item xs={12} md={9}>
              <CAInformation cApp={cApp} />

              {/* Check if cApp.eqaAssignedTo is null or empty */}
              {cApp.eqaAssignedTo ? (
                (() => {
                  const assignedEqa = cApp.eqaInCharge;
                  return assignedEqa ? (
                    <>
                      <Paper elevation={1} sx={{ p: 3, borderRadius: 2, mt: 3 }} id="assign-eqa">
                        <MDTypography variant="h6" sx={{ mb: 2 }}>
                          EQA Assigned
                        </MDTypography>
                        <MDTypography variant="h4" fontWeight="light" color="success">
                          {assignedEqa.firstName.toUpperCase()}
                          &nbsp;{assignedEqa.middleName.toUpperCase()}
                          &nbsp;{assignedEqa.lastName.toUpperCase()}
                        </MDTypography>
                      </Paper>
                      {!cApp.centreApplicationApproved && <AssignEQACard eqas={filteredEQAs} />}
                    </>
                  ) : (
                    <Paper elevation={1} sx={{ p: 3, borderRadius: 2, mt: 3 }} id="assign-eqa">
                      <MDTypography variant="h6">EQA Assigned</MDTypography>
                      <MDTypography variant="subtitle2" fontWeight="regular" color="danger">
                        NOT FOUND
                      </MDTypography>
                    </Paper>
                  );
                })()
              ) : (
                <AssignEQACard eqas={filteredEQAs} />
              )}

              <DownloadCADocsCard />

              <DownloadEQADocsCard />

              <ApproveCACard />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// Display All CentreApplications assigned to a particular EQA
export function MyCentreApplications() {
  const { columns, rows } = myCentreapplicationsTableData();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  Assigned Centres
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  canSearch={true}
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={{ variant: "gradient", color: "success" }}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// Centre Quality Check Form with Stepper
export function CentreQualityCheck() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [qualificationsData, setQualificationsData] = useState([]);
  const maxYear = new Date().getFullYear().toString();
  const today = new Date().toString();

  const apiUrl = process.env.REACT_APP_API_URL;

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole !== "eqam") {
    return <Navigate to="/error404" />;
  }

  const [activeStep, setActiveStep] = useState(0);

  //CENTRE DETAILS INITIALIZE
  const [centreName, setCentreName] = useState("");
  const [validCentreName, setValidCentreName] = useState(false);
  const [yearOfInc, setYearOfInc] = useState();
  const [cacNumber, setCACNumber] = useState("");
  const [validCACNumber, setValidCACNumber] = useState(false);
  const [permanentCentreAddress, setPermanentCentreAddress] = useState("");
  const [trainingCentreAddress, setTrainingCentreAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [website, setWebsite] = useState("");
  const [validWebsite, setValidWebsite] = useState(false);
  const [centreHead, setCentreHead] = useState("");
  const [validCentreHead, setValidCentreHead] = useState(false);
  const [centreHeadPhone, setCentreHeadPhone] = useState("");
  const [validCentreHeadPhone, setValidCentreHeadPhone] = useState(false);
  const [centreHeadEmail, setCentreHeadEmail] = useState("");
  const [validCentreHeadEmail, setValidCentreHeadEmail] = useState(false);
  const [qualifications, setQualifications] = useState([]);
  const [zone, setZone] = useState("");
  const [stateOfRez, setStateOfRez] = useState("");

  //CENTRE ORGANISATION INITIALIZE
  const [centreType, setCentreType] = useState("");
  const [satelliteCentreName1, setSatelliteCentreName1] = useState("");
  const [validSatelliteCentreName1, setValidSatelliteCentreName1] = useState(false);
  const [satelliteCentreAddress1, setSatelliteCentreAddress1] = useState("");
  const [satelliteCentreName2, setSatelliteCentreName2] = useState("");
  const [validSatelliteCentreName2, setValidSatelliteCentreName2] = useState(false);
  const [satelliteCentreAddress2, setSatelliteCentreAddress2] = useState("");
  const [otherApprovals, setOtherApprovals] = React.useState({
    itp: false,
    naddc: false,
    nabteb: false,
    cityGuild: false,
  });
  const [awardingBody1, setAwardingBody1] = useState("");
  const [awardingBody1Qualification, setAwardingBody1Qualification] = useState("");
  const [awardingBody1Level, setAwardingBody1Level] = useState(1);
  const [awardingBody1DateApproved, setAwardingBody1DateApproved] = useState();
  const [awardingBody1ApprovalStatus, setAwardingBody1ApprovalStatus] = useState("");
  const [awardingBody2, setAwardingBody2] = useState("");
  const [awardingBody2Qualification, setAwardingBody2Qualification] = useState("");
  const [awardingBody2Level, setAwardingBody2Level] = useState(1);
  const [awardingBody2DateApproved, setAwardingBody2DateApproved] = useState();
  const [awardingBody2ApprovalStatus, setAwardingBody2ApprovalStatus] = useState("");

  //EQA FEEDBACK 1 INITIALIZE
  const [nsqCertifiedAssessors, setNsqCertifiedAssessors] = useState("");
  const [assessorsCount, setAssessorsCount] = useState(0);
  const [nsqCertifiedVerifiers, setNsqCertifiedVerifiers] = useState("");
  const [verifiersCount, setVerifiersCount] = useState(0);
  const [nsqCertifiedCentreManager, setNsqCertifiedCentreManager] = useState("");
  const [liaisonOfficerPresent, setLiaisonOfficerPresent] = useState("");
  const [resourceOfficerPresent, setResourceOfficerPresent] = useState("");
  const [siteBankPresent, setSiteBankPresent] = useState("");
  const [nsqCertifiedAssessorsEQAView, setNsqCertifiedAssessorsEQAView] = useState("");
  const [assessorsCountEQAView, setAssessorsCountEQAView] = useState(0);
  const [nsqCertifiedVerifiersEQAView, setNsqCertifiedVerifiersEQAView] = useState("");
  const [verifiersCountEQAView, setVerifiersCountEQAView] = useState(0);
  const [nsqCertifiedCentreManagerEQAView, setNsqCertifiedCentreManagerEQAView] = useState("");
  const [liaisonOfficerPresentEQAView, setLiaisonOfficerPresentEQAView] = useState("");
  const [resourceOfficerPresentEQAView, setResourceOfficerPresentEQAView] = useState("");
  const [siteBankPresentEQAView, setSiteBankPresentEQAView] = useState("");
  const [eqaFeedbackOnCentreHumanResource, setEqaFeedbackOnCentreHumanResource] = useState("");

  //EQA FEEDBACK 2 INITIALIZE
  const [trainingRoomAdequate, setTrainingRoomAdequate] = useState("");
  const [dataRoomAdequate, setDataRoomAdequate] = useState("");
  const [workshopAdequate, setWorkshopAdequate] = useState("");
  const [practicalSpaceAdequate, setPracticalSpaceAdequate] = useState("");
  const [techLabAdequate, setTechLabAdequate] = useState("");
  const [staffRoomAdequate, setStaffRoomAdequate] = useState("");
  const [toiletAdequate, setToiletAdequate] = useState("");
  const [firstAidFireAdequate, setFirstAidFireAdequate] = useState("");
  const [hostelsAdequate, setHostelsAdequate] = useState("");
  const [tradeToolsAdequate, setTradeToolsAdequate] = useState("");
  const [auditoriumAvailable, setAuditoriumAvailable] = useState("");
  const [libraryAdequate, setLibraryAdequate] = useState("");
  const [waterAndPowerAdequate, setWaterAndPowerAdequate] = useState("");
  const [mgtOfficesAdequate, setMgtOfficesAdequate] = useState("");
  const [trainingRoomAdequateEQAView, setTrainingRoomAdequateEQAView] = useState("");
  const [dataRoomAdequateEQAView, setDataRoomAdequateEQAView] = useState("");
  const [workshopAdequateEQAView, setWorkshopAdequateEQAView] = useState("");
  const [practicalSpaceAdequateEQAView, setPracticalSpaceAdequateEQAView] = useState("");
  const [techLabAdequateEQAView, setTechLabAdequateEQAView] = useState("");
  const [staffRoomAdequateEQAView, setStaffRoomAdequateEQAView] = useState("");
  const [toiletAdequateEQAView, setToiletAdequateEQAView] = useState("");
  const [firstAidFireAdequateEQAView, setFirstAidFireAdequateEQAView] = useState("");
  const [hostelsAdequateEQAView, setHostelsAdequateEQAView] = useState("");
  const [tradeToolsAdequateEQAView, setTradeToolsAdequateEQAView] = useState("");
  const [auditoriumAvailableEQAView, setAuditoriumAvailableEQAView] = useState("");
  const [libraryAdequateEQAView, setLibraryAdequateEQAView] = useState("");
  const [waterAndPowerAdequateEQAView, setWaterAndPowerAdequateEQAView] = useState("");
  const [mgtOfficesAdequateEQAView, setMgtOfficesAdequateEQAView] = useState("");
  const [eqaFeedbackOnCentreFacilities, setEqaFeedbackOnCentreFacilities] = useState("");

  //EQA FEEDBACK 3 INITIALIZE
  const [nosAvailable, setNosAvailable] = useState("");
  const [trainingManualAvailable, setTrainingManualAvailable] = useState("");
  const [nosAndManualAlignment, setNosAndManualAlignment] = useState("");
  const [otherTrainingMaterialsAvailable, setOtherTrainingMaterialsAvailable] = useState("");
  const [nsqGuidelineAvailable, setNsqGuidelineAvailable] = useState("");
  const [staffCompliantWithStandards, setStaffCompliantWithStandards] = useState("");
  const [objectivesClear, setObjectivesClear] = useState("");
  const [classParticipation, setClassParticipation] = useState("");
  const [nosAvailableEQAView, setNosAvailableEQAView] = useState("");
  const [trainingManualAvailableEQAView, setTrainingManualAvailableEQAView] = useState("");
  const [nosAndManualAlignedEQAView, setNosAndManualAlignedEQAView] = useState("");
  const [otherTrainingMaterialsAvailableEQAView, setOtherTrainingMaterialsAvailableEQAView] =
    useState("");
  const [nsqGuidelineAvailableEQAView, setNsqGuidelineAvailableEQAView] = useState("");
  const [staffCompliantWithStandardsEQAView, setStaffCompliantWithStandardsEQAView] = useState("");
  const [objectivesClearEQAView, setObjectivesClearEQAView] = useState("");
  const [classParticipationEQAView, setClassParticipationEQAView] = useState("");
  const [eqaFeedbackOnCentreNOSNSQ, setEqaFeedbackOnCentreNOSNSQ] = useState("");

  //EQA FINAL REPORT
  const [conduciveLearningEnvironment, setConduciveLearningEnvironment] = useState("");
  const [policyAndProcedure, setPolicyAndProcedure] = useState("");
  const [practicalWorkPlacementArrangement, setPracticalWorkPlacementArrangement] = useState("");
  const [sufficientQualityAssuranceTeam, setSufficientQualityAssuranceTeam] = useState("");
  const [learnersSupportSystem, setLearnersSupportSystem] = useState("");
  const [eqaFinalFeedbackOnCentre, setEqaFinalFeedbackOnCentre] = useState("");

  //EQA SUPPORTING DOCUMENT
  const [eqaReports, setEqaReports] = useState(null);
  const [eqaReportsUrl, setEqaReportsUrl] = useState("");
  const [previewEqaReports, setPreviewEqaReports] = useState();

  //ORGANISATION STAFFING INITIALIZE
  const [centreManagerName, setCentreManagerName] = useState("");
  const [centreManagerQualification, setCentreManagerQualification] = useState("");
  const [centreManagerEQARemark, setCentreManagerEQARemark] = useState("");
  const [assessor1Name, setAssessor1Name] = useState("");
  const [assessor1Qualification, setAssessor1Qualification] = useState("");
  const [assessor1EQARemark, setAssessor1EQARemark] = useState("");
  const [assessor2Name, setAssessor2Name] = useState("");
  const [assessor2Qualification, setAssessor2Qualification] = useState("");
  const [assessor2EQARemark, setAssessor2EQARemark] = useState("");
  const [internalVerifierName, setInternalVerifierName] = useState("");
  const [internalVerifierQualification, setInternalVerifierQualification] = useState("");
  const [internalVerifierEQARemark, setInternalVerifierEQARemark] = useState("");
  const [liaisonOfficerName, setLiaisonOfficerName] = useState("");
  const [liaisonOfficerQualification, setLiaisonOfficerQualification] = useState("");
  const [liaisonOfficerEQARemark, setLiaisonOfficerEQARemark] = useState("");
  const [resourceOfficerName, setResourceOfficerName] = useState("");
  const [resourceOfficerQualification, setResourceOfficerQualification] = useState("");
  const [resourceOfficerEQARemark, setResourceOfficerEQARemark] = useState("");
  const [otherStaffName, setOtherStaffName] = useState("");
  const [otherStaffQualification, setOtherStaffQualification] = useState("");
  const [otherStaffDesignation, setOtherStaffDesignation] = useState("");

  //PHYSICAL FACILITIES INITIALIZE
  const [trainingRoomQuantity, setTrainingRoomQuantity] = useState(0);
  const [trainingRoomFloorArea, setTrainingRoomFloorArea] = useState(0);
  const [workshopQuantity, setWorkshopQuantity] = useState(0);
  const [workshopFloorArea, setWorkshopFloorArea] = useState(0);
  const [practicalTrainingSpaceQuantity, setPracticalTrainingSpaceQuantity] = useState(0);
  const [practicalTrainingSpaceFloorArea, setPracticalTrainingSpaceFloorArea] = useState(0);
  const [technicalLabQuantity, setTechnicalLabQuantity] = useState(0);
  const [technicalLabFloorArea, setTechnicalLabFloorArea] = useState(0);
  const [recordsRoomQuantity, setRecordsRoomQuantity] = useState(0);
  const [recordsRoomFloorArea, setRecordsRoomFloorArea] = useState(0);
  const [auditoriumQuantity, setAuditoriumQuantity] = useState(0);
  const [auditoriumFloorArea, setAuditoriumFloorArea] = useState(0);
  const [libraryQuantity, setLibraryQuantity] = useState(0);
  const [libraryFloorArea, setLibraryFloorArea] = useState(0);
  const [utilityQuantity, setUtilityQuantity] = useState(0);
  const [utilityFloorArea, setUtilityFloorArea] = useState(0);
  const [mgtOfficesQuantity, setMgtOfficesQuantity] = useState(0);
  const [mgtOfficesFloorArea, setMgtOfficesFloorArea] = useState(0);
  const [staffRoomQuantity, setStaffRoomQuantity] = useState(0);
  const [staffRoomFloorArea, setStaffRoomFloorArea] = useState(0);
  const [toiletQuantity, setToiletQuantity] = useState(0);
  const [toiletFloorArea, setToiletFloorArea] = useState(0);
  const [firstAidFireFacilityQuantity, setFirstAidFireFacilityQuantity] = useState(0);
  const [firstAidFireFacilityFloorArea, setFirstAidFireFacilityFloorArea] = useState(0);
  const [hostelQuantity, setHostelQuantity] = useState(0);
  const [hostelFloorArea, setHostelFloorArea] = useState(0);
  const [tradeToolsQuantity, setTradeToolsQuantity] = useState(0);
  const [tradeToolsFloorArea, setTradeToolsFloorArea] = useState(0);
  const [otherFacilityName, setOtherFacilityName] = useState("");
  const [otherFacilityQuantity, setOtherFacilityQuantity] = useState(0);
  const [otherFacilityFloorArea, setOtherFacilityFloorArea] = useState(0);

  //TRAINING STAFF INITIALIZE
  const [trainingStaff1Name, setTrainingStaff1Name] = useState("");
  const [trainingStaff1Gender, setTrainingStaff1Gender] = useState("");
  const [trainingStaff1Qualification, setTrainingStaff1Qualification] = useState("");
  const [trainingStaff1Experience, setTrainingStaff1Experience] = useState(0);
  const [trainingStaff1Specialization, setTrainingStaff1Specialization] = useState("");
  const [trainingStaff2Name, setTrainingStaff2Name] = useState("");
  const [trainingStaff2Gender, setTrainingStaff2Gender] = useState("");
  const [trainingStaff2Qualification, setTrainingStaff2Qualification] = useState("");
  const [trainingStaff2Experience, setTrainingStaff2Experience] = useState(0);
  const [trainingStaff2Specialization, setTrainingStaff2Specialization] = useState("");
  const [trainingStaff3Name, setTrainingStaff3Name] = useState("");
  const [trainingStaff3Gender, setTrainingStaff3Gender] = useState("");
  const [trainingStaff3Qualification, setTrainingStaff3Qualification] = useState("");
  const [trainingStaff3Experience, setTrainingStaff3Experience] = useState(0);
  const [trainingStaff3Specialization, setTrainingStaff3Specialization] = useState("");

  //SUPPORTING DOCS & DECLARATION INITIALIZE
  const [applicantsName, setApplicantsName] = useState("");
  const [validApplicantsName, setValidApplicantsName] = useState(false);
  const [applicantsPosition, setApplicantsPosition] = useState("");
  const [validApplicantsPosition, setValidApplicantsPosition] = useState(false);

  //INITIALIZE UPLOAD VARIABLES
  const [centreCredentials, setCentreCredentials] = useState(null);
  //const [centreCredentialsURL, setCentreCredentialsURL] = useState("");
  const [previewCredentials, setPreviewCredentials] = useState();

  // Fetch centreapplication data
  useEffect(() => {
    const fetchCentreApplicationData = async () => {
      const { data } = await axios.get(`${apiUrl}/api/centreapplications/${id}`);

      setCentreName(data.centreName);
      setCACNumber(data.cacNumber);
      setYearOfInc(data.yearOfInc);
      setPermanentCentreAddress(data.permanentCentreAddress);
      setTrainingCentreAddress(data.trainingCentreAddress);
      setPhone(data.phone);
      setEmail(data.email);
      setWebsite(data.website);
      setCentreHead(data.centreHead);
      setCentreHeadEmail(data.centreHeadEmail);
      setCentreHeadPhone(data.centreHeadPhone);
      setQualifications(data.qualificationsList.map((q) => q.qualificationType));
      setZone(data.zone);
      setStateOfRez(data.stateOfRez);
      setCentreType(data.centreType);
      setSatelliteCentreName1(data.satelliteCentreName1);
      setSatelliteCentreAddress1(data.satelliteCentreAddress1);
      setSatelliteCentreName2(data.satelliteCentreName2);
      setSatelliteCentreAddress2(data.satelliteCentreAddress2);
      setOtherApprovals(data?.otherApprovals[0]);
      setAwardingBody1(data.awardingBody1);
      setAwardingBody1Qualification(data.awardingBody1Qualification);
      setAwardingBody1Level(data.awardingBody1Level);
      setAwardingBody1DateApproved(data.awardingBody1DateApproved);
      setAwardingBody1ApprovalStatus(data.awardingBody1ApprovalStatus);
      setAwardingBody2(data.awardingBody2);
      setAwardingBody2Qualification(data.awardingBody2Qualification);
      setAwardingBody2Level(data.awardingBody2Level);
      setAwardingBody2DateApproved(data.awardingBody2DateApproved);
      setAwardingBody2ApprovalStatus(data.awardingBody2ApprovalStatus);
      setNsqCertifiedAssessors(data.nsqCertifiedAssessors);
      setAssessorsCount(data.assessorsCount);
      setNsqCertifiedVerifiers(data.nsqCertifiedVerifiers);
      setVerifiersCount(data.verifiersCount);
      setNsqCertifiedCentreManager(data.nsqCertifiedCentreManager);
      setLiaisonOfficerPresent(data.liaisonOfficerPresent);
      setResourceOfficerPresent(data.resourceOfficerPresent);
      setSiteBankPresent(data.siteBankPresent);
      setTrainingRoomAdequate(data.trainingRoomAdequate);
      setDataRoomAdequate(data.dataRoomAdequate);
      setWorkshopAdequate(data.workshopAdequate);
      setPracticalSpaceAdequate(data.practicalSpaceAdequate);
      setTechLabAdequate(data.techLabAdequate);
      setStaffRoomAdequate(data.staffRoomAdequate);
      setToiletAdequate(data.toiletAdequate);
      setFirstAidFireAdequate(data.firstAidFireAdequate);
      setHostelsAdequate(data.hostelsAdequate);
      setTradeToolsAdequate(data.tradeToolsAdequate);
      setAuditoriumAvailable(data.auditoriumAvailable);
      setLibraryAdequate(data.libraryAdequate);
      setWaterAndPowerAdequate(data.waterAndPowerAdequate);
      setMgtOfficesAdequate(data.mgtOfficesAdequate);
      setNosAvailable(data.nosAvailable);
      setTrainingManualAvailable(data.trainingManualAvailable);
      setNosAndManualAlignment(data.nosAndManualAlignment);
      setOtherTrainingMaterialsAvailable(data.otherTrainingMaterialsAvailable);
      setNsqGuidelineAvailable(data.nsqGuidelineAvailable);
      setStaffCompliantWithStandards(data.staffCompliantWithStandards);
      setObjectivesClear(data.objectivesClear);
      setClassParticipation(data.classParticipation);
      setCentreManagerName(data.centreManagerName);
      setCentreManagerQualification(data.centreManagerQualification);
      setAssessor1Name(data.assessor1Name);
      setAssessor1Qualification(data.assessor1Qualification);
      setAssessor2Name(data.assessor2Name);
      setAssessor2Qualification(data.assessor2Qualification);
      setInternalVerifierName(data.internalVerifierName);
      setInternalVerifierQualification(data.internalVerifierQualification);
      setLiaisonOfficerName(data.liaisonOfficerName);
      setLiaisonOfficerQualification(data.liaisonOfficerQualification);
      setResourceOfficerName(data.resourceOfficerName);
      setResourceOfficerQualification(data.resourceOfficerQualification);
      setOtherStaffName(data.otherStaffName);
      setOtherStaffQualification(data.otherStaffQualification);
      setOtherStaffDesignation(data.otherStaffDesignation);
      setTrainingRoomQuantity(data.trainingRoomQuantity);
      setTrainingRoomFloorArea(data.trainingRoomFloorArea);
      setWorkshopQuantity(data.workshopQuantity);
      setWorkshopFloorArea(data.workshopFloorArea);
      setPracticalTrainingSpaceQuantity(data.practicalTrainingSpaceQuantity);
      setPracticalTrainingSpaceFloorArea(data.practicalTrainingSpaceFloorArea);
      setTechnicalLabQuantity(data.technicalLabQuantity);
      setTechnicalLabFloorArea(data.technicalLabFloorArea);
      setRecordsRoomQuantity(data.recordsRoomQuantity);
      setRecordsRoomFloorArea(data.recordsRoomFloorArea);
      setAuditoriumQuantity(data.auditoriumQuantity);
      setAuditoriumFloorArea(data.auditoriumFloorArea);
      setLibraryQuantity(data.libraryQuantity);
      setLibraryFloorArea(data.libraryFloorArea);
      setUtilityQuantity(data.utilityQuantity);
      setUtilityFloorArea(data.utilityFloorArea);
      setMgtOfficesQuantity(data.mgtOfficesQuantity);
      setMgtOfficesFloorArea(data.mgtOfficesFloorArea);
      setStaffRoomQuantity(data.staffRoomQuantity);
      setStaffRoomFloorArea(data.staffRoomFloorArea);
      setToiletQuantity(data.toiletQuantity);
      setToiletFloorArea(data.toiletFloorArea);
      setFirstAidFireFacilityQuantity(data.firstAidFireFacilityQuantity);
      setFirstAidFireFacilityFloorArea(data.firstAidFireFacilityFloorArea);
      setHostelQuantity(data.hostelQuantity);
      setHostelFloorArea(data.hostelFloorArea);
      setTradeToolsQuantity(data.tradeToolsQuantity);
      setTradeToolsFloorArea(data.tradeToolsFloorArea);
      setOtherFacilityName(data.otherFacilityName);
      setOtherFacilityQuantity(data.otherFacilityQuantity);
      setOtherFacilityFloorArea(data.otherFacilityFloorArea);
      setTrainingStaff1Name(data.trainingStaff1Name);
      setTrainingStaff1Gender(data.trainingStaff1Gender);
      setTrainingStaff1Qualification(data.trainingStaff1Qualification);
      setTrainingStaff1Experience(data.trainingStaff1Experience);
      setTrainingStaff1Specialization(data.trainingStaff1Specialization);
      setTrainingStaff2Name(data.trainingStaff2Name);
      setTrainingStaff2Gender(data.trainingStaff2Gender);
      setTrainingStaff2Qualification(data.trainingStaff2Qualification);
      setTrainingStaff2Experience(data.trainingStaff2Experience);
      setTrainingStaff2Specialization(data.trainingStaff2Specialization);
      setTrainingStaff3Name(data.trainingStaff3Name);
      setTrainingStaff3Gender(data.trainingStaff3Gender);
      setTrainingStaff3Qualification(data.trainingStaff3Qualification);
      setTrainingStaff3Experience(data.trainingStaff3Experience);
      setTrainingStaff3Specialization(data.trainingStaff3Specialization);
      setApplicantsName(data.applicantsName);
      setApplicantsPosition(data.applicantsPosition);
    };
    fetchCentreApplicationData();
  }, [id]);

  // Fetch qualifications data
  useEffect(() => {
    const fetchData = async () => {
      const qualificationsResponse = await axios.get(
        `${apiUrl}/api/qualifications/getQualifications`
      );

      setQualificationsData(qualificationsResponse.data);
    };

    fetchData();
  }, []);

  // Input validation
  useEffect(() => {
    setValidCentreName(CENTRE_REGEX.test(centreName));
    setValidEmail(EMAIL_REGEX.test(email));
  }, [centreName, email]);

  //CENTRE DETAILS HANDLE CHANGE
  const onCentreNameChanged = (e) => setCentreName(e.target.value);
  const onCACChanged = (e) => setCACNumber(e.target.value);
  const onPermanentCentreAddressChanged = (e) => setPermanentCentreAddress(e.target.value);
  const onTrainingCentreAddressChanged = (e) => setTrainingCentreAddress(e.target.value);
  const onPhoneChanged = (e) => setPhone(e.target.value);
  const onEmailChanged = (e) => setEmail(e.target.value);
  const onWebsiteChanged = (e) => setWebsite(e.target.value);
  const onCentreHeadChanged = (e) => setCentreHead(e.target.value);
  const onCentreHeadPhoneChanged = (e) => setCentreHeadPhone(e.target.value);
  const onCentreHeadEmailChanged = (e) => setCentreHeadEmail(e.target.value);

  //CENTRE ORGANIZATION HANDLE CHANGE
  const onCentreTypeChanged = (e) => setCentreType(e.target.value);
  const onSatelliteCentreName1Changed = (e) => setSatelliteCentreName1(e.target.value);
  const onSatelliteCentreAddress1Changed = (e) => setSatelliteCentreAddress1(e.target.value);
  const onSatelliteCentreName2Changed = (e) => setSatelliteCentreName2(e.target.value);
  const onSatelliteCentreAddress2Changed = (e) => setSatelliteCentreAddress2(e.target.value);
  const onOtherApprovalsChange = (event) => {
    setOtherApprovals({ ...otherApprovals, [event.target.name]: event.target.checked });
  };
  const { itp, naddc, nabteb, cityGuild } = otherApprovals;
  const onAwardingBody1Changed = (e) => setAwardingBody1(e.target.value);
  const onAwardingBody1LevelChanged = (e) => setAwardingBody1Level(e.target.value);
  const onAwardingBody2Changed = (e) => setAwardingBody2(e.target.value);
  const onAwardingBody2LevelChanged = (e) => setAwardingBody2Level(e.target.value);

  //HUMAN & FINANCIAL RESOURCES HANDLE CHANGE
  const onAssessorsCountChanged = (e) => setAssessorsCount(e.target.value);
  const onVerifiersCountChanged = (e) => setVerifiersCount(e.target.value);
  const onAssessorsCountEQAViewChanged = (e) => setAssessorsCountEQAView(e.target.value);
  const onVerifiersCountEQAViewChanged = (e) => setVerifiersCountEQAView(e.target.value);

  //ORGANIZATIONAL STAFFING HANDLE CHANGE
  const onCentreManagerNameChanged = (e) => setCentreManagerName(e.target.value);
  const onCentreManagerQualificationChanged = (e) => setCentreManagerQualification(e.target.value);
  const onCentreManagerEQARemarkChanged = (e) => setCentreManagerEQARemark(e.target.value);
  const onAssessor1NameChanged = (e) => setAssessor1Name(e.target.value);
  const onAssessor1QualificationChanged = (e) => setAssessor1Qualification(e.target.value);
  const onAssessor1EQARemarkChanged = (e) => setAssessor1EQARemark(e.target.value);
  const onAssessor2NameChanged = (e) => setAssessor2Name(e.target.value);
  const onAssessor2QualificationChanged = (e) => setAssessor2Qualification(e.target.value);
  const onAssessor2EQARemarkChanged = (e) => setAssessor2EQARemark(e.target.value);
  const onInternalVerifierNameChanged = (e) => setInternalVerifierName(e.target.value);
  const onInternalVerifierQualificationChanged = (e) =>
    setInternalVerifierQualification(e.target.value);
  const onInternalVerifierEQARemarkChanged = (e) => setInternalVerifierEQARemark(e.target.value);
  const onLiaisonOfficerNameChanged = (e) => setLiaisonOfficerName(e.target.value);
  const onLiaisonOfficerQualificationChanged = (e) =>
    setLiaisonOfficerQualification(e.target.value);
  const onLiaisonOfficerEQARemarkChanged = (e) => setLiaisonOfficerEQARemark(e.target.value);
  const onResourceOfficerNameChanged = (e) => setResourceOfficerName(e.target.value);
  const onResourceOfficerQualificationChanged = (e) =>
    setResourceOfficerQualification(e.target.value);
  const onResourceOfficerEQARemarkChanged = (e) => setResourceOfficerEQARemark(e.target.value);
  const onOtherStaffNameChanged = (e) => setOtherStaffName(e.target.value);
  const onOtherStaffQualificationChanged = (e) => setOtherStaffQualification(e.target.value);
  const onOtherStaffDesignationChanged = (e) => setOtherStaffDesignation(e.target.value);

  //PHYSICAL FACILITIES HANDLE CHANGE
  const onTrainingRoomQuantityChanged = (e) => setTrainingRoomQuantity(e.target.value);
  const onTrainingRoomFloorAreaChanged = (e) => setTrainingRoomFloorArea(e.target.value);
  const onWorkshopQuantityChanged = (e) => setWorkshopQuantity(e.target.value);
  const onWorkshopFloorAreaChanged = (e) => setWorkshopFloorArea(e.target.value);
  const onPracticalTrainingSpaceQuantityChanged = (e) =>
    setPracticalTrainingSpaceQuantity(e.target.value);
  const onPracticalTrainingSpaceFloorAreaChanged = (e) =>
    setPracticalTrainingSpaceFloorArea(e.target.value);
  const onTechnicalLabQuantityChanged = (e) => setTechnicalLabQuantity(e.target.value);
  const onTechnicalLabFloorAreaChanged = (e) => setTechnicalLabFloorArea(e.target.value);
  const onRecordsRoomQuantityChanged = (e) => setRecordsRoomQuantity(e.target.value);
  const onRecordsRoomFloorAreaChanged = (e) => setRecordsRoomFloorArea(e.target.value);
  const onAuditoriumQuantityChanged = (e) => setAuditoriumQuantity(e.target.value);
  const onAuditoriumFloorAreaChanged = (e) => setAuditoriumFloorArea(e.target.value);
  const onLibraryQuantityChanged = (e) => setLibraryQuantity(e.target.value);
  const onLibraryFloorAreaChanged = (e) => setLibraryFloorArea(e.target.value);
  const onUtilityQuantityChanged = (e) => setUtilityQuantity(e.target.value);
  const onUtilityFloorAreaChanged = (e) => setUtilityFloorArea(e.target.value);
  const onMgtOfficesQuantityChanged = (e) => setMgtOfficesQuantity(e.target.value);
  const onMgtOfficesFloorAreaChanged = (e) => setMgtOfficesFloorArea(e.target.value);
  const onStaffRoomQuantityChanged = (e) => setStaffRoomQuantity(e.target.value);
  const onStaffRoomFloorAreaChanged = (e) => setStaffRoomFloorArea(e.target.value);
  const onToiletQuantityChanged = (e) => setToiletQuantity(e.target.value);
  const onToiletFloorAreaChanged = (e) => setToiletFloorArea(e.target.value);
  const onFirstAidFireFacilityQuantityChanged = (e) =>
    setFirstAidFireFacilityQuantity(e.target.value);
  const onFirstAidFireFacilityFloorAreaChanged = (e) =>
    setFirstAidFireFacilityFloorArea(e.target.value);
  const onHostelQuantityChanged = (e) => setHostelQuantity(e.target.value);
  const onHostelFloorAreaChanged = (e) => setHostelFloorArea(e.target.value);
  const onTradeToolsQuantityChanged = (e) => setTradeToolsQuantity(e.target.value);
  const onTradeToolsFloorAreaChanged = (e) => setTradeToolsFloorArea(e.target.value);
  const onOtherFacilityNameChanged = (e) => setOtherFacilityName(e.target.value);
  const onOtherFacilityQuantityChanged = (e) => setOtherFacilityQuantity(e.target.value);
  const onOtherFacilityFloorAreaChanged = (e) => setOtherFacilityFloorArea(e.target.value);

  //TRAINING STAFF HANDLE CHANGE
  const onTrainingStaff1NameChanged = (e) => setTrainingStaff1Name(e.target.value);
  const onTrainingStaff1QualificationChanged = (e) =>
    setTrainingStaff1Qualification(e.target.value);
  const onTrainingStaff1ExperienceChanged = (e) => setTrainingStaff1Experience(e.target.value);
  const onTrainingStaff2NameChanged = (e) => setTrainingStaff2Name(e.target.value);
  const onTrainingStaff2QualificationChanged = (e) =>
    setTrainingStaff2Qualification(e.target.value);
  const onTrainingStaff2ExperienceChanged = (e) => setTrainingStaff2Experience(e.target.value);
  const onTrainingStaff3NameChanged = (e) => setTrainingStaff3Name(e.target.value);
  const onTrainingStaff3QualificationChanged = (e) =>
    setTrainingStaff3Qualification(e.target.value);
  const onTrainingStaff3ExperienceChanged = (e) => setTrainingStaff3Experience(e.target.value);

  //SUPPORTING DOCS & DECLARATION HANDLE CHANGE
  const onApplicantsNameChanged = (e) => setApplicantsName(e.target.value);
  const onApplicantsPositionChanged = (e) => setApplicantsPosition(e.target.value);

  //HANDLE CHANGE
  const onEqaFeedbackOnCentreHumanResourceChanged = (e) =>
    setEqaFeedbackOnCentreHumanResource(e.target.value);
  const onEqaFeedbackOnCentreFacilitiesChanged = (e) =>
    setEqaFeedbackOnCentreFacilities(e.target.value);
  const onEqaFeedbackOnCentreNOSNSQChanged = (e) => setEqaFeedbackOnCentreNOSNSQ(e.target.value);
  const onEqaFinalFeedbackOnCentreChanged = (e) => setEqaFinalFeedbackOnCentre(e.target.value);

  //SUPPORTING DOCS HANDLE CHANGE
  const onSelectEqaReports = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setEqaReports(e.target.files[0]);
    }
  };

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const validateStepTen = () => {
    return eqaReports !== null;
  };

  // Form submission handler
  const onSaveEqaReportClicked = async (e) => {
    e.preventDefault();

    /*if (!eqaReports) {
      console.error("No file selected.");
      return;
    }*/

    if (activeStep === 10 && !validateStepTen()) {
      showNotification("error", `Please upload EQA report before submitting.`);
      return;
    }

    try {
      // Step 1: Upload the zip file to the server
      const zipFormData = new FormData();
      zipFormData.append("eqareports", eqaReports);

      const uploadResponse = await axios.post(
        `${apiUrl}/api/centreapplications/upload-eqareports`,
        zipFormData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      const eqaReportsUrl = uploadResponse.data.filePath;

      // Step 3: Append url and store centre application
      const formData = {
        nsqCertifiedAssessorsEQAView,
        assessorsCountEQAView,
        nsqCertifiedVerifiersEQAView,
        verifiersCountEQAView,
        nsqCertifiedCentreManagerEQAView,
        liaisonOfficerPresentEQAView,
        resourceOfficerPresentEQAView,
        siteBankPresentEQAView,
        eqaFeedbackOnCentreHumanResource,
        trainingRoomAdequateEQAView,
        dataRoomAdequateEQAView,
        workshopAdequateEQAView,
        practicalSpaceAdequateEQAView,
        techLabAdequateEQAView,
        staffRoomAdequateEQAView,
        toiletAdequateEQAView,
        firstAidFireAdequateEQAView,
        hostelsAdequateEQAView,
        tradeToolsAdequateEQAView,
        auditoriumAvailableEQAView,
        libraryAdequateEQAView,
        waterAndPowerAdequateEQAView,
        mgtOfficesAdequateEQAView,
        eqaFeedbackOnCentreFacilities,
        nosAvailableEQAView,
        trainingManualAvailableEQAView,
        nosAndManualAlignedEQAView,
        otherTrainingMaterialsAvailableEQAView,
        nsqGuidelineAvailableEQAView,
        staffCompliantWithStandardsEQAView,
        objectivesClearEQAView,
        classParticipationEQAView,
        eqaFeedbackOnCentreNOSNSQ,
        centreManagerEQARemark,
        assessor1EQARemark,
        assessor2EQARemark,
        internalVerifierEQARemark,
        liaisonOfficerEQARemark,
        resourceOfficerEQARemark,
        conduciveLearningEnvironment,
        policyAndProcedure,
        practicalWorkPlacementArrangement,
        sufficientQualityAssuranceTeam,
        learnersSupportSystem,
        eqaFinalFeedbackOnCentre,
        eqaReportsUrl: eqaReportsUrl,
      };

      const response = await axios.put(`${apiUrl}/api/centreapplications/${id}`, formData);

      if (response) {
        showNotification("success", "Centre Visit report submitted successfully!");

        // Redirect after a delay
        setTimeout(() => navigate("/"), 7000);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      showNotification("error", `${error.response.data.message}, Try again.`);
    }
  };

  // Options for autocomplete components
  const options3 = qualificationsData.map((qualification) => ({
    id: qualification.id,
    qualificationType: qualification.qualificationType,
  }));

  const options1 = Object.entries(STATES).map(([key, value]) => ({
    key,
    state: value,
  }));

  const options4 = Object.entries(ZONES).map(([key, value]) => ({
    key,
    zone: value,
  }));

  const options0 = Object.entries(CENTRETYPES).map(([key, value]) => ({
    key,
    centreType: value,
  }));

  // Ensure values are always valid objects or null
  const mapValuesToOptions = (values, options, key) => {
    if (!Array.isArray(values) || !Array.isArray(options)) return [];
    return values
      .map((value) => options.find((option) => option[key] === value))
      .filter((option) => option); // Ensure no undefined values
  };

  const validateStepThree = () => {
    return (
      nsqCertifiedAssessorsEQAView.trim() !== "" &&
      assessorsCountEQAView >= 0 &&
      nsqCertifiedVerifiersEQAView.trim() !== "" &&
      verifiersCountEQAView >= 0 &&
      nsqCertifiedCentreManagerEQAView.trim() !== "" &&
      liaisonOfficerPresentEQAView.trim() !== "" &&
      resourceOfficerPresentEQAView.trim() !== "" &&
      siteBankPresentEQAView.trim() !== "" &&
      eqaFeedbackOnCentreHumanResource.trim() !== ""
    );
  };

  const validateStepFour = () => {
    return (
      trainingRoomAdequateEQAView.trim() !== "" &&
      dataRoomAdequateEQAView.trim() !== "" &&
      workshopAdequateEQAView.trim() !== "" &&
      practicalSpaceAdequateEQAView.trim() !== "" &&
      techLabAdequateEQAView.trim() !== "" &&
      staffRoomAdequateEQAView.trim() !== "" &&
      toiletAdequateEQAView.trim() !== "" &&
      firstAidFireAdequateEQAView.trim() !== "" &&
      hostelsAdequateEQAView.trim() !== "" &&
      tradeToolsAdequateEQAView.trim() !== "" &&
      auditoriumAvailableEQAView.trim() !== "" &&
      libraryAdequateEQAView.trim() !== "" &&
      waterAndPowerAdequateEQAView.trim() !== "" &&
      mgtOfficesAdequateEQAView.trim() !== "" &&
      eqaFeedbackOnCentreFacilities.trim() !== ""
    );
  };

  const validateStepFive = () => {
    return (
      nosAvailableEQAView.trim() !== "" &&
      trainingManualAvailableEQAView.trim() !== "" &&
      nosAndManualAlignedEQAView.trim() !== "" &&
      otherTrainingMaterialsAvailableEQAView.trim() !== "" &&
      nsqGuidelineAvailableEQAView.trim() !== "" &&
      staffCompliantWithStandardsEQAView.trim() !== "" &&
      objectivesClearEQAView.trim() !== "" &&
      classParticipationEQAView.trim() !== "" &&
      eqaFeedbackOnCentreNOSNSQ.trim() !== ""
    );
  };

  const validateStepSix = () => {
    return (
      centreManagerEQARemark.trim() !== "" &&
      assessor1EQARemark.trim() !== "" &&
      internalVerifierEQARemark.trim() !== ""
    );
  };

  const validateStepNine = () => {
    return (
      eqaFinalFeedbackOnCentre.trim() !== "" &&
      conduciveLearningEnvironment.trim() !== "" &&
      policyAndProcedure.trim() !== "" &&
      practicalWorkPlacementArrangement.trim() !== "" &&
      sufficientQualityAssuranceTeam.trim() !== "" &&
      learnersSupportSystem.trim() !== ""
    );
  };

  const handleNext = () => {
    const asterix = "*";
    if (activeStep === 3 && !validateStepThree()) {
      showNotification(
        "error",
        `Please accurately fill all required fields (${asterix}) before proceeding.`
      );
      return;
    } else if (activeStep === 4 && !validateStepFour()) {
      showNotification(
        "error",
        `Please accurately fill all required fields (${asterix}) before proceeding.`
      );
      return;
    } else if (activeStep === 5 && !validateStepFive()) {
      showNotification(
        "error",
        `Please accurately fill all required fields (${asterix}) before proceeding.`
      );
      return;
    } else if (activeStep === 6 && !validateStepSix()) {
      showNotification(
        "error",
        `Please accurately fill all required fields (${asterix}) before proceeding.`
      );
      return;
    } else if (activeStep === 9 && !validateStepNine()) {
      showNotification(
        "error",
        `Please accurately fill all required fields (${asterix}) before proceeding.`
      );
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <MDBox sx={{ pl: 5, pr: 5 }}>
            <MDTypography sx={{ color: "#295C21", fontSize: 17, mb: 3 }}>
              EQA FORM INSTRUCTIONS
            </MDTypography>

            <MDBox sx={{ maxWidth: { xs: "100%", md: "80%" } }}>
              <MDTypography sx={{ color: "#0b1c08", fontSize: 16, wordWrap: "break-word", mb: 3 }}>
                Please use this form to conduct your quality assurance for
                <span style={{ color: "#49a139" }}> &quot;{centreName}&quot; </span>
                Your input begins from the <u>EQA FEEDBACK 1</u> section. Complete all sections in
                BLOCK LETTERS .
              </MDTypography>
              <MDTypography sx={{ color: "#49a139", fontSize: 16, wordWrap: "break-word" }}>
                Read the following conditions which apply to this quality assurance check, before
                you complete this form:
              </MDTypography>
              <List sx={{ fontSize: 14, wordWrap: "break-word" }}>
                <ListItem>
                  1. Download this&nbsp;
                  <a
                    href="/Centre Visit Status Report.pdf" // Adjusted path
                    style={{ color: "#49a139" }}
                    target="_blank"
                    download="Centre_Visit_Status_Report.pdf" // Added download attribute
                  >
                    &quot;
                    <u>Centre Visit Status Report</u>
                    &quot;
                  </a>
                </ListItem>
                <ListItem>
                  2. Complete the downloaded report and upload it in the&nbsp;
                  <u>EQA SUPPORTING DOCUMENTs</u>
                  &nbsp;section.
                </ListItem>
              </List>
            </MDBox>
          </MDBox>
        );

      case 1:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              CENTRE DETAILS
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Centre Name <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreNameChanged}
              value={centreName}
              name="centreName"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="standard"
                disabled
                label={
                  <span>
                    Year of Incorporation <span style={{ color: "red" }}> *</span>
                  </span>
                }
                openTo="year"
                onChange={(newValue) => setYearOfInc(newValue)}
                value={yearOfInc ? dayjs(yearOfInc) : dayjs("")}
                views={["year"]}
                maxDate={dayjs(maxYear)}
                minDate={dayjs("1960")}
                name="yearOfInc"
                sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
              />
            </LocalizationProvider>

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  CAC Number <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCACChanged}
              value={cacNumber}
              name="cacNumber"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
              InputProps={{
                startAdornment: <InputAdornment position="start">RC</InputAdornment>,
              }}
            />

            <MDInput
              variant="standard"
              multiline
              rows={3}
              type="text"
              disabled
              label={
                <span>
                  Permanent Centre Address <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onPermanentCentreAddressChanged}
              value={permanentCentreAddress}
              name="permanentCentreAddress"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              multiline
              rows={3}
              type="text"
              disabled
              label="Training Centre Address"
              onChange={onTrainingCentreAddressChanged}
              value={trainingCentreAddress}
              name="trainingCentreAddress"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Phone Number <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onPhoneChanged}
              value={phone}
              name="phone"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+234</InputAdornment>,
              }}
            />

            <MDInput
              variant="standard"
              type="email"
              disabled
              label={
                <span>
                  Email <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onEmailChanged}
              value={email}
              name="email"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Website"
              onChange={onWebsiteChanged}
              value={website}
              name="website"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Centre Head Fullname <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreHeadChanged}
              value={centreHead}
              name="centreHead"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Centre Head Phone Number <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreHeadPhoneChanged}
              value={centreHeadPhone}
              name="centreHeadPhone"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+234</InputAdornment>,
              }}
            />

            <MDInput
              variant="standard"
              type="email"
              disabled
              label="Centre Head Email"
              onChange={onCentreHeadEmailChanged}
              value={centreHeadEmail}
              name="centreHeadEmail"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDAutocomplete
              id="zone-selector"
              disabled
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
              options={options4}
              value={options4.find((option) => option.zone === zone) || null} // Set a single value or null if no match
              onChange={(event, value) => setZone(value?.zone || "")} // Set only one zone value
              getOptionLabel={(option) => option.zone} // Access the zone property for display
              isOptionEqualToValue={(option, value) => option.zone === value?.zone}
              renderInput={(params) => (
                <MDInput {...params} label="Select Zone" variant="standard" />
              )}
            />

            <MDAutocomplete
              id="state-selector"
              disabled
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
              options={options1}
              value={options1.find((option) => option.state === stateOfRez) || null} // Set null if no selection
              onChange={(event, value) => setStateOfRez(value?.state || "")} // Update the stateOfRez based on selection
              getOptionLabel={(option) => option.state} // Display the state property for options
              isOptionEqualToValue={(option, value) => option.state === value?.state} // Match state
              renderInput={(params) => (
                <MDInput {...params} label="Select State" variant="standard" />
              )}
            />

            <MDAutocomplete
              id="qualification-selector"
              disabled
              sx={{ gridColumn: "span 6", mb: "30px" }}
              options={options3}
              multiple
              value={mapValuesToOptions(qualifications, options3, "qualificationType")}
              onChange={(event, value) =>
                setQualifications(value.map((item) => item.qualificationType))
              }
              getOptionLabel={(option) => option.qualificationType}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput {...params} label="Select Qualifications" variant="standard" />
              )}
            />
          </MDBox>
        );

      case 2:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              YOUR ORGANISATION
            </MDTypography>
            <MDAutocomplete
              id="centretype-selector"
              disabled
              sx={{ gridColumn: "span 6" }}
              options={options0}
              value={options0.find((option) => option.centreType === centreType) || null} // Set a single value or null if no match
              onChange={(event, value) => setCentreType(value?.centreType || "")} // Set only one centreType value
              getOptionLabel={(option) => option.centreType} // Access the centreType property for display
              isOptionEqualToValue={(option, value) => option.centreType === value?.centreType}
              renderInput={(params) => (
                <MDInput {...params} label="Select Centre Type" variant="standard" />
              )}
            />

            <MDTypography sx={{ gridColumn: "span 6", fontSize: "15px", mb: "-10px" }}>
              - Satellite Centres where Training is provided other than your Main Campus/Centre
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Satellite Centre Name 1"
              onChange={onSatelliteCentreName1Changed}
              value={satelliteCentreName1}
              name="satelliteCentreName1"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Satellite Centre Address 1"
              onChange={onSatelliteCentreAddress1Changed}
              value={satelliteCentreAddress1}
              name="satelliteCentreAddress1"
              sx={{ gridColumn: { xs: "span 6", md: "span 4" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Satellite Centre Name 2"
              onChange={onSatelliteCentreName2Changed}
              value={satelliteCentreName2}
              name="satelliteCentreName2"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Satellite Centre Address 2"
              onChange={onSatelliteCentreAddress2Changed}
              value={satelliteCentreAddress2}
              name="satelliteCentreAddress2"
              sx={{ gridColumn: { xs: "span 6", md: "span 4" }, mb: "30px" }}
            />

            <FormControl fullWidth sx={{ gridColumn: "span 6" }}>
              <FormLabel component="legend" sx={{ color: "#49a139", fontSize: "15px" }}>
                Indicate if you are currently an approved Centre of another Awarding Body
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={otherApprovals?.itp}
                      onChange={onOtherApprovalsChange}
                      name="itp"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="ITP"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={otherApprovals?.naddc}
                      onChange={onOtherApprovalsChange}
                      name="naddc"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="NADDC"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={otherApprovals?.nabteb}
                      onChange={onOtherApprovalsChange}
                      name="nabteb"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="NABTEB"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={otherApprovals?.cityGuild}
                      onChange={onOtherApprovalsChange}
                      name="cityGuild"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="City & Guilds"
                />
              </FormGroup>
            </FormControl>

            <MDTypography sx={{ gridColumn: "span 6", fontSize: "15px" }}>
              If your Centre is currently approved as a Centre for NSQ by another Awarding Body,
              please give details below:
            </MDTypography>

            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Awarding Body 1"
              onChange={onAwardingBody1Changed}
              value={awardingBody1}
              name="awardingBody1"
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDAutocomplete
              id="awardingBody1-qualification-selector"
              disabled
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === awardingBody1Qualification
                ) || null
              } // Set null if no selection
              onChange={(event, value) =>
                setAwardingBody1Qualification(value?.qualificationType || "")
              } // Update the stateOfRez based on selection
              getOptionLabel={(option) => option.qualificationType} // Display the state property for options
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              } // Match state
              renderInput={(params) => (
                <MDInput {...params} label="Qualification 1" variant="standard" />
              )}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Level"
              onChange={onAwardingBody1LevelChanged}
              value={awardingBody1Level}
              name="awardingBody1Level"
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="standard"
                label={<span>Date Approved</span>}
                value={
                  awardingBody1DateApproved ? dayjs(awardingBody1DateApproved, "DD-MM-YYYY") : null
                }
                onChange={(newValue) =>
                  setAwardingBody1DateApproved(newValue ? newValue.format("DD-MM-YYYY") : "")
                }
                views={["year", "month", "day"]}
                maxDate={dayjs()} // Today's date
                minDate={dayjs("1960-01-01")} // Min date set to 1960
                name="awardingBody1DateApproved"
                sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
                renderInput={(params) => (
                  <MDInput {...params} value={awardingBody1DateApproved || ""} /> // Format displayed value
                )}
              />
            </LocalizationProvider>

            <MDAutocomplete
              id="awardingBody1ApprovalStatus-selector"
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
              options={[
                { label: "Done", value: "Done" },
                { label: "Pending", value: "Pending" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option?.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Approval Status"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setAwardingBody1ApprovalStatus(value?.value || ""); // Update state based on selection
              }}
              isOptionEqualToValue={
                (option, value) => option?.value === value?.value // Safely compare option and value
              }
            />

            <MDInput
              variant="standard"
              type="text"
              label="Awarding Body 2"
              onChange={onAwardingBody2Changed}
              value={awardingBody2}
              name="awardingBody2"
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDAutocomplete
              id="awardingBody2-qualification-selector"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === awardingBody2Qualification
                ) || null
              } // Set null if no selection
              onChange={(event, value) =>
                setAwardingBody2Qualification(value?.qualificationType || "")
              } // Update the stateOfRez based on selection
              getOptionLabel={(option) => option.qualificationType} // Display the state property for options
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              } // Match state
              renderInput={(params) => (
                <MDInput {...params} label="Qualification 2" variant="standard" />
              )}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Level"
              onChange={onAwardingBody2LevelChanged}
              value={awardingBody2Level}
              name="awardingBody2Level"
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="standard"
                label={<span>Date Approved</span>}
                value={
                  awardingBody2DateApproved ? dayjs(awardingBody2DateApproved, "DD-MM-YYYY") : null
                }
                onChange={(newValue) =>
                  setAwardingBody2DateApproved(newValue ? newValue.format("DD-MM-YYYY") : "")
                }
                views={["year", "month", "day"]}
                maxDate={dayjs()} // Today's date
                minDate={dayjs("1960-01-01")} // Min date set to 1960
                name="awardingBody2DateApproved"
                sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
                renderInput={(params) => (
                  <MDInput {...params} value={awardingBody2DateApproved || ""} /> // Format displayed value
                )}
              />
            </LocalizationProvider>

            <MDAutocomplete
              id="awardingBody2ApprovalStatus-selector"
              sx={{ gridColumn: { xs: "span 6", md: "span 1" }, mb: "30px" }}
              options={[
                { label: "Done", value: "Done" },
                { label: "Pending", value: "Pending" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Approval Status"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                console.log("Value 2: ", value);
                setAwardingBody2ApprovalStatus(value?.value || "");
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </MDBox>
        );

      case 3:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              EQA FEEDBACK 1
            </MDTypography>
            {[
              {
                id: "nsqCertifiedAssessor-selector",
                label: "i. Are there NSQ certified Assessors at your Centre?",
                centreState: nsqCertifiedAssessors,
                setCentreState: setNsqCertifiedAssessors,
                eqaState: nsqCertifiedAssessorsEQAView,
                setEqaState: setNsqCertifiedAssessorsEQAView,
              },
              {
                id: "assessorsCount",
                label: "ii. How many Assessors are available at Centre?",
                centreState: assessorsCount,
                setCentreState: onAssessorsCountChanged,
                eqaState: assessorsCountEQAView,
                setEqaState: onAssessorsCountEQAViewChanged,
                type: "number",
              },
              {
                id: "nsqCertifiedVerifier-selector",
                label: "iii. Are there NSQ certified Verifiers at your Centre?",
                centreState: nsqCertifiedVerifiers,
                setCentreState: setNsqCertifiedVerifiers,
                eqaState: nsqCertifiedVerifiersEQAView,
                setEqaState: setNsqCertifiedVerifiersEQAView,
              },
              {
                id: "verifiersCount",
                label: "iv. How many Verifiers are available at Centre?",
                centreState: verifiersCount,
                setCentreState: onVerifiersCountChanged,
                eqaState: verifiersCountEQAView,
                setEqaState: onVerifiersCountEQAViewChanged,
                type: "number",
              },
              {
                id: "nsqCertifiedCentreManager-selector",
                label: "v. Is the Centre Manager NSQ certified?",
                centreState: nsqCertifiedCentreManager,
                setCentreState: setNsqCertifiedCentreManager,
                eqaState: nsqCertifiedCentreManagerEQAView,
                setEqaState: setNsqCertifiedCentreManagerEQAView,
              },
              {
                id: "liaisonOfficerPresent-selector",
                label: "vi. Is there a Liaison Officer with the Industry at your Centre?",
                centreState: liaisonOfficerPresent,
                setCentreState: setLiaisonOfficerPresent,
                eqaState: liaisonOfficerPresentEQAView,
                setEqaState: setLiaisonOfficerPresentEQAView,
              },
              {
                id: "resourceOfficerPresent-selector",
                label: "vii. Is there a Resource/Data Officer at your Centre?",
                centreState: resourceOfficerPresent,
                setCentreState: setResourceOfficerPresent,
                eqaState: resourceOfficerPresentEQAView,
                setEqaState: setResourceOfficerPresentEQAView,
              },
              {
                id: "siteBankPresent-selector",
                label: `viii. Are there evidences/records of site-banks for apprenticeship training
                at your Centre?`,
                centreState: siteBankPresent,
                setCentreState: setSiteBankPresent,
                eqaState: siteBankPresentEQAView,
                setEqaState: setSiteBankPresentEQAView,
              },
            ].map(({ id, type, label, centreState, setCentreState, eqaState, setEqaState }) => (
              <MDBox
                key={id}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { sm: "center" },
                  gap: 2,
                  mb: 3, // Adds spacing between each question-answer set
                  gridColumn: "span 6",
                }}
              >
                <MDTypography sx={{ width: { sm: "60%" }, fontSize: "15px" }}>
                  {label} <span style={{ color: "red" }}> *</span>
                </MDTypography>

                {type === "number" ? (
                  <>
                    <MDInput
                      variant="standard"
                      type="number"
                      label="Centre's Response"
                      onChange={setCentreState}
                      value={centreState}
                      InputProps={{ inputProps: { min: 0 } }}
                      name={id}
                      sx={{ width: { xs: "100%", sm: "20%" } }}
                      disabled
                    />

                    <MDInput
                      variant="standard"
                      type="number"
                      label="EQA's Response"
                      onChange={setEqaState}
                      value={eqaState}
                      InputProps={{ inputProps: { min: 0 } }}
                      name={`${id}EQAView`}
                      sx={{ width: { xs: "100%", sm: "20%" } }}
                    />
                  </>
                ) : (
                  <>
                    <MDAutocomplete
                      id={`${id}-selector`}
                      sx={{ width: { xs: "100%", sm: "20%" } }}
                      options={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                      ]}
                      value={centreState ? { label: centreState, value: centreState } : null}
                      getOptionLabel={(option) => option?.label || ""}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          disabled
                          fullWidth
                          label="Centre's Response"
                          variant="standard"
                        />
                      )}
                      onChange={(event, value) => setCentreState(value?.value || "")}
                      isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    />

                    <MDAutocomplete
                      id={`${id}EQAView-selector`}
                      sx={{ width: { xs: "100%", sm: "20%" } }}
                      options={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                      ]}
                      value={eqaState ? { label: eqaState, value: eqaState } : null}
                      getOptionLabel={(option) => option?.label || ""}
                      renderInput={(params) => (
                        <MDInput {...params} fullWidth label="EQA Response" variant="standard" />
                      )}
                      onChange={(event, value) => setEqaState(value?.value || "")}
                      isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    />
                  </>
                )}
              </MDBox>
            ))}

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDInput
                variant="standard"
                type="text"
                multiline
                rows={3}
                label={
                  <span>
                    EQA General Feedback on Centre Human Resource
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                onChange={onEqaFeedbackOnCentreHumanResourceChanged}
                value={eqaFeedbackOnCentreHumanResource}
                name="eqaFeedbackOnCentreHumanResource"
                sx={{ width: "100%", mb: 3 }}
              />
            </MDBox>
          </MDBox>
        );

      case 4:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              EQA FEEDBACK 2
            </MDTypography>
            {[
              {
                id: "trainingRoomAdequate",
                label: `ix. Are the Classrooms/Training Rooms adequate in terms of space,
                lighting, ventilation & traffic?`,
                centreState: trainingRoomAdequate,
                setCentreState: setTrainingRoomAdequate,
                eqaState: trainingRoomAdequateEQAView,
                setEqaState: setTrainingRoomAdequateEQAView,
              },
              {
                id: "dataRoomAdequate",
                label: `x. Is there a Data/Records room available and adequate?`,
                centreState: dataRoomAdequate,
                setCentreState: setDataRoomAdequate,
                eqaState: dataRoomAdequateEQAView,
                setEqaState: setDataRoomAdequateEQAView,
              },
              {
                id: "workshopAdequate",
                label: `xi. Are Workshop spaces adequate and fit for purpose?`,
                centreState: workshopAdequate,
                setCentreState: setWorkshopAdequate,
                eqaState: workshopAdequateEQAView,
                setEqaState: setWorkshopAdequateEQAView,
              },
              {
                id: "practicalSpaceAdequate",
                label: `xii. Are Practical training spaces available and suitable for each trade?`,
                centreState: practicalSpaceAdequate,
                setCentreState: setPracticalSpaceAdequate,
                eqaState: practicalSpaceAdequateEQAView,
                setEqaState: setPracticalSpaceAdequateEQAView,
              },
              {
                id: "techLabAdequate",
                type: "other",
                label: `xiii. Are Technical laboratories AVAILABLE or HIRED?`,
                centreState: techLabAdequate,
                setCentreState: setTechLabAdequate,
                eqaState: techLabAdequateEQAView,
                setEqaState: setTechLabAdequateEQAView,
              },
              {
                id: "staffRoomAdequate",
                label: `xiv. Are Staff rooms adequate and fit for purpose?`,
                centreState: staffRoomAdequate,
                setCentreState: setStaffRoomAdequate,
                eqaState: staffRoomAdequateEQAView,
                setEqaState: setStaffRoomAdequateEQAView,
              },
              {
                id: "toiletAdequate",
                label: `xv. Are Toilets available and functional?`,
                centreState: toiletAdequate,
                setCentreState: setToiletAdequate,
                eqaState: toiletAdequateEQAView,
                setEqaState: setToiletAdequateEQAView,
              },
              {
                id: "firstAidFireAdequate",
                label: `xvi. Are First Aid/Firefighting facilities available and functional?`,
                centreState: firstAidFireAdequate,
                setCentreState: setFirstAidFireAdequate,
                eqaState: firstAidFireAdequateEQAView,
                setEqaState: setFirstAidFireAdequateEQAView,
              },
              {
                id: "hostelsAdequate",
                label: `xvii. Are Hostels available and fit for purpose?`,
                centreState: hostelsAdequate,
                setCentreState: setHostelsAdequate,
                eqaState: hostelsAdequateEQAView,
                setEqaState: setHostelsAdequateEQAView,
              },
              {
                id: "tradeToolsAdequate",
                label: `xviii. Are there Tools and Equipment for relevant trades at your Centre?`,
                centreState: tradeToolsAdequate,
                setCentreState: setTradeToolsAdequate,
                eqaState: tradeToolsAdequateEQAView,
                setEqaState: setTradeToolsAdequateEQAView,
              },
              {
                id: "auditoriumAvailable",
                type: "other",
                label: `xix. Is Auditorium AVAILABLE or HIRED?`,
                centreState: auditoriumAvailable,
                setCentreState: setAuditoriumAvailable,
                eqaState: auditoriumAvailableEQAView,
                setEqaState: setAuditoriumAvailableEQAView,
              },
              {
                id: "libraryAdequate",
                label: `xx. Are Library and Study rooms adequate?`,
                centreState: libraryAdequate,
                setCentreState: setLibraryAdequate,
                eqaState: libraryAdequateEQAView,
                setEqaState: setLibraryAdequateEQAView,
              },
              {
                id: "waterAndPowerAdequate",
                label: `xxi. Is Water and Power supply adequate?`,
                centreState: waterAndPowerAdequate,
                setCentreState: setWaterAndPowerAdequate,
                eqaState: waterAndPowerAdequateEQAView,
                setEqaState: setWaterAndPowerAdequateEQAView,
              },
              {
                id: "mgtOfficesAdequate",
                label: `xxii. Are Management Offices adequate and fit for purpose?`,
                centreState: mgtOfficesAdequate,
                setCentreState: setMgtOfficesAdequate,
                eqaState: mgtOfficesAdequateEQAView,
                setEqaState: setMgtOfficesAdequateEQAView,
              },
            ].map(({ id, label, type, centreState, setCentreState, eqaState, setEqaState }) => (
              <MDBox
                key={id}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { sm: "center" },
                  gap: 2,
                  mb: 3, // Adds spacing between each question-answer set
                  gridColumn: "span 6",
                }}
              >
                <MDTypography sx={{ width: { sm: "60%" }, fontSize: "15px" }}>
                  {label} <span style={{ color: "red" }}> *</span>
                </MDTypography>

                <MDAutocomplete
                  id={`${id}-selector`}
                  sx={{ width: { xs: "100%", sm: "20%" } }}
                  options={
                    type === "other"
                      ? [
                          { label: "AVAILABLE", value: "AVAILABLE" },
                          { label: "HIRED", value: "HIRED" },
                        ]
                      : [
                          { label: "Yes", value: "Yes" },
                          { label: "No", value: "No" },
                        ]
                  }
                  value={centreState ? { label: centreState, value: centreState } : null}
                  getOptionLabel={(option) => option?.label || ""}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      disabled
                      fullWidth
                      label="Centre's Response"
                      variant="standard"
                    />
                  )}
                  onChange={(event, value) => setCentreState(value?.value || "")}
                  isOptionEqualToValue={(option, value) => option?.value === value?.value}
                />

                <MDAutocomplete
                  id={`${id}EQAView-selector`}
                  sx={{ width: { xs: "100%", sm: "20%" } }}
                  options={
                    type === "other"
                      ? [
                          { label: "AVAILABLE", value: "AVAILABLE" },
                          { label: "HIRED", value: "HIRED" },
                        ]
                      : [
                          { label: "Yes", value: "Yes" },
                          { label: "No", value: "No" },
                        ]
                  }
                  value={eqaState ? { label: eqaState, value: eqaState } : null}
                  getOptionLabel={(option) => option?.label || ""}
                  renderInput={(params) => (
                    <MDInput {...params} fullWidth label="EQA Response" variant="standard" />
                  )}
                  onChange={(event, value) => setEqaState(value?.value || "")}
                  isOptionEqualToValue={(option, value) => option?.value === value?.value}
                />
              </MDBox>
            ))}

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDInput
                variant="standard"
                type="text"
                multiline
                rows={3}
                label={
                  <span>
                    EQA General Feedback on Centre Facilities
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                onChange={onEqaFeedbackOnCentreFacilitiesChanged}
                value={eqaFeedbackOnCentreFacilities}
                name="eqaFeedbackOnCentreFacilities"
                sx={{ width: "100%", mb: 3 }}
              />
            </MDBox>
          </MDBox>
        );

      case 5:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              EQA FEEDBACK 3
            </MDTypography>
            {[
              {
                id: "nosAvailable",
                label: "xxiii. Is NOS available at your Centre?",
                centreState: nosAvailable,
                setCentreState: setNosAvailable,
                eqaState: nosAvailableEQAView,
                setEqaState: setNosAvailableEQAView,
              },
              {
                id: "trainingManualAvailable",
                label: "xxiv. Are there Training Manuals at your Centre?",
                centreState: trainingManualAvailable,
                setCentreState: setTrainingManualAvailable,
                eqaState: trainingManualAvailableEQAView,
                setEqaState: setTrainingManualAvailableEQAView,
              },
              {
                id: "nosAndManualAlignment",
                label: "xxv. Are the Manuals aligned to the NOS?",
                centreState: nosAndManualAlignment,
                setCentreState: setNosAndManualAlignment,
                eqaState: nosAndManualAlignedEQAView,
                setEqaState: setNosAndManualAlignedEQAView,
              },
              {
                id: "otherTrainingMaterialsAvailable",
                label: `xxvi. Are there other Training materials that are relevant to the training
                needs?`,
                centreState: otherTrainingMaterialsAvailable,
                setCentreState: setOtherTrainingMaterialsAvailable,
                eqaState: otherTrainingMaterialsAvailableEQAView,
                setEqaState: setOtherTrainingMaterialsAvailableEQAView,
              },
              {
                id: "nsqGuidelineAvailable",
                label: `xxvii. Is there an NSQ Code of Practice and Implementation Guideline at
                your Centre?`,
                centreState: nsqGuidelineAvailable,
                setCentreState: setNsqGuidelineAvailable,
                eqaState: nsqGuidelineAvailableEQAView,
                setEqaState: setNsqGuidelineAvailableEQAView,
              },
              {
                id: "staffCompliantWithStandards",
                label: `xxviii. Are the Trainers and Staff conversant/comply with these documents?`,
                centreState: staffCompliantWithStandards,
                setCentreState: setStaffCompliantWithStandards,
                eqaState: staffCompliantWithStandardsEQAView,
                setEqaState: setStaffCompliantWithStandardsEQAView,
              },
              {
                id: "objectivesClear",
                label: `xxix. Are the Objectives of the training clearly defined?`,
                centreState: objectivesClear,
                setCentreState: setObjectivesClear,
                eqaState: objectivesClearEQAView,
                setEqaState: setObjectivesClearEQAView,
              },
              {
                id: "classParticipation",
                label: `xxx. Is Class participation through questioning encouraged?`,
                centreState: classParticipation,
                setCentreState: setClassParticipation,
                eqaState: classParticipationEQAView,
                setEqaState: setClassParticipationEQAView,
              },
            ].map(({ id, label, centreState, setCentreState, eqaState, setEqaState }) => (
              <MDBox
                key={id}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { sm: "center" },
                  gap: 2,
                  mb: 3, // Adds spacing between each question-answer set
                  gridColumn: "span 6",
                }}
              >
                <MDTypography sx={{ width: { sm: "60%" }, fontSize: "15px" }}>
                  {label} <span style={{ color: "red" }}> *</span>
                </MDTypography>

                <MDAutocomplete
                  id={`${id}-selector`}
                  sx={{ width: { xs: "100%", sm: "20%" } }}
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ]}
                  value={centreState ? { label: centreState, value: centreState } : null}
                  getOptionLabel={(option) => option?.label || ""}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      disabled
                      fullWidth
                      label="Centre's Response"
                      variant="standard"
                    />
                  )}
                  onChange={(event, value) => setCentreState(value?.value || "")}
                  isOptionEqualToValue={(option, value) => option?.value === value?.value}
                />

                <MDAutocomplete
                  id={`${id}EQAView-selector`}
                  sx={{ width: { xs: "100%", sm: "20%" } }}
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ]}
                  value={eqaState ? { label: eqaState, value: eqaState } : null}
                  getOptionLabel={(option) => option?.label || ""}
                  renderInput={(params) => (
                    <MDInput {...params} fullWidth label="EQA Response" variant="standard" />
                  )}
                  onChange={(event, value) => setEqaState(value?.value || "")}
                  isOptionEqualToValue={(option, value) => option?.value === value?.value}
                />
              </MDBox>
            ))}

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDInput
                variant="standard"
                type="text"
                multiline
                rows={3}
                label={
                  <span>
                    EQA General Feedback on Centre NOS & NSQ
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                onChange={onEqaFeedbackOnCentreNOSNSQChanged}
                value={eqaFeedbackOnCentreNOSNSQ}
                name="eqaFeedbackOnCentreNOSNSQ"
                sx={{ width: "100%", mb: 3 }}
              />
            </MDBox>
          </MDBox>
        );

      case 6:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              EQA FEEDBACK 4
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Centre Manager&apos;s FullName <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreManagerNameChanged}
              value={centreManagerName}
              name="centreManagerName"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Centre Manager&apos;s Qualification <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreManagerQualificationChanged}
              value={centreManagerQualification}
              name="centreManagerQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  EQA Remark <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreManagerEQARemarkChanged}
              value={centreManagerEQARemark}
              name="centreManagerEQARemark"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Assessor 1&apos;s FullName <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onAssessor1NameChanged}
              value={assessor1Name}
              name="assessor1Name"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Assessor 1&apos;s Qualification <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onAssessor1QualificationChanged}
              value={assessor1Qualification}
              name="assessor1Qualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  EQA Remark <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onAssessor1EQARemarkChanged}
              value={assessor1EQARemark}
              name="assessor1EQARemark"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Assessor 2&apos;s FullName</span>}
              onChange={onAssessor2NameChanged}
              value={assessor2Name}
              name="assessor2Name"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Assessor 2&apos;s Qualification</span>}
              onChange={onAssessor2QualificationChanged}
              value={assessor2Qualification}
              name="assessor2Qualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="EQA Remark"
              onChange={onAssessor2EQARemarkChanged}
              value={assessor2EQARemark}
              name="assessor2EQARemark"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Internal Verifier&apos;s FullName <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onInternalVerifierNameChanged}
              value={internalVerifierName}
              name="internalVerifierName"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Internal Verifier&apos;s Qualification <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onInternalVerifierQualificationChanged}
              value={internalVerifierQualification}
              name="internalVerifierQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  EQA Remark <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onInternalVerifierEQARemarkChanged}
              value={internalVerifierEQARemark}
              name="internalVerifierEQARemark"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Industry/Workplace Liaison Officer&apos;s FullName</span>}
              onChange={onLiaisonOfficerNameChanged}
              value={liaisonOfficerName}
              name="liaisonOfficerName"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Industry/Workplace Liaison Officer&apos;s Qualification</span>}
              onChange={onLiaisonOfficerQualificationChanged}
              value={liaisonOfficerQualification}
              name="liaisonOfficerQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="EQA Remark"
              onChange={onLiaisonOfficerEQARemarkChanged}
              value={liaisonOfficerEQARemark}
              name="liaisonOfficerEQARemark"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Resource/Data Officer&apos;s FullName</span>}
              onChange={onResourceOfficerNameChanged}
              value={resourceOfficerName}
              name="resourceOfficerName"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Resource/Data Officer&apos;s Qualification</span>}
              onChange={onResourceOfficerQualificationChanged}
              value={resourceOfficerQualification}
              name="resourceOfficerQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="EQA Remark"
              onChange={onResourceOfficerEQARemarkChanged}
              value={resourceOfficerEQARemark}
              name="resourceOfficerEQARemark"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Other Staff&apos;s FullName</span>}
              onChange={onOtherStaffNameChanged}
              value={otherStaffName}
              name="otherStaffName"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Other Staff&apos;s Qualification</span>}
              onChange={onOtherStaffQualificationChanged}
              value={otherStaffQualification}
              name="otherStaffQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Other Staff&apos;s Designation</span>}
              onChange={onOtherStaffDesignationChanged}
              value={otherStaffDesignation}
              name="otherStaffDesignation"
              helperText="e.g: Program Officer, HOD Admin, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 2" }, mb: { xs: 1, md: 0 } }}
            />
          </MDBox>
        );

      case 7:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              PHYSICAL FACILITIES
            </MDTypography>
            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Classrooms/Training Rooms - Quantity"
              onChange={onTrainingRoomQuantityChanged}
              value={trainingRoomQuantity}
              name="trainingRoomQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Classrooms/Training Rooms - Total Floor Area"
              onChange={onTrainingRoomFloorAreaChanged}
              value={trainingRoomFloorArea}
              name="trainingRoomFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Workshops - Quantity"
              onChange={onWorkshopQuantityChanged}
              value={workshopQuantity}
              name="workshopQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Workshops - Total Floor Area"
              onChange={onWorkshopFloorAreaChanged}
              value={workshopFloorArea}
              name="workshopFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Practical Training Space for each Trade - Quantity"
              onChange={onPracticalTrainingSpaceQuantityChanged}
              value={practicalTrainingSpaceQuantity}
              name="practicalTrainingSpaceQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Practical Training Space for each Trade - Total Floor Area"
              onChange={onPracticalTrainingSpaceFloorAreaChanged}
              value={practicalTrainingSpaceFloorArea}
              name="practicalTrainingSpaceFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Technical Laboratories - Quantity"
              onChange={onTechnicalLabQuantityChanged}
              value={technicalLabQuantity}
              name="technicalLabQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Technical Laboratories - Total Floor Area"
              onChange={onTechnicalLabFloorAreaChanged}
              value={technicalLabFloorArea}
              name="technicalLabFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Data/Records Room - Quantity"
              onChange={onRecordsRoomQuantityChanged}
              value={recordsRoomQuantity}
              name="recordsRoomQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Data/Records Room - Total Floor Area"
              onChange={onRecordsRoomFloorAreaChanged}
              value={recordsRoomFloorArea}
              name="recordsRoomFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Auditorium - Quantity"
              onChange={onAuditoriumQuantityChanged}
              value={auditoriumQuantity}
              name="auditoriumQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Auditorium - Total Floor Area"
              onChange={onAuditoriumFloorAreaChanged}
              value={auditoriumFloorArea}
              name="auditoriumFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Library & Study Rooms - Quantity"
              onChange={onLibraryQuantityChanged}
              value={libraryQuantity}
              name="libraryQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Library & Study Rooms - Total Floor Area"
              onChange={onLibraryFloorAreaChanged}
              value={libraryFloorArea}
              name="libraryFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Water & Power Supply (Public/Private) - Quantity"
              onChange={onUtilityQuantityChanged}
              value={utilityQuantity}
              name="utilityQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Water & Power Supply (Public/Private) - Total Floor Area"
              onChange={onUtilityFloorAreaChanged}
              value={utilityFloorArea}
              name="utilityFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Management Offices - Quantity"
              onChange={onMgtOfficesQuantityChanged}
              value={mgtOfficesQuantity}
              name="mgtOfficesQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Management Offices - Total Floor Area"
              onChange={onMgtOfficesFloorAreaChanged}
              value={mgtOfficesFloorArea}
              name="mgtOfficesFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Staff Rooms - Quantity"
              onChange={onStaffRoomQuantityChanged}
              value={staffRoomQuantity}
              name="staffRoomQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Staff Rooms - Total Floor Area"
              onChange={onStaffRoomFloorAreaChanged}
              value={staffRoomFloorArea}
              name="staffRoomFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Toilets - Quantity"
              onChange={onToiletQuantityChanged}
              value={toiletQuantity}
              name="toiletQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Toilets - Total Floor Area"
              onChange={onToiletFloorAreaChanged}
              value={toiletFloorArea}
              name="toiletFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="First Aid/Firefighting Facility - Quantity"
              onChange={onFirstAidFireFacilityQuantityChanged}
              value={firstAidFireFacilityQuantity}
              name="firstAidFireFacilityQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="First Aid/Firefighting Facility - Total Floor Area"
              onChange={onFirstAidFireFacilityFloorAreaChanged}
              value={firstAidFireFacilityFloorArea}
              name="firstAidFireFacilityFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Hostel - Quantity"
              onChange={onHostelQuantityChanged}
              value={hostelQuantity}
              name="hostelQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Hostel - Total Floor Area"
              onChange={onHostelFloorAreaChanged}
              value={hostelFloorArea}
              name="hostelFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Tools & Equipment for relevant Trades - Quantity"
              onChange={onTradeToolsQuantityChanged}
              value={tradeToolsQuantity}
              name="tradeToolsQuantity"
              sx={{ gridColumn: { xs: "span 6", md: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Tools & Equipment for relevant Trades - Total Floor Area"
              onChange={onTradeToolsFloorAreaChanged}
              value={tradeToolsFloorArea}
              name="tradeToolsFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 3" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Other Facility Name"
              onChange={onOtherFacilityNameChanged}
              value={otherFacilityName}
              name="otherFacilityName"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Others - Quantity"
              onChange={onOtherFacilityQuantityChanged}
              value={otherFacilityQuantity}
              name="otherFacilityQuantity"
              InputProps={{ inputProps: { min: 0 } }}
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Others - Total Floor Area"
              onChange={onOtherFacilityFloorAreaChanged}
              value={otherFacilityFloorArea}
              name="otherFacilityFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 2" }, mb: { xs: 1, md: 0 } }}
            />
          </MDBox>
        );

      case 8:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              TRAINING STAFF
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Full Name of Staff 1"
              onChange={onTrainingStaff1NameChanged}
              value={trainingStaff1Name}
              name="trainingStaff1Name"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Staff's Qualification"
              onChange={onTrainingStaff1QualificationChanged}
              value={trainingStaff1Qualification}
              name="trainingStaff1Qualification"
              helperText="e.g: BSc. Building, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDAutocomplete
              id="trainingStaff1Gender-selector"
              disabled
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Gender"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setTrainingStaff1Gender(value?.value || ""); // Call setGender with the value (e.g., "male" or "female")
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Practical Experience"
              onChange={onTrainingStaff1ExperienceChanged}
              value={trainingStaff1Experience}
              name="trainingStaff1Experience"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDAutocomplete
              id="trainingStaff1Specialization-selector"
              disabled
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === trainingStaff1Specialization
                ) || null
              }
              onChange={(event, value) =>
                setTrainingStaff1Specialization(value?.qualificationType || "")
              }
              getOptionLabel={(option) => option.qualificationType || ""}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput {...params} label="Trade Specialization" variant="standard" />
              )}
              sx={{ gridColumn: { xs: "span 6", md: "span 1" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              disabled
              type="text"
              label="Full Name of Staff 2"
              onChange={onTrainingStaff2NameChanged}
              value={trainingStaff2Name}
              name="trainingStaff2Name"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              disabled
              type="text"
              label="Staff's Qualification"
              onChange={onTrainingStaff2QualificationChanged}
              value={trainingStaff2Qualification}
              name="trainingStaff2Qualification"
              helperText="e.g: BSc. Building, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDAutocomplete
              id="trainingStaff2Gender-selector"
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  disabled
                  fullWidth
                  label="Gender"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setTrainingStaff2Gender(value?.value || ""); // Call setGender with the value (e.g., "male" or "female")
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />

            <MDInput
              variant="standard"
              disabled
              type="number"
              label="Practical Experience"
              onChange={onTrainingStaff2ExperienceChanged}
              value={trainingStaff2Experience}
              name="trainingStaff2Experience"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDAutocomplete
              id="trainingStaff2Specialization-selector"
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === trainingStaff2Specialization
                ) || null
              }
              onChange={(event, value) =>
                setTrainingStaff2Specialization(value?.qualificationType || "")
              }
              getOptionLabel={(option) => option.qualificationType || ""}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput {...params} label="Trade Specialization" variant="standard" disabled />
              )}
              sx={{ gridColumn: { xs: "span 6", md: "span 1" }, mb: { xs: 1, md: 0 } }}
            />

            <MDInput
              variant="standard"
              disabled
              type="text"
              label="Full Name of Staff 3"
              onChange={onTrainingStaff3NameChanged}
              value={trainingStaff3Name}
              name="trainingStaff3Name"
              sx={{ gridColumn: { xs: "span 6", md: "span 2" } }}
            />

            <MDInput
              variant="standard"
              disabled
              type="text"
              label="Staff's Qualification"
              onChange={onTrainingStaff3QualificationChanged}
              value={trainingStaff3Qualification}
              name="trainingStaff3Qualification"
              helperText="e.g: BSc. Building, etc."
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDAutocomplete
              id="trainingStaff3Gender-selector"
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  disabled
                  fullWidth
                  label="Gender"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setTrainingStaff3Gender(value?.value || ""); // Call setGender with the value (e.g., "male" or "female")
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />

            <MDInput
              variant="standard"
              disabled
              type="number"
              label="Practical Experience"
              onChange={onTrainingStaff3ExperienceChanged}
              value={trainingStaff3Experience}
              name="trainingStaff3Experience"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
              }}
              sx={{ gridColumn: { xs: "span 6", md: "span 1" } }}
            />

            <MDAutocomplete
              id="trainingStaff3Specialization-selector"
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === trainingStaff3Specialization
                ) || null
              }
              onChange={(event, value) =>
                setTrainingStaff3Specialization(value?.qualificationType || "")
              }
              getOptionLabel={(option) => option.qualificationType || ""}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput {...params} label="Trade Specialization" variant="standard" disabled />
              )}
              sx={{ gridColumn: { xs: "span 6", md: "span 1" }, mb: { xs: 1, md: 0 } }}
            />
          </MDBox>
        );

      case 9:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              EQA FINAL REPORT
            </MDTypography>
            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDInput
                variant="standard"
                type="text"
                multiline
                rows={6}
                label={
                  <span>
                    EQA End of Visit Report <span style={{ color: "red" }}> *</span>
                  </span>
                }
                onChange={onEqaFinalFeedbackOnCentreChanged}
                value={eqaFinalFeedbackOnCentre}
                name="eqaFinalFeedbackOnCentre"
                sx={{ width: "100%", mb: 3 }}
              />
            </MDBox>

            <MDBox
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "left", md: "center" },
                gap: { xs: 1, md: 4 },
                gridColumn: "span 6",
                mb: 1,
              }}
            >
              <MDTypography sx={{ width: { xs: "100%", md: "60%" }, fontSize: "15px" }}>
                I.{"\u00A0"}
                Conducive Learning Environment: <span style={{ color: "red" }}>*</span>
              </MDTypography>

              <MDAutocomplete
                id="conduciveLearningEnvironment-selector"
                sx={{ width: { xs: "100%", md: "40%" } }}
                options={[
                  { label: "APPROVED", value: "APPROVED" },
                  { label: "TEMPORAL APPROVAL", value: "TEMPORAL APPROVAL" },
                  { label: "NEED MORE INFORMATION", value: "NEED MORE INFORMATION" },
                  { label: "NOT APPROVED", value: "NOT APPROVED" },
                ]} // Array of options for the autocomplete
                value={
                  conduciveLearningEnvironment
                    ? { label: conduciveLearningEnvironment, value: conduciveLearningEnvironment }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select as appropriate"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setConduciveLearningEnvironment(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "left", md: "center" },
                gap: { xs: 1, md: 4 },
                gridColumn: "span 6",
                mb: 1,
              }}
            >
              <MDTypography sx={{ width: { xs: "100%", md: "60%" }, fontSize: "15px" }}>
                II.{"\u00A0"} Policy and Procedure: <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="policyAndProcedure-selector"
                sx={{ width: { xs: "100%", md: "40%" } }}
                options={[
                  { label: "APPROVED", value: "APPROVED" },
                  { label: "TEMPORAL APPROVAL", value: "TEMPORAL APPROVAL" },
                  { label: "NEED MORE INFORMATION", value: "NEED MORE INFORMATION" },
                  { label: "NOT APPROVED", value: "NOT APPROVED" },
                ]} // Array of options for the autocomplete
                value={
                  policyAndProcedure
                    ? { label: policyAndProcedure, value: policyAndProcedure }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select as appropriate"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setPolicyAndProcedure(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "left", md: "center" },
                gap: { xs: 1, md: 4 },
                gridColumn: "span 6",
                mb: 1,
              }}
            >
              <MDTypography sx={{ width: { xs: "100%", md: "60%" }, fontSize: "15px" }}>
                III.{"\u00A0"}
                Practical Work Placement Arrangement: <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="practicalWorkPlacementArrangement-selector"
                sx={{ width: { xs: "100%", md: "40%" } }}
                options={[
                  { label: "APPROVED", value: "APPROVED" },
                  { label: "TEMPORAL APPROVAL", value: "TEMPORAL APPROVAL" },
                  { label: "NEED MORE INFORMATION", value: "NEED MORE INFORMATION" },
                  { label: "NOT APPROVED", value: "NOT APPROVED" },
                ]} // Array of options for the autocomplete
                value={
                  practicalWorkPlacementArrangement
                    ? {
                        label: practicalWorkPlacementArrangement,
                        value: practicalWorkPlacementArrangement,
                      }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select as appropriate"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setPracticalWorkPlacementArrangement(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "left", md: "center" },
                gap: { xs: 1, md: 4 },
                gridColumn: "span 6",
                mb: 1,
              }}
            >
              <MDTypography sx={{ width: { xs: "100%", md: "60%" }, fontSize: "15px" }}>
                IV.{"\u00A0"}
                Sufficient Quality Assurance Team: <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="sufficientQualityAssuranceTeam-selector"
                sx={{ width: { xs: "100%", md: "40%" } }}
                options={[
                  { label: "APPROVED", value: "APPROVED" },
                  { label: "TEMPORAL APPROVAL", value: "TEMPORAL APPROVAL" },
                  { label: "NEED MORE INFORMATION", value: "NEED MORE INFORMATION" },
                  { label: "NOT APPROVED", value: "NOT APPROVED" },
                ]} // Array of options for the autocomplete
                value={
                  sufficientQualityAssuranceTeam
                    ? {
                        label: sufficientQualityAssuranceTeam,
                        value: sufficientQualityAssuranceTeam,
                      }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select as appropriate"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setSufficientQualityAssuranceTeam(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "left", md: "center" },
                gap: { xs: 1, md: 4 },
                gridColumn: "span 6",
                mb: 3,
              }}
            >
              <MDTypography sx={{ width: { xs: "100%", md: "60%" }, fontSize: "15px" }}>
                V.{"\u00A0"} Course Structure / Learners&apos; Support System:
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="learnersSupportSystem-selector"
                sx={{ width: { xs: "100%", md: "40%" } }}
                options={[
                  { label: "APPROVED", value: "APPROVED" },
                  { label: "TEMPORAL APPROVAL", value: "TEMPORAL APPROVAL" },
                  { label: "NEED MORE INFORMATION", value: "NEED MORE INFORMATION" },
                  { label: "NOT APPROVED", value: "NOT APPROVED" },
                ]} // Array of options for the autocomplete
                value={
                  learnersSupportSystem
                    ? { label: learnersSupportSystem, value: learnersSupportSystem }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select as appropriate"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setLearnersSupportSystem(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>
          </MDBox>
        );

      case 10:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography
              sx={{ color: "#295C21", fontSize: { xs: 18, md: 12 }, gridColumn: "span 6" }}
            >
              EQA SUPPORTING DOCUMENT(s)
            </MDTypography>
            <MDBox sx={{ gridColumn: "span 6", mt: 2 }}>
              <Grid container spacing={{ xs: 2, md: 6 }} xs={12} sx={{ mb: { xs: 2, md: -7 } }}>
                <Grid item xs={12} sm={6}>
                  {eqaReports && (
                    <MDTypography variant="body2">
                      <strong>EQA Report:</strong>
                      &nbsp;<span style={{ color: "#4CAF50" }}>{eqaReports.name}</span>
                    </MDTypography>
                  )}
                </Grid>
              </Grid>
            </MDBox>

            <MDButton
              component="label"
              role={undefined}
              variant="gradient"
              color="dark"
              tabIndex={-1}
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" }, mb: { xs: 0, md: 5 } }}
              startIcon={<CloudUploadIcon />}
            >
              Click to Upload EQA Report(s) &nbsp;<span style={{ color: "red" }}> *</span>
              <VisuallyHiddenInput type="file" onChange={onSelectEqaReports} />
            </MDButton>
          </MDBox>
        );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <MDBox sx={{ mb: 5, mt: 5 }}>
        <Card>
          <form>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{
                p: 3,
                ml: 5,
                mr: 5,
                mt: -3,
                background: "#4CAF50 !important",
                boxShadow: "#4CAF50 !important",
                display: "flex",
                justifyContent: "space-between", // Ensures even spacing
                overflowX: "auto",
                whiteSpace: "nowrap",
                scrollSnapType: "x mandatory",
                WebkitOverflowScrolling: "touch",
                scrollbarWidth: "none", // Firefox
                "&::-webkit-scrollbar": {
                  display: "none", // Chrome, Safari, Edge
                },
                "& .MuiStep-root": {
                  flex: 1, // Ensures equal width for each step
                },
                "& .MuiStepLabel-label": {
                  color: "#ffffff !important",
                  minWidth: { md: "180px", xs: "100px" },
                },
                "& .MuiStepIcon-root": {
                  color: "#ffffff !important",
                  stroke: "#ffffff !important",
                  fill: "#ffffff !important",
                },
                "& .MuiStepConnector-root": {
                  flexGrow: 1, // Ensures connectors stretch evenly
                  color: "#ffffff !important",
                  minWidth: { md: "180px", xs: "100px" },
                },
              }}
            >
              {eqasteps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <MDBox sx={{ mt: 3 }}>{renderStepContent(activeStep)}</MDBox>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 2,
                mr: 3,
                ml: 3,
              }}
            >
              <MDButton disabled={activeStep === 0} onClick={handleBack}>
                Back
              </MDButton>
              {/* Next/Finish Button */}
              {activeStep === eqasteps.length - 1 ? (
                <MDButton onClick={onSaveEqaReportClicked}>Submit</MDButton>
              ) : (
                <MDButton onClick={handleNext}>Next</MDButton>
              )}
            </MDBox>
          </form>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
