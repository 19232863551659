// @mui material components
import Grid from "@mui/material/Grid";

import { useState, useEffect } from "react";
import { Navigate, useLocation, Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../../AuthProvider";
import axios from "axios";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAutocomplete from "components/MDAutocomplete";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import Sidebar, { ProfileSidebar } from "examples/Sidenav/Sidebar";
import CAInformation from "layouts/centreapplications/components/CentreInformation";
import { AssignEQACard, DownloadCADocsCard } from "layouts/centreapplications/components/EqaCards";
import {
  Paper,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Avatar,
  Box,
  Typography,
  IconButton,
  Divider,
  Icon,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EngineeringIcon from "@mui/icons-material/Engineering";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar, { DashboardNavbar2 } from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

import burceMars from "assets/images/bruce-mars.jpg";

export default function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const { auth } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [userRole, setUserRole] = useState("");

  const [usersCount, setUsersCount] = useState([]);
  const [casCount, setCasCount] = useState([]);
  const [eqasCount, setEqasCount] = useState([]);
  const [userQuals, setUserQuals] = useState([]);

  // Fetch users, centreapplications, and userqualifications data
  useEffect(() => {
    const fetchData = async () => {
      const usersResponse = await axios.get(`${apiUrl}/api/users/getUsers`);
      const casResponse = await axios.get(`${apiUrl}/api/centreapplications/getCentreApplications`);
      //const centresResponse = await axios.get(`${apiUrl}/api/centres/getCentres`);

      setUsersCount(usersResponse.data.length);
      setCasCount(casResponse.data.length);
      setEqasCount(
        usersResponse.data.filter((user) => user.roles.some((role) => role.roleType === "EQAM"))
          .length
      );
    };

    fetchData();
  }, []);

  useEffect(() => {
    const authData = localStorage.getItem("auth");

    if (!authData) {
      console.error("No auth data found in localStorage.");
      return;
    }

    try {
      const parsedAuth = JSON.parse(authData);
      const token = parsedAuth?.token;

      if (!token) {
        console.error("Token not found in auth object.");
        return;
      }

      const user = jwtDecode(token);

      if (user.roles) {
        setUserRole(user.roles);
      } else {
        console.error("Roles not found in token payload.");
      }
    } catch (error) {
      console.error("Error parsing auth data from localStorage:", error);
    }
  }, [auth]); // Depend on `auth` to ensure it updates properly

  if (userRole.toLowerCase() === "admin") {
    return (
      <DashboardLayout>
        <DashboardNavbar2 />
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="attach_file"
                  title="Centre Applications"
                  count={casCount}
                  percentage={{
                    color: "success",
                    amount: "+55%",
                    label: "than lask week",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="people"
                  title="Today's Users"
                  count={usersCount}
                  percentage={{
                    color: "success",
                    amount: "+3%",
                    label: "than last month",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="payments"
                  title="Revenue"
                  count="34k"
                  percentage={{
                    color: "success",
                    amount: "+1%",
                    label: "than yesterday",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="workspace_premium"
                  title="Qualified EQAMs"
                  count={eqasCount}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
          {/*<MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="info"
                    title="website views"
                    description="Last Campaign Performance"
                    date="campaign sent 2 days ago"
                    chart={reportsBarChartData}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="success"
                    title="daily sales"
                    description={
                      <>
                        (<strong>+15%</strong>) increase in today sales.
                      </>
                    }
                    date="updated 4 min ago"
                    chart={sales}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="dark"
                    title="completed tasks"
                    description="Last Campaign Performance"
                    date="just updated"
                    chart={tasks}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>*/}
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={12}>
                <Projects />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  } else {
    // redirect
    <Navigate to="/" />;
  }
}

export function EQAMDashboard() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const apiUrl = process.env.REACT_APP_API_URL;
  const [_user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const authData = localStorage.getItem("auth");

    if (!authData) {
      console.error("No auth data found in localStorage.");
      return;
    }

    try {
      const parsedAuth = JSON.parse(authData);
      const token = parsedAuth?.token;

      if (!token) {
        console.error("Token not found in auth object.");
        return;
      }

      const user = jwtDecode(token);

      if (user?.id) {
        setUserId(user.id);
      } else {
        console.error("ID not found in token payload.");
      }
    } catch (error) {
      console.error("Error parsing auth data from localStorage:", error);
    }
  }, []);

  // Fetch user data only if `userId` is available
  useEffect(() => {
    if (!userId) return;

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/users/${userId}`);

        if (response.data) {
          setUser({
            ...response.data,
            qualifications: response.data.qualifications || [],
            roles: response.data.roles || [],
          });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userId]);

  // Show a loader while fetching user data
  if (!_user) {
    return <CircularProgress color="primary" />;
  }

  const participants = [
    "/material-dashboard-pro-react/static/media/team-1.0fd36e0ee93dcfacdef8.jpg",
    "/material-dashboard-pro-react/static/media/team-2.13ae2ce3e12f4cfed420.jpg",
    "/material-dashboard-pro-react/static/media/team-3.0ef0be95e6850814c79e.jpg",
    "/material-dashboard-pro-react/static/media/team-4.85c82b6e60178804017f.jpg",
    "/material-dashboard-pro-react/static/media/team-5.d59ab7b9f95f1169cf83.jpg",
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={3} alignItems="center" pb={3} pl={2}>
                <Grid item>
                  <MDAvatar
                    src={apiUrl + "" + _user.avatar || burceMars}
                    alt="profile-image"
                    size="xl"
                    shadow="sm"
                  />
                </Grid>
                <Grid item>
                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                      {_user.firstName.toUpperCase() +
                        " " +
                        _user.middleName.toUpperCase() +
                        " " +
                        _user.lastName.toUpperCase()}
                    </MDTypography>
                    <MDTypography variant="button" color="success" fontWeight="regular">
                      {_user.roles.map((role) => {
                        if (role.roleType === "EQAM") {
                          return "External Quality Assurance Manager";
                        } else if (role.roleType === "IQAM") {
                          return "Internal Quality Assurance Manager";
                        }
                      })}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
              <Card>
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor={darkMode ? "transparent" : "grey-100"}
                      borderRadius="lg"
                      p={3}
                      mb={1}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                          <Grid container mb={2} lineHeight={0} display="flex" alignItems="center">
                            <Grid item md={3} xs={12}>
                              <MDTypography
                                variant="subtitle2"
                                color="success"
                                sx={{ minWidth: "150px" }}
                              >
                                ULN:
                              </MDTypography>
                            </Grid>
                            <Grid item md={9} xs={12}>
                              <MDTypography variant="h6" fontWeight="regular">
                                {_user.uln}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </MDBox>
                        <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                          <Grid container mb={2} lineHeight={0} display="flex" alignItems="center">
                            <Grid item md={3} xs={12}>
                              <MDTypography
                                variant="subtitle2"
                                color="success"
                                sx={{ minWidth: "150px" }}
                              >
                                EMAIL:
                              </MDTypography>
                            </Grid>
                            <Grid item md={9} xs={12}>
                              <MDTypography variant="h6" fontWeight="regular">
                                {_user.email}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </MDBox>
                        <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                          <Grid container mb={2} lineHeight={0} display="flex" alignItems="center">
                            <Grid item md={3} xs={12}>
                              <MDTypography
                                variant="subtitle2"
                                color="success"
                                sx={{ minWidth: "150px" }}
                              >
                                PHONE:
                              </MDTypography>
                            </Grid>
                            <Grid item md={9} xs={12}>
                              <MDTypography variant="h6" fontWeight="regular">
                                {_user.phone}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </MDBox>
                        <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                          <Grid container mb={2} lineHeight={0} display="flex" alignItems="center">
                            <Grid item md={3} xs={12}>
                              <MDTypography
                                variant="subtitle2"
                                color="success"
                                sx={{ minWidth: "150px" }}
                              >
                                ZONE:
                              </MDTypography>
                            </Grid>
                            <Grid item md={9} xs={12}>
                              <MDTypography variant="h6" fontWeight="regular">
                                {_user.zone}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </MDBox>
                        <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                          <Grid container mb={2} lineHeight={0} display="flex" alignItems="center">
                            <Grid item md={3} xs={12}>
                              <MDTypography
                                variant="subtitle2"
                                color="success"
                                sx={{ minWidth: "150px" }}
                              >
                                STATE:
                              </MDTypography>
                            </Grid>
                            <Grid item md={9} xs={12}>
                              <MDTypography variant="h6" fontWeight="regular">
                                {_user.stateOfRez}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox
          pt={2}
          px={2}
          lineHeight={1.25}
          sx={{ backgroundColor: "#fff", borderRadius: 4, mb: 3, boxShadow: 3 }}
        >
          <MDTypography variant="h6" fontWeight="medium" mt={3} ml={2}>
            Qualifications
          </MDTypography>
          <MDBox mb={3} ml={2}>
            <MDTypography variant="button" color="text">
              You have been trained in the following fields
            </MDTypography>
          </MDBox>

          <MDBox p={2}>
            <Grid container spacing={3}>
              {_user.qualifications.map((qualification, index) => (
                <Grid item xs={12} md={4} key={index} mb={2}>
                  <Card>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      pt={1}
                      px={2}
                      pb={{ xs: 5, md: 2 }}
                    >
                      <MDBox
                        variant="gradient"
                        bgColor={
                          qualification.qualificationType === "PLUMBING"
                            ? "dark"
                            : qualification.qualificationType === "CARPENTRY"
                            ? "success"
                            : qualification.qualificationType === "ELECTRICAL"
                            ? "error"
                            : qualification.qualificationType === "PAINTING & DECORATION"
                            ? "info"
                            : qualification.qualificationType === "WELDING & FABRICATION"
                            ? "warning"
                            : "dark"
                        }
                        color={"white"}
                        coloredShadow={"dark"}
                        borderRadius="xl"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="4rem"
                        height="4rem"
                        mt={-3}
                      >
                        <Icon fontSize="medium" color="inherit">
                          {qualification.qualificationType === "PLUMBING"
                            ? "plumbing"
                            : qualification.qualificationType === "CARPENTRY"
                            ? "handyman"
                            : qualification.qualificationType === "ELECTRICAL"
                            ? "electrical_services"
                            : qualification.qualificationType === "PAINTING & DECORATION"
                            ? "format_paint"
                            : qualification.qualificationType === "TILING & DECORATIVE STONE WORK"
                            ? "view_comfy"
                            : "engineering"}
                        </Icon>
                      </MDBox>
                      <MDBox textAlign="right" lineHeight={1.25}>
                        <MDTypography
                          variant={
                            qualification.qualificationType === "TILING & DECORATIVE STONE WORK"
                              ? "caption"
                              : "subtitle2"
                          }
                          fontWeight="light"
                          color="text"
                        >
                          {qualification.qualificationType}
                        </MDTypography>
                        {/*<MDTypography variant="subtitle2">
                          Level &nbsp;
                          <MDTypography
                            component="span"
                            variant="h5"
                            fontWeight="regular"
                            color={
                              qualification.UserQualification.level > 5
                                ? "success"
                                : qualification.UserQualification.level === 5
                                ? "warning"
                                : qualification.UserQualification.level < 5
                                ? "error"
                                : "text"
                            }
                          >
                            {qualification.UserQualification.level}
                          </MDTypography>
                        </MDTypography>*/}
                      </MDBox>
                    </MDBox>
                    {/*<Divider />
                    <MDBox pb={2} px={2}>
                      <MDTypography component={Link} to="#" variant="body2" color="secondary">
                        <Tooltip title="add certificate" placement="top">
                          <Icon>picture_as_pdf</Icon>
                        </Tooltip>
                      </MDTypography>
                    </MDBox>*/}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </MDBox>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
