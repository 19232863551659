/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { Navigate, useLocation, Link } from "react-router-dom";
import axios from "axios";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

// Images
import logoXD from "assets/images/small-logos/logo-xd.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoJira from "assets/images/small-logos/logo-jira.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import burceMars from "assets/images/bruce-mars.jpg";

export default function Data() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const usersResponse = await axios.get(`${apiUrl}/api/users/getUsers`);
      setUsers(usersResponse.data);
    };
    fetchData();
  }, []);

  // Group users by qualification
  const groupedUsers = users.reduce((acc, user) => {
    user.qualifications.forEach((qualification) => {
      const qualificationType = qualification.qualificationType;

      if (!acc[qualificationType]) {
        acc[qualificationType] = [];
      }

      acc[qualificationType].push(user);
    });

    return acc;
  }, {});

  // Count EQAM users
  const totalEQAs = users.filter((user) => user.roleType === "EQAM").length;

  // Generate avatars
  const avatars = (users) => {
    const displayedUsers = users.slice(0, 5);
    return (
      <MDBox display="flex" py={1} alignItems="center">
        {displayedUsers.map((user, index) => (
          <Tooltip key={index} title={user.firstName + " " + user.middleName + " " + user.lastName}>
            <MDAvatar
              src={apiUrl + "" + user.avatar || burceMars}
              alt={user.firstName}
              size="xs"
              sx={{
                border: "2px solid white",
                cursor: "pointer",
                position: "relative",
                backgroundColor: "#4CAF50",
                "&:not(:first-of-type)": {
                  ml: -1.25,
                },
                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </Tooltip>
        ))}
        {users.length > 5 && (
          <MDTypography variant="caption" color="text" fontWeight="bold" ml={1}>
            +{users.length - 5}
          </MDTypography>
        )}
      </MDBox>
    );
  };

  return {
    columns: [
      { Header: "Qualification", accessor: "qualification", width: "45%", align: "left" },
      { Header: "Members", accessor: "members", width: "20%", align: "left" },
      { Header: "Total EQAs", accessor: "totalEQAs", align: "center" },
      { Header: "Total Centres", accessor: "centres", align: "center" },
    ],

    rows: Object.entries(groupedUsers).map(([qualificationType, users]) => ({
      qualification: (
        <MDTypography variant="button" fontWeight="medium">
          {qualificationType}
        </MDTypography>
      ),
      members: avatars(users), // Pass the grouped users to the avatars function
      totalEQAs: users.filter((user) => user.roles.some((role) => role.roleType === "EQAM")).length, // Count users with roleType "EQAM"
    })),
  };
}
