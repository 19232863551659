import { Link } from "react-router-dom";
import { useTheme } from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//import HomeLayout from "./components/HomeLayout";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
//import Footer from "layouts/authentication/components/Footer";
import Footer from "examples/Footer";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { Typography, Button, Grid, Card } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import ApprovalIcon from "@mui/icons-material/Approval";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import bgImage from "assets/images/workshop.jpg";

const Homepage = () => {
  return (
    <PageLayout>
      <DefaultNavbar />
      <MDBox
        position="relative"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            bgImage &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <MDBox
          id="home"
          data-parallax="scroll"
          data-natural-width={3000}
          data-natural-height={2000}
        >
          <MDBox className="home-scrolldown">
            <a href="#about" className="scroll-icon smoothscroll">
              <span>Scroll Down</span>
              <i className="icon-arrow-right" aria-hidden="true"></i>
            </a>
          </MDBox>
          <MDBox className="overlay" />

          <MDBox className="home-content">
            <MDBox className="home-content-left">
              <MDTypography variant="h3" data-aos="fade-up">
                Welcome to
              </MDTypography>
              <MDTypography variant="h1" data-aos="fade-up">
                NIOB Skill CaMP
              </MDTypography>
              <MDTypography variant="h5">
                The Skill Certification and Management Portal for the <br />
                Nigerian Institute of Building (NIOB)
              </MDTypography>
              <MDBox className="buttons" data-aos="fade-up">
                <Button
                  variant="outlined"
                  startIcon={<PlayArrowIcon />}
                  size="large"
                  sx={{ color: "#fff", borderColor: "#fff", p: "20px 50px" }}
                >
                  Watch Video
                </Button>
              </MDBox>
            </MDBox>
            <MDBox className="home-image-right">
              <img
                src="iphone-app-470.png"
                srcSet="iphone-app-2-470.png 1x, iphone-app-2-940.png 2x"
                data-aos="fade-up"
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox id="about">
        <Grid container spacing={4} className="about-intro">
          <Grid item xs={12} md={4}>
            <MDTypography variant="h3" data-aos="fade-up">
              About
            </MDTypography>
            <MDTypography variant="h1" data-aos="fade-up">
              Skill CaMP
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={8}>
            <MDTypography className="lead" data-aos="fade-up">
              A Skill Certification and Management Portal that provides certification information on
              National Skill Qualification Framework.
            </MDTypography>
          </Grid>
        </Grid>

        <Grid container spacing={3} className="about-features">
          <Grid item xs={12} md={4} className="bgrid feature" data-aos="fade-up">
            <PlayArrowIcon className="icon icon-image" fontSize="large" />
            <MDBox className="service-content">
              <MDTypography variant="h4">Centre Application</MDTypography>
              <MDTypography variant="subtitle2" sx={{ textAlign: "justify" }}>
                Centres can now apply online for NSQ certification. Approved Centres get a portal
                where they can manage their Staff and Trainees.
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} md={4} className="bgrid feature" data-aos="fade-up">
            <AssuredWorkloadIcon className="icon icon-image" fontSize="large" />
            <MDBox className="service-content">
              <MDTypography variant="h4">Quality Assurance</MDTypography>
              <MDTypography variant="subtitle2" sx={{ textAlign: "justify" }}>
                Maintaining high standards is at the heart of what we do. Hence, well-trained and
                certified EQAs are deployed to ensure the highest quality in Centres.
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} md={4} className="bgrid feature" data-aos="fade-up">
            <AssessmentIcon className="icon icon-image" fontSize="large" />
            <MDBox className="service-content">
              <MDTypography variant="h4">QA Reporting</MDTypography>
              <MDTypography variant="subtitle2" sx={{ textAlign: "justify" }}>
                All our quality assurance processes and outcomes are well documented and reported.
                Thereby ensuring transparency across board.
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>

        <MDBox className="about-how">
          <MDTypography variant="h1" className="intro-header" data-aos="fade-up">
            How The App Works?
          </MDTypography>

          <Grid container spacing={3} className="about-how-content" data-aos="fade-up">
            <Grid item xs={12} md={6} className="bgrid step" data-item="1">
              <MDTypography variant="h3">Apply</MDTypography>
              <MDTypography variant="body2">
                A Centre fills & submits this&nbsp;
                <Link
                  to="/centre-application"
                  style={{ color: "#49a139", textDecoration: "underline" }}
                >
                  Online Form
                </Link>
              </MDTypography>
            </Grid>

            <Grid item xs={12} md={6} className="bgrid step" data-item="2">
              <MDTypography variant="h3">EQA Assigned</MDTypography>
              <MDTypography variant="subtitle1">
                An EQA is assigned to the Centre for quality assurance.
              </MDTypography>
            </Grid>

            <Grid item xs={12} md={6} className="bgrid step" data-item="3">
              <MDTypography variant="h3">Board Review</MDTypography>
              <MDTypography variant="subtitle1">
                The Board reviews the Centres submission with the EQA&apos;s report.
              </MDTypography>
            </Grid>

            <Grid item xs={12} md={6} className="bgrid step" data-item="4">
              <MDTypography variant="h3">Approval</MDTypography>
              <MDTypography variant="subtitle1">
                Having completed the review phase, the Board approves/disapproves a Centre&apos;s
                application.
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </PageLayout>
  );
};

export default Homepage;
