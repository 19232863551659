import { useState, useEffect } from "react";
import { useSearch } from "../../SearchContext";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "@mui/material/Card";
import { Input, Button, Card, CardContent, Grid, Tooltip, Icon } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

import SearchLayout from "layouts/authentication/components/SearchLayout";

// Images
import bgImage from "assets/images/workshop.jpg";

export default function SearchEQAs() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const apiUrl = process.env.REACT_APP_API_URL;
  const { searchQuery, setSearchQuery } = useSearch();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchResults = async () => {
    if (!searchQuery.trim()) return;
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/searchEQAs?q=${searchQuery}`);
      setResults(response.data);
    } catch (error) {
      console.error("Search error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery.length > 2) {
      fetchResults();
    }
  }, [searchQuery]);

  return (
    <SearchLayout image={bgImage}>
      <MDBox mt={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            {/* Filter section */}
            <Grid item xs={12} md={4}>
              <Card>
                <MDBox sx={{ mb: 5, p: 2 }}>
                  <Input
                    placeholder="Search EQAs..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <Button onClick={fetchResults} disabled={loading}>
                    <SearchIcon />
                  </Button>
                </MDBox>
              </Card>
            </Grid>

            {/* Display results */}
            <Grid item xs={12} md={8}>
              <Card>
                <MDBox pt={3} px={2}>
                  <MDTypography variant="h6" fontWeight="light">
                    Search result for <strong>&quot;{searchQuery}&quot;</strong>
                  </MDTypography>
                </MDBox>
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="grid" md={12} p={0} m={0}>
                    {results.length > 0
                      ? results.map((eqa, index) => (
                          <MDBox
                            component="li"
                            key={index}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            bgColor={darkMode ? "transparent" : "grey-100"}
                            borderRadius="lg"
                            p={3}
                            mb={1}
                            mt={2}
                          >
                            <MDBox width="100%" display="flex" flexDirection="column">
                              <MDBox
                                display="flex"
                                justifyContent="space-between"
                                alignItems={{ xs: "flex-start", sm: "center" }}
                                flexDirection={{ xs: "column", sm: "row" }}
                                mb={2}
                              >
                                <MDTypography
                                  variant="subtitle2"
                                  fontWeight="regular"
                                  textTransform="capitalize"
                                >
                                  {eqa.firstName.toUpperCase()}
                                  &nbsp;{eqa.middleName.toUpperCase()}
                                  &nbsp;{eqa.lastName.toUpperCase()}
                                </MDTypography>
                                <Tooltip title="pay here to see more">
                                  <MDTypography
                                    component="a"
                                    href={`users/${eqa.id}/view`}
                                    variant="caption"
                                    color="success"
                                    fontWeight="regular"
                                  >
                                    <Icon>payment</Icon>
                                    &nbsp;Click to see more
                                  </MDTypography>
                                </Tooltip>
                              </MDBox>

                              <Grid
                                container
                                mb={2}
                                lineHeight={0}
                                display="flex"
                                alignItems="center"
                              >
                                <Grid item md={3} xs={12}>
                                  <MDTypography
                                    variant="caption"
                                    color="success"
                                    sx={{ minWidth: "200px" }}
                                  >
                                    Email Address:
                                  </MDTypography>
                                </Grid>
                                <Grid item md={9} xs={12}>
                                  <MDTypography variant="button" fontWeight="regular">
                                    {eqa.email}
                                  </MDTypography>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                mb={2}
                                lineHeight={0}
                                display="flex"
                                alignItems="center"
                              >
                                <Grid item md={3} xs={12}>
                                  <MDTypography
                                    variant="caption"
                                    color="success"
                                    sx={{ minWidth: "200px" }}
                                  >
                                    Zone:
                                  </MDTypography>
                                </Grid>
                                <Grid item md={9} xs={12}>
                                  <MDTypography
                                    variant="button"
                                    fontWeight="regular"
                                    textTransform="capitalize"
                                  >
                                    {eqa.zone}
                                  </MDTypography>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                mb={2}
                                lineHeight={0}
                                display="flex"
                                alignItems="center"
                              >
                                <Grid item md={3} xs={12}>
                                  <MDTypography
                                    variant="caption"
                                    color="success"
                                    sx={{ minWidth: "200px" }}
                                  >
                                    State:
                                  </MDTypography>
                                </Grid>
                                <Grid item md={9} xs={12}>
                                  <MDTypography
                                    variant="button"
                                    fontWeight="regular"
                                    textTransform="capitalize"
                                  >
                                    {eqa.stateOfRez}
                                  </MDTypography>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </MDBox>
                        ))
                      : !loading && <p>No results found.</p>}
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </SearchLayout>
  );
}
