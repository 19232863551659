// stefan.dataanalyst - wfthxGz4zd
// deji4me - FDa5NUfB8x

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAutocomplete from "components/MDAutocomplete";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar, { DashboardNavbar2 } from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Notifications from "layouts/notifications";

import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { STATES } from "../../config/states";
import { ZONES } from "../../config/zones";

//import MDLoadingButton from "@mui/lab/LoadingButton";
import { useTheme, styled } from "@mui/material";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDAlert from "@mui/material/Alert";
import MDHomeIcon from "@mui/icons-material/Home";
import MDVisibilityIcon from "@mui/icons-material/Visibility";
import MDCloudUploadIcon from "@mui/icons-material/CloudUpload";
import MDTextField from "@mui/material/TextField";
import MDStack from "@mui/material/Stack";
import MDButton from "components/MDButton";
import MDFormControl from "@mui/material/FormControl";
import MDInputLabel from "@mui/material/InputLabel";
import MDSelect from "@mui/material/Select";
import MDMenuItem from "@mui/material/MenuItem";
import MDGrid from "@mui/material/Grid";
import MDCard from "@mui/material/Card";
import MDCardHeader from "@mui/material/CardHeader";
import MDCardActions from "@mui/material/CardActions";
import MDCardContent from "@mui/material/CardContent";

const QUAL_REGEX = /^[A-Za-z& ]{3,50}$/;

// Data
import qualificationsTableData from "layouts/qualifications/data/qualificationsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { useAuth } from "../../AuthProvider"; // Corrected Import
import { Navigate, useLocation } from "react-router-dom";

// Display All Qualifications
export default function Qualifications() {
  const { columns, rows } = qualificationsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole !== "admin") {
    return <Navigate to="/error404" />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  All Qualifications
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  canSearch={true}
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={{ variant: "gradient", color: "success" }}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// New Qualification Form
export function NewQualificationForm() {
  const navigate = useNavigate();
  const [qualificationType, setQualificationType] = useState("");
  const [validQualificationType, setValidQualificationType] = useState(false);
  const [description, setDescription] = useState("");
  const [validDescription, setValidDescription] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole !== "admin") {
    return <Navigate to="/error404" />;
  }

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Input validation
  useEffect(() => {
    setValidQualificationType(QUAL_REGEX.test(qualificationType));
    setValidDescription(QUAL_REGEX.test(description));
  }, [qualificationType, description]);

  const onQualificationTypeChanged = (e) => setQualificationType(e.target.value);
  const onDescriptionChanged = (e) => setDescription(e.target.value);

  const onSaveUserClicked = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = { qualificationType, description };

    try {
      const response = await axios.post(`${apiUrl}/api/qualifications/addQualification`, formData);

      if (response.status === 201 || response.status === 200) {
        setQualificationType("");
        setDescription("");
        setIsSubmitting(false);
        showNotification("success", "Qualification added successfully!");

        // Redirect after a delay
        setTimeout(() => navigate("/qualifications"), 7000);
      } else {
        setIsSubmitting(false);
        showNotification("error", "Failed to add qualification. Please try again.");
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error while adding qualification: ", error.response.data.message);
      showNotification("error", `${error.response.data.message}, Try again.`);
    }
  };

  const canSave = [validQualificationType, validDescription].every(Boolean);

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  Add New Qualification
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <form onSubmit={onSaveUserClicked}>
                  <MDBox
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: undefined },
                    }}
                  >
                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Qualification Type"
                      onChange={onQualificationTypeChanged}
                      value={qualificationType}
                      name="qualificationType"
                      sx={{ gridColumn: "span 4" }}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Add a Description of the Qualification Type"
                      onChange={onDescriptionChanged}
                      value={description}
                      name="description"
                      sx={{ gridColumn: "span 4" }}
                    />
                  </MDBox>
                  <MDBox>
                    <MDButton
                      disabled={!canSave || isSubmitting}
                      loading={false}
                      type="submit"
                      variant="contained"
                      color="success"
                      sx={{ mt: 3 }}
                    >
                      {isSubmitting ? "Submitting..." : "Add New Qualification"}
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// Edit Qualification Form
export function EditQualificationForm() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [qualificationType, setQualificationType] = useState("");
  const [validQualificationType, setValidQualificationType] = useState(false);
  const [description, setDescription] = useState("");
  const [validDescription, setValidDescription] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole !== "admin") {
    return <Navigate to="/error404" />;
  }

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Fetch qualification data
  useEffect(() => {
    const fetchQualificationData = async () => {
      const { data } = await axios.get(`${apiUrl}/api/qualifications/${id}`);
      setQualificationType(data.qualificationType);
      //setDescription(data.description);
    };
    fetchQualificationData();
  }, [id]);

  // Input validation
  useEffect(() => {
    setValidQualificationType(QUAL_REGEX.test(qualificationType));
    setValidDescription(QUAL_REGEX.test(description));
  }, [qualificationType, description]);

  const onQualificationTypeChanged = (e) => setQualificationType(e.target.value);
  const onDescriptionChanged = (e) => setDescription(e.target.value);

  const onSaveUserClicked = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = {
      qualificationType,
      description,
    };

    try {
      const qualificationUpdated = await axios.put(`${apiUrl}/api/qualifications/${id}`, formData);

      if (qualificationUpdated) {
        setIsSubmitting(false);
        showNotification("success", "Qualification updated successfully!");

        // Redirect after a delay
        setTimeout(() => navigate("/qualifications"), 7000);
      }
    } catch (e) {
      setIsSubmitting(false);
      console.error("Error: ", e.response.data);
      showNotification("error", `${e.response.data}, Try again.`);
    }
  };

  const canSave = [validQualificationType].every(Boolean);

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  Update Qualification Record
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <form onSubmit={onSaveUserClicked}>
                  <MDBox
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: undefined },
                    }}
                  >
                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Qualification Type"
                      onChange={onQualificationTypeChanged}
                      value={qualificationType}
                      name="qualificationType"
                      sx={{ gridColumn: "span 4" }}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Description of the Qualification Type"
                      onChange={onDescriptionChanged}
                      value={description}
                      name="description"
                      sx={{ gridColumn: "span 4" }}
                    />
                  </MDBox>
                  <MDBox>
                    <MDButton
                      disabled={!canSave || isSubmitting}
                      type="submit"
                      variant="contained"
                      color="success"
                      sx={{ mt: 3 }}
                    >
                      {isSubmitting ? "Updating..." : "Update Qualification Record"}
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
